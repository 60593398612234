import { Md5 } from 'ts-md5';

import { setPage } from '../config/history';
import {
  getAuthHeader,
  getAuthToken,
  logout,
  removeAuthToken,
  removeRefreshToken,
  setAuthToken,
} from '../helpers/authHeader';
import { showMessage } from '../helpers/notifications';
import { API_URL, getRefreshToken, setRefreshToken } from '../helpers/settings';

interface FetchApiConfig {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: Record<string, string | number | undefined> | any;
  body?: BodyInit | Record<string, unknown> | unknown;
  headers?: Record<string, string>;
  formData?: FormData;
  suppressError?: boolean;
  noLogout?: boolean;
  noKey?: boolean;
}

export class FetchError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    Object.setPrototypeOf(this, FetchError.prototype);
    this.response = response;
  }

  public text = (): Promise<string> => this.response.text();
}

let codeData:
  | {
      [key: string]: string;
    }
  | undefined;
let secretKey: string | undefined;

let refreshInProcess = false;

export async function fetchApi(
  url: string,
  { suppressError, noLogout, ...config }: FetchApiConfig,
  attempt = 0,
): Promise<any> {
  if (attempt > 3) {
    throw new Error('too many attempts');
  }

  try {
    const response = await fetchData(url, config);
    if (response.ok) {
      if (isJson(response)) {
        return response.json();
      } else if (isCsv(response)) {
        return response.blob();
      }
      return response.text();
    }
    if (response.status === 401) {
      const refreshToken = getRefreshToken();
      if (refreshToken) {
        removeRefreshToken();
        refreshInProcess = true;
        const { access, refresh } = await fetchApi('/auth/refresh', {
          method: 'POST',
          body: { token: refreshToken },
        });
        refreshInProcess = false;
        if (access && refresh) {
          setAuthToken(access);
          setRefreshToken(refresh);
          codeData = undefined;
          secretKey = undefined;
          return fetchApi(
            url,
            {
              ...config,
              headers: getAuthHeader(access),
            },
            attempt + 1,
          );
        } else if (!noLogout) {
        } else removeAuthToken();
        !refreshInProcess && logout();
      } else {
        !refreshInProcess && logout();
        throw new FetchError(response);
      }
    } else if (response.status === 412) {
      codeData = undefined;
      secretKey = undefined;
      await sleep(1000);
      return fetchApi(url, config, attempt + 1);
    } else if (response.status === 423) {
      setPage('banned');
    } else {
      throw new FetchError(response);
    }
    return response;
  } catch (e: any) {
    if (e?.response?.status === 412) {
      codeData = undefined;
      secretKey = undefined;
      await sleep(1000);
      return fetchApi(url, config, attempt + 1);
    }
    if (e?.response?.status === 401) {
      return '';
    }
    if (!suppressError) {
      if (e?.response?.status === 403) return logout();
      if (isJson(e?.response)) {
        e?.response
          .json()
          .then((result) =>
            showMessage('error', result.detail || e?.response?.statusText),
          );
      } else {
        showMessage('error', e?.response?.statusText);
      }
    }
    throw e;
  }
}

export async function fetchData(
  url: string,
  { method = 'GET', params, body, headers, formData, noKey, ...config }: FetchApiConfig,
): Promise<Response> {
  if (!headers) {
    headers = {};
  }
  if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
    headers['Content-Type'] = 'application/json;charset=utf-8';
  }
  if (headers['Content-Type'] === '') {
    delete headers['Content-Type'];
  }
  if (!noKey) {
    if (!codeData) {
      codeData = await getCodeData();
    }
    headers.AuthKey = generateSecretKey();
  }

  const fetchConfig = {
    method,
    body: formData ? formData : body ? JSON.stringify(body) : undefined,
    headers: new Headers(headers),
    mode: 'cors' as any,
    ...config,
  };

  const urlParams: string[] = [];
  for (const name in params) {
    if (params?.[name] !== undefined) {
      urlParams.push(name + '=' + encodeURIComponent(params[name]));
    }
  }
  if (urlParams.length > 0) {
    url += '?' + urlParams.join('&');
  }

  return fetch(`${API_URL}/rest/v1${url}`, fetchConfig);
}

const errorHtmlPattern = /<p>(.*)<\/p>/;

export function parseErrorHtml(html: string): string | undefined {
  const match = errorHtmlPattern.exec(html);
  if (match) {
    return match[1];
  }
  return;
}

export function isJson(response): boolean {
  let contentType = response?.headers?.get('content-type');
  contentType = contentType?.toLowerCase();
  return (
    contentType &&
    (contentType.indexOf('application/json') !== -1 ||
      contentType.indexOf('application/problem+json') !== -1)
  );
}

function isCsv(response): boolean {
  const contentType = response?.headers?.get('content-type');
  return contentType && contentType?.indexOf('text/csv') !== -1;
}

function getCodeData(): Promise<any> {
  return fetchApi('/codedata', {
    headers: getAuthHeader(),
    suppressError: true,
    noKey: true,
  });
}

function generateSecretKey(): string {
  if (secretKey) {
    return secretKey;
  }
  if (!codeData) {
    return '';
  }

  const needReverse = codeData['aKM'] === 'e';
  const md5 = new Md5();
  let codedataKeys = Object.keys(codeData).sort();
  needReverse && (codedataKeys = codedataKeys.reverse());

  const codedataValues = codedataKeys.map((key) => codeData?.[key]);
  const result = codedataValues.join('') + 'l';
  secretKey = md5.appendStr(result).end() as string;
  return secretKey;
}

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
