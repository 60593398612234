import React from 'react';

import {
  formatCrypto,
  formatDateTime,
  formatNumber,
  parseDate,
} from '../../helpers/utils';
import { api } from '../../utils/api';
import Txt from '../controls/Txt';
import { getSettingsCrypto, offChangeCrypto, onChangeCrypto } from './admin-common';
import {
  ClockIcon,
  FinanceIcon,
  InputsIcon,
  OkIcon,
  OnlineTodayIcon,
  OutputsIcon,
  Profit2Icon,
  Users2Icon,
} from './icons';

interface State {
  crypto: string;
  updateDate?: Date;
  profit: number;
  finance: number;
  confirmedBalance: number;
  inputs: number;
  outputs: number;
  users: number;
  onlineToday: number;
}

export default class Profit extends React.Component {
  public state: State = {
    crypto: getSettingsCrypto(),
    profit: 0,
    finance: 0,
    confirmedBalance: 0,
    inputs: 0,
    outputs: 0,
    users: 0,
    onlineToday: 0,
  };

  componentDidMount(): void {
    onChangeCrypto(this.onChangeCrypto);
    this.update();
  }

  componentWillUnmount(): void {
    offChangeCrypto(this.onChangeCrypto);
  }

  onChangeCrypto = (crypto: string) => this.setState({ crypto }, this.update);

  update = () => {
    const { crypto } = this.state;
    api.admin
      .profit(crypto.toLowerCase())
      .then((profit) => {
        this.setState({
          crypto,
          updateDate: parseDate(profit.updated),
          profit: profit.profit,
          finance: profit.users_balance,
          confirmedBalance: profit.wallet_balance,
          inputs: profit.deposit_revenue || 0,
          outputs: profit.withdraw_revenue || 0,
          users: profit.users,
          onlineToday: profit.online_users,
        });
      })
      .catch(() => undefined);
  };

  render(): React.ReactNode {
    const {
      crypto,
      updateDate,
      profit,
      finance,
      confirmedBalance,
      inputs,
      outputs,
      users,
      onlineToday,
    } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <div className={'adm-page-title'}>
            <Txt k={'admin.profit.title'} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <div className={'adm-profit-cards'}>
            <ProfitCard
              value={formatDateTime(updateDate)}
              fontSize={'2.2rem'}
              caption={'admin.profit.update-date'}
              icon={<ClockIcon fill={'#ef3124'} />}
            />
            <ProfitCard
              value={profit}
              crypto={crypto}
              caption={'admin.profit.profit'}
              icon={<Profit2Icon fill={'#ff8c00'} />}
            />
            <ProfitCard
              value={finance}
              crypto={crypto}
              caption={'admin.profit.finance'}
              icon={<FinanceIcon fill={'#0098db'} />}
            />
            <ProfitCard
              value={confirmedBalance}
              crypto={crypto}
              caption={'admin.profit.confirmed-balance'}
              icon={<OkIcon fill={'#00d151'} />}
            />
            <ProfitCard
              value={inputs}
              crypto={crypto}
              caption={'admin.profit.inputs'}
              icon={<InputsIcon fill={'#8f8f8f'} />}
            />
            <ProfitCard
              value={outputs}
              crypto={crypto}
              caption={'admin.profit.outputs'}
              icon={<OutputsIcon fill={'#8f8f8f'} />}
            />
            <ProfitCard
              value={users}
              caption={'admin.profit.users'}
              icon={<Users2Icon fill={'#8f8f8f'} />}
            />
            <ProfitCard
              value={onlineToday}
              caption={'admin.profit.online-today'}
              icon={<OnlineTodayIcon fill={'#8f8f8f'} />}
            />
          </div>
        </div>
      </div>
    );
  }
}

const ProfitCard = ({
  value,
  crypto,
  caption,
  fontSize,
  icon,
}: {
  value?: number | string;
  crypto?;
  caption;
  fontSize?: string;
  icon: React.ReactNode;
}) => (
  <div className={'adm-profit-card'}>
    {icon}
    <span style={{ fontSize }}>
      {typeof value === 'number'
        ? crypto
          ? formatCrypto(value, crypto)
          : formatNumber(value)
        : value}
    </span>
    <span>
      <Txt k={caption} />
    </span>
  </div>
);
