import React from 'react';
import { RouteComponentProps } from 'react-router';

import { formatCrypto, formatNumber, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmUserLink } from './admin-common';
import PageLayout from './PageLayout';

export interface IAutotradersStatistics {
  amount: number;
  broker: string;
  receivedFiatInCrypto: number;
  cancelReason: string;
  createdAt?: Date;
  currency: string;
  identificator: string;
  nickname: string;
  processedAt?: Date;
  state: string;
  symbol: string;
}

interface HistoryState {
  currency: string;
  symbol: string;
  year: number;
  month: number;
  name: string;
}

interface State {
  currencies: Array<string>;
  currentCurrency?: string;
}

export const columns = [
  {
    id: 'identificator',
    caption: 'admin.autotrader-statistics.table.identificator',
    noSorting: true,
  },
  {
    id: 'nickname',
    caption: 'admin.autotrader-statistics.table.nickname',
    noSorting: true,
    render: (nickname) => <AdmUserLink nickname={nickname} />,
  },
  {
    id: 'broker',
    caption: 'admin.autotrader-statistics.table.broker',
    minWidth: '5rem',
    noSorting: true,
  },
  {
    id: 'amount',
    caption: 'admin.autotrader-statistics.table.amount',
    noSorting: true,
    render: (amount, row) => (
      <>
        {formatNumber(amount)}&nbsp;{row.currency?.toUpperCase()}
      </>
    ),
  },
  {
    id: 'receivedFiatInCrypto',
    caption: 'admin.autotrader-statistics.table.receivedFiatInCrypto',
    noSorting: true,
    render: (receivedFiatInCrypto, row) => (
      <>
        {formatCrypto(receivedFiatInCrypto)}&nbsp;{row.symbol?.toUpperCase()}
      </>
    ),
  },
  {
    id: 'createdAt',
    caption: 'admin.autotrader-statistics.table.createdAt',
    noSorting: true,
  },
  {
    id: 'processedAt',
    caption: 'admin.autotrader-statistics.table.processedAt',
    noSorting: true,
  },
  {
    id: 'cancelReason',
    caption: 'admin.autotrader-statistics.table.cancelReason',
    noSorting: true,
  },
  {
    id: 'state',
    caption: 'admin.autotrader-statistics.table.state',
    noSorting: true,
    render: (state) => <Txt k={`admin.autotrader-statistics.table.state-${state}`} />,
  },
];

export default class AutotraderStatistics extends React.Component<
  RouteComponentProps<{ id: string }>
> {
  public state: State = {
    currencies: [],
    currentCurrency: undefined,
  };

  render(): React.ReactNode {
    const { id } = this.props.match.params;
    const { currency, month, symbol, year, name } = this.props.history.location
      .state as HistoryState;

    return (
      <PageLayout<IAutotradersStatistics>
        request={getAutotradersStatistics}
        additionalParams={{ id, currency, month, symbol, year }}
        headerTitle={'admin.autotrader-statistics.title'}
        defaultSortBy={'amount'}
        tableColumns={columns}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[name]}
      />
    );
  }
}

function getAutotradersStatistics(
  params: CommonRequestParams & {
    id: string;
    currency: string;
    month: number;
    year: number;
    symbol: string;
  },
): Promise<IAutotradersStatistics[]> {
  return new Promise((resolve) => {
    api.admin
      .autotradersStatistics({
        ...params,
        symbol: params.symbol?.toLowerCase(),
        ordering: params.ordering ?? '-amount',
      })
      .then((result) => {
        const statistics: IAutotradersStatistics[] = [];
        for (const p of result) {
          statistics.push({
            amount: p.amount,
            receivedFiatInCrypto: p.received_fiat_in_crypto,
            broker: p.broker,
            cancelReason: p.cancel_reason,
            createdAt: parseDate(p.created_at),
            currency: p.currency,
            identificator: p.identificator,
            nickname: p.nickname,
            processedAt: parseDate(p.processed_at),
            state: p.state,
            symbol: p.symbol,
          });
        }
        resolve(statistics);
      })
      .catch(() => undefined);
  });
}
