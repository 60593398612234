import { Defaults } from '../components/common';
import { getLanguage, t } from '../config/i18n';

export const OP_TYPES = ['pay', 'sale', 'sale_v2', 'cpayments', 'pay_v2'];

export const OP_TYPES_FILTER = [
  { id: 'pay', value: 'SKY PAY' },
  { id: 'sale', value: 'SKY SALE' },
  { id: 'pay_v2', value: 'SKY PAY V2' },
  { id: 'sale_v2', value: 'SKY SALE V2' },
  { id: 'cpayments', value: 'CPAYMENT' },
];

export const MONTHS = [
  { id: '1', value: 'Январь' },
  { id: '2', value: 'Ферваль' },
  { id: '3', value: 'Март' },
  { id: '4', value: 'Апрель' },
  { id: '5', value: 'Май' },
  { id: '6', value: 'Июнь' },
  { id: '7', value: 'Июль' },
  { id: '8', value: 'Август' },
  { id: '9', value: 'Сентябрь' },
  { id: '10', value: 'Октябрь' },
  { id: '11', value: 'Ноябрь' },
  { id: '12', value: 'Декабрь' },
];

export function formatNumber(
  f?: number,
  maximumFractionDigits = 8,
  minimumFractionDigits?: number,
): string {
  return f !== undefined
    ? Intl.NumberFormat(undefined, {
        maximumFractionDigits,
        minimumFractionDigits,
      }).format(f)
    : '';
}

export function getCryptoFracDigits(crypto?: string): number {
  return crypto === 'BTC' ? 8 : 8;
}

export function formatCrypto(f?: number, crypto?: string): string {
  return formatNumber(f, getCryptoFracDigits(crypto));
}

export function formatDate(d: Date): string {
  return d.toLocaleDateString();
}

export function formatDateTime(d?: Date): string | undefined {
  return d
    ? d.toLocaleString(undefined, {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        hour12: false,
        minute: '2-digit',
      })
    : undefined;
}

export function getUTCTime(d?: Date): number | undefined {
  return d ? d.getTime() / 1000 - d.getTimezoneOffset() * 60 : undefined;
}

export function formatString(s?: string, ...args: any): string | undefined {
  if (!s) {
    return;
  }
  for (let i = 0; i < args.length; i++) {
    s = s.split(`{${i}}`).join(args[i]);
  }
  return highlight(s);
}

export function highlight(s?: string): string | undefined {
  if (!s) {
    return;
  }
  return s
    .replace('<hl>', '<span style="color: #0097db; text-transform: none;">')
    .replace('</hl>', '</span>');
}

export function escapeHtml(s?: string): string | undefined {
  if (!s) {
    return;
  }
  return s.replace(/<.+?>/g, '');
}

export function formatFileSize(bytes?: number): [number, 'b' | 'kb' | 'mb' | 'gb'] {
  if (bytes === undefined) {
    return [0, 'b'];
  }
  const kb = Math.round(bytes / 1024);
  if (kb === 0) {
    return [bytes, 'b'];
  }
  const mb = Math.round(kb / 1024);
  if (mb === 0) {
    return [kb, 'kb'];
  }
  const gb = Math.round(mb / 1024);
  if (gb === 0) {
    return [mb, 'mb'];
  }
  return [gb, 'gb'];
}

export function roundFloat(f: number, n?: number): number {
  return parseFloat(f.toFixed(n || 0));
}

export function roundCrypto(f: number, crypto?: string): number {
  return truncNumber(f, getCryptoFracDigits(crypto));
}

export function truncNumber(f: number, fracDigits: number): number {
  const decSep = String(1.1).substring(1, 2);
  const fixedNumber = toFixed(f);
  const ss = String(fixedNumber).split(decSep);
  if (ss.length < 2) {
    return f;
  }
  ss[1] = ss[1].substr(0, fracDigits);

  return parseFloat(ss.join(decSep));
}

export function toFixed(x: number): string | number {
  if (Math.abs(x) < 1.0) {
    const e = parseInt(x.toString().split('e-')[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      return '0.' + new Array(e).join('0') + x.toString().substring(2);
    }
  } else {
    let e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      return x + new Array(e + 1).join('0');
    }
  }
  return x;
}

export function getDecimalSeparator(): string {
  return (1.1).toLocaleString().substring(1, 2);
}

function getPluralizeConst(value, constName) {
  const lang = getLanguage();
  if (lang === 'ru') {
    if (['2', '3', '4'].includes(value.toString().slice(-1))) {
      constName += 's';
    }
  } else if (lang === 'en') {
    if (value > 1) {
      constName += 's';
    }
  }

  return constName;
}

export function secondsToText(
  seconds: number,
): [number, 'second' | 'minute' | 'hour' | 'day' | 'week'] {
  seconds = Math.floor(seconds);
  const w = Math.floor(seconds / 60 / 60 / 24 / 7);
  if (w > 0) {
    return [w, getPluralizeConst(w, 'week')];
  }

  const d = Math.floor(seconds / 60 / 60 / 24);
  if (d > 0) {
    return [d, getPluralizeConst(d, 'day')];
  }

  const h = Math.floor(seconds / 60 / 60);
  if (h > 0) {
    return [h, getPluralizeConst(h, 'hour')];
  }

  const m = Math.floor(seconds / 60);
  if (m > 0) {
    return [m, getPluralizeConst(m, 'minute')];
  }

  return [seconds, getPluralizeConst(seconds, 'second')];
}

export function parseDate(s?: string): Date | undefined {
  if (!s || s === 'None') {
    return;
  }

  let ss = s.split(' ');
  if (ss.length < 2) {
    ss = s.split('T');
  }

  const d = ss[0].split('-');
  if (ss.length < 2) {
    return new Date(
      Date.UTC(parseInt(d[0], 10), parseInt(d[1], 10) - 1, parseInt(d[2], 10)),
    );
  }

  const t = ss[1].split('.');
  if (t.length < 1) {
    return;
  }

  const tt = t[0].split(':');
  if (tt.length < 3) {
    return;
  }

  return new Date(
    Date.UTC(
      parseInt(d[0], 10),
      parseInt(d[1], 10) - 1,
      parseInt(d[2], 10),
      parseInt(tt[0], 10),
      parseInt(tt[1], 10),
      parseInt(tt[2], 10),
    ),
  );
}

export function getNumberParts(f: number): { int: number; float: number } {
  const int = Math.trunc(f);
  const float = f - int;
  return { int, float };
}

export function mustFloat(v): number | undefined {
  if (v === null || v === undefined) {
    return undefined;
  }
  return parseFloat(String(v));
}

export function copyToClipboard(value?: string, element?: HTMLElement | null) {
  if (!value) {
    return;
  }
  const textField = document.createElement('textarea');
  textField.innerText = value;
  if (element) {
    element.appendChild(textField);
  } else {
    document.body.appendChild(textField);
  }
  textField.select();
  document.execCommand('copy');
  notify(t('notifications.copied'), 'success');
  textField.remove();
}

function getNotifyColor(type?: string) {
  switch (type) {
    case 'error':
      return `${Defaults.redColor}ab`;
    case 'info':
      return `${Defaults.mainColor}ab`;
    case 'success':
      return `${Defaults.greenColor}ab`;
    case 'neutral':
      return `${Defaults.textColor}ab`;
    default:
      return `${Defaults.textColor}ab`;
  }
}

export function notify(
  string: string,
  type?: 'error' | 'info' | 'success' | 'neutral',
  duration?: number,
) {
  const notification = document.getElementById('notification');
  const notificationsContainer = document.getElementById('notifications-container');
  const textNode = document.createTextNode(string);
  if (notification && notificationsContainer) {
    const notificationClone = notification.cloneNode(true) as HTMLElement;
    notificationClone.appendChild(textNode);
    notificationClone.removeAttribute('id');
    notificationsContainer.appendChild(notificationClone);
    notificationClone.style.setProperty('opacity', '1');
    notificationClone.style.setProperty('background-color', getNotifyColor(type));
    window.setTimeout(() => {
      notificationClone?.style.setProperty('opacity', '0');
      window.setTimeout(() => notificationClone.remove(), 300);
    }, duration ?? 2000);
  }
}

export function findObjectInList<T>(list: T[], key: keyof T, value: any): T | undefined {
  const filtered = list.filter((i) => i[key] === value);
  return filtered.length > 0 ? filtered[0] : undefined;
}

export function sortData<T>(array: T[], cmp?: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  if (cmp) {
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      return order !== 0 ? order : a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  return array;
}

export type SortDirection = 'asc' | 'desc';

export function getSorter<K extends keyof any>(
  direction: SortDirection,
  sortBy?: K,
):
  | ((
      a: { [key in K]: number | string | Date },
      b: { [key in K]: number | string | Date },
    ) => number)
  | undefined {
  if (sortBy) {
    return direction === 'desc'
      ? (a, b) => desc(a, b, sortBy)
      : (a, b) => -desc(a, b, sortBy);
  }
  return undefined;
}

function desc<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  return b[orderBy] > a[orderBy] ? 1 : 0;
}

export function toUpperCase(s?: string): string | undefined {
  return s !== undefined ? s.toUpperCase() : undefined;
}

export function toLowerCase(s?: string): string | undefined {
  return s !== undefined ? s.toLowerCase() : undefined;
}

export function nvl(v: any, ...def: any): any {
  if (v === null) {
    v = undefined;
  }
  if (v !== undefined) {
    return v;
  }
  for (const d of def) {
    if (d !== undefined) {
      return d;
    }
  }
}

export function length(value: any): number {
  return value ? value.toString().length : 0;
}

export function openSkyBot() {
  openLink('http://sky-crypto.ru/sky');
}

export function openLink(path: string) {
  path = path.match(/^https?:/) ? path : '//' + path;
  window.open(path, '_blank');
}

export function openDoc(path: string) {
  window.open(path, '_blank');
}

export function extractFileName(path?: string): string | undefined {
  return path ? path.substring(path.lastIndexOf('/') + 1) : undefined;
}

export function isEmpty(obj: Record<any, any>): boolean {
  return Object.keys(obj).length === 0;
}

export function onEnterPress(f: () => void): (e) => void {
  return (e) => {
    if (e.which === 13 || e.keyCode === 13) {
      f();
      return false;
    }
    return true;
  };
}

export const MobileWidthLimit = 1280;

export function onResize(f: () => void) {
  f();
  window.addEventListener('resize', f);
}

export function offResize(f: () => void) {
  window.removeEventListener('resize', f);
}

export function onScroll(f: () => void) {
  f();
  window.addEventListener('scroll', f);
}

export function offScroll(f: () => void) {
  window.removeEventListener('scroll', f);
}

export function cl(f: boolean, ...ss: string[]): string {
  return f ? ss.join(' ') : '';
}

export function toggleClass(selector: string, f: boolean, className: string) {
  const el = document.querySelector(selector);
  if (el) {
    if (f) {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
}

export function isEquals(array1: any[], array2: any[]): boolean {
  if (array1.length === array2.length) {
    const array = array1.filter((val) => {
      return array2.includes(val);
    });
    return array.length === array2.length;
  }
  return false;
}

export function kebabToSpaces(str: string) {
  return str.replace(/[_,-]/g, ' ');
}

export const saveCSVFile = (csv, fileName) => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${fileName}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
