import { AxiosError } from 'axios';

import { setPage } from '../config/history';
import { logout, removeRefreshToken, setAuthToken } from '../helpers/authHeader';
import { API_URL, getRefreshToken, setRefreshToken } from '../helpers/settings';
import { generalRequest } from './axiosSetup';

let refreshInProcess = false;

export const AxiosErrorHandlers: {
  [index: string | number]: (error: AxiosError) => any;
} = {
  ['401']: async (error) => {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      removeRefreshToken();
      try {
        refreshInProcess = true;
        const { access, refresh }: any = await generalRequest({
          url: `${API_URL}/rest/v1/auth/refresh`,
          method: 'POST',
          data: { token: refreshToken },
          headers: {},
        });
        setAuthToken(access);
        setRefreshToken(refresh);
        refreshInProcess = false;
      } catch (error) {
        refreshInProcess = false;
        logout();
      }
    } else {
      !refreshInProcess && logout();
    }
  },
  ['412']: (error) => {},
  ['423']: (error) => {
    setPage('banned');
  },
  default: (error) => {
    console.error('UNHANDLED ERROR: ', error);
  },
};
