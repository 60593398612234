import copy from 'copy-to-clipboard';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { setPage } from '../../config/history';
import { t } from '../../config/i18n';
import { formatCrypto, formatString, nvl } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmUserLoginData } from '../../utils/types';
import { Defaults } from '../common';
import ActionButton from '../controls/ActionButton';
import Modal from '../controls/Modal';
import Txt from '../controls/Txt';
import {
  AdmSwitcherLabeled,
  getSettingsCrypto,
  offChangeCrypto,
  onChangeCrypto,
  renderIcon,
  ReturnButton,
} from './admin-common';
import {
  AddBalanceIcon,
  BidsIcon,
  ContentCopyIcon,
  DealsIcon,
  PromocodesIcon,
  ReviewIcon,
  TransactionsIcon,
  TVProfileIcon,
  TwoFAIcon,
  UnlinkIcon,
} from './icons';
import { assignUser } from './Users';

export interface UserProfile {
  id: number;
  nickname: string;
  email: string;
  telegramId: string;
  crypto?: string;
  balance?: number;
  frozen?: number;
  rating?: number;
  lifeDays?: number;
  dealsCount?: number;
  amount?: number;
  likes?: number;
  dislikes?: number;
  isVerify: boolean;
  isTemporary: boolean;
  isBaned: boolean;
  isMfaEnabled: boolean;
}

interface UserProfileExtended extends UserProfile {
  prevTelegramNickname: string;
  prevNickname: string;
}

interface Modal {
  isShown: boolean;
  newPassword: string;
  isPasswordCopied: boolean;
}
interface State {
  crypto: string;
  user: UserProfileExtended;
  modal: Modal;
  isMFAModalShown: boolean;
  buttonList: Record<ButtonTypes, PanelButton>;
}

type ButtonTypes =
  | 'transactions'
  | 'deals'
  | 'bids'
  | 'promocodes'
  | 'regenerate'
  | 'unbind-tg-bot'
  | 'reset-2FA'
  | 'login-data';
interface PanelButton {
  title: ButtonTypes;
  icon: React.ReactNode;
  caption?: string;
  shown?: boolean;
  onClick?: () => void;
}

export default class User extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  regeneratePassword = () => {
    const {
      user: { nickname },
    } = this.state;
    if (confirm(formatString(t('admin.user.password-hint'), nickname))) {
      api.admin.regenerateUserPassword(nickname).then(({ new_password: newPassword }) => {
        this.setState({ modal: { isShown: true, newPassword, isPasswordCopied: false } });
      });
    }
  };

  reset2FA = () => {
    const {
      user,
      user: { nickname, telegramId },
    } = this.state;

    if (confirm(formatString(t('admin.user.2FA-hint'), nickname))) {
      api.admin.resetUser2FA(nickname).then(() => {
        this.setState({
          buttonList: this.updateButtonList(!!telegramId, false),
          user: {
            ...user,
            mfa: false,
          },
          isMFAModalShown: true,
        });
      });
    }
  };

  unlinkTelegramBot = () => {
    const {
      user: { nickname, prevTelegramNickname, prevNickname },
    } = this.state;

    if (
      confirm(
        formatString(
          t('admin.user.unlink-hint'),
          nickname,
          prevNickname === nickname ? prevTelegramNickname : prevNickname,
        ),
      )
    ) {
      api.admin.deleteUserAccountJoins(nickname).then(() => {
        this.update();
      });
    }
  };

  public state: State = {
    crypto: getSettingsCrypto(),
    user: {
      id: 0,
      email: '',
      nickname: this.props.match.params.nickname,
      telegramId: '',
      isVerify: false,
      isTemporary: false,
      isBaned: false,
      isMfaEnabled: false,
      prevTelegramNickname: '',
      prevNickname: '',
    },
    modal: {
      isShown: false,
      newPassword: '',
      isPasswordCopied: false,
    },
    isMFAModalShown: false,
    buttonList: {
      transactions: {
        title: 'transactions',
        icon: TransactionsIcon,
      },
      deals: {
        title: 'deals',
        icon: DealsIcon,
      },
      bids: {
        title: 'bids',
        icon: BidsIcon,
      },
      promocodes: {
        title: 'promocodes',
        icon: PromocodesIcon,
      },
      regenerate: {
        title: 'regenerate',
        icon: AddBalanceIcon,
        caption: 'admin.user.regenerate-password',
        onClick: this.regeneratePassword,
      },
      'login-data': {
        title: 'login-data',
        icon: TVProfileIcon,
        caption: 'admin.user.watch-user-login-data',
      },
      'unbind-tg-bot': {
        title: 'unbind-tg-bot',
        icon: UnlinkIcon,
        caption: 'admin.user.unbind-tg-bot',
        shown: false,
        onClick: this.unlinkTelegramBot,
      },
      'reset-2FA': {
        title: 'reset-2FA',
        icon: TwoFAIcon,
        caption: 'admin.user.reset-2FA',
        shown: false,
        onClick: this.reset2FA,
      },
    },
  };

  componentDidMount(): void {
    onChangeCrypto(this.onChangeCrypto);
    this.update();
  }

  componentWillUnmount(): void {
    offChangeCrypto(this.onChangeCrypto);
  }

  onChangeCrypto = (crypto: string) => this.setState({ crypto }, this.update);

  update = () => {
    const { nickname } = this.props.match.params;
    const { crypto } = this.state;

    api.admin
      .user(crypto.toLowerCase(), nickname)
      .then((data) => {
        const user = assignUser(data, crypto);
        const isUserLinkedToBot = !!user.telegramId;
        const buttonList = this.updateButtonList(isUserLinkedToBot, user.isMfaEnabled);

        if (isUserLinkedToBot) {
          api.admin.getUserAccountJoins(user.nickname).then((data) => {
            this.setState({
              user: {
                ...user,
                prevTelegramNickname: data.account_tg,
                prevNickname: data.account_web,
              },
              buttonList,
            });
          });
        }
        if (!isUserLinkedToBot) {
          return this.setState({
            user,
            buttonList,
          });
        }
      })
      .catch(() => undefined);
  };

  banUser = (ban: boolean) => this.updateUser(ban, undefined);

  verifyUser = (verify: boolean) => this.updateUser(undefined, verify);

  updateUser = (banned?, verified?: boolean) => {
    const { crypto, user } = this.state;
    api.admin
      .updateUser(crypto.toLowerCase(), user.nickname, banned, verified)
      .then(() => {
        if (banned !== undefined) {
          user.isBaned = banned;
        }
        if (verified !== undefined) {
          user.isVerify = verified;
        }
        this.setState({ user });
      })
      .catch(() => undefined);
  };

  pageClick = (nickname, page?: string) => () =>
    setPage(`admin/users/${nickname}/${page}`);

  fillUpBalance = () => {
    const s = prompt(t('admin.user.balance-hint'));
    if (!s) {
      return;
    }

    const fillUp = parseFloat(s);
    if (!fillUp) {
      alert(t('admin.user.balance-error'));
    }

    const { crypto, user } = this.state;
    const newBalance = nvl(user.balance, 0) + fillUp;
    const msg = formatString(
      t('admin.user.balance-mask'),
      formatCrypto(newBalance, user.crypto),
      user.crypto,
    );
    if (!window.confirm(msg)) {
      return;
    }

    api.admin
      .updateUser(crypto.toLowerCase(), user.nickname, undefined, undefined, newBalance)
      .then(() => this.update())
      .catch(() => undefined);
  };

  updateButtonList = (
    showUnbindTelegramBotButton: boolean,
    show2FAResetButton: boolean,
  ): Record<ButtonTypes, PanelButton> => {
    const { buttonList } = this.state;
    const renderData = { ...buttonList };

    renderData['unbind-tg-bot'].shown = showUnbindTelegramBotButton;

    renderData['reset-2FA'].shown = show2FAResetButton;

    return renderData;
  };

  toggleMFAResetModal = () => {
    const { isMFAModalShown } = this.state;
    this.setState({ isMFAModalShown: !isMFAModalShown });
  };

  render(): React.ReactNode {
    const { nickname } = this.props.match.params;
    const { user, modal, buttonList, isMFAModalShown } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'adm-page-title'}>
            <Txt k={'admin.user.title'} args={[nickname]} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <table className={'adm-table'}>
            <tbody>
              <tr>
                <td>
                  <Txt k={'admin.users.table.nickname'} />
                </td>
                <td className={'color-main'}>{nickname}</td>
              </tr>
              {user?.email && (
                <tr>
                  <td>
                    <Txt k={'admin.users.table.email'} />
                  </td>
                  <td>{user.email}</td>
                </tr>
              )}
              <tr>
                <td>
                  <Txt k={'admin.users.table.telegram-user'} />
                </td>
                <td>{user.telegramId}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.rating'} />
                </td>
                <td>{user.rating}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.verified'} />
                </td>
                <td>
                  <AdmSwitcherLabeled
                    value={user.isVerify}
                    label={`common.${user.isVerify ? 'yes' : 'no'}`}
                    onChange={this.verifyUser}
                    className={'adm-user-verified'}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.life-days'} />
                </td>
                <td>{user.lifeDays}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.deals'} />
                </td>
                <td>
                  <Txt
                    k={'admin.users.table.deals-mask'}
                    args={[
                      user.dealsCount,
                      formatCrypto(user.amount, user.crypto),
                      user.crypto,
                    ]}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.reviews'} />
                </td>
                <td>
                  <ReviewIcon className={'adm-user-likes'} />
                  {user.likes}
                  <ReviewIcon className={'adm-user-dislikes'} />
                  {user.dislikes}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.balance'} />
                </td>
                <td>
                  {formatCrypto(user.balance, user.crypto)}&nbsp;{user.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.frozen'} />
                </td>
                <td>
                  {formatCrypto(user.frozen, user.crypto)}&nbsp;{user.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.temporary'} />
                </td>
                <td>
                  <Txt k={`common.${user.isTemporary ? 'yes' : 'no'}`} />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.users.table.banned'} />
                </td>
                <td>
                  <AdmSwitcherLabeled
                    value={user.isBaned}
                    label={`common.${user.isBaned ? 'yes' : 'no'}`}
                    onChange={this.banUser}
                    className={'adm-user-banned'}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <div className={'adm-page-user-buttons'}>
            {Object.values(buttonList)
              .filter(({ shown }) => shown !== false)
              .map(({ onClick, title, icon, caption }, n) => (
                <button
                  className={'adm-page-button adm-page-button__user-desc'}
                  key={n}
                  onClick={onClick || this.pageClick(nickname, title)}>
                  {renderIcon(icon)}
                  <span>
                    <Txt k={caption ? caption : `admin.${title}.title`} />
                  </span>
                </button>
              ))}
          </div>
        </div>
        <Modal
          onRequestClose={() =>
            this.setState({
              modal: { isShown: false, newPassword: '', isPasswordCopied: false },
            })
          }
          subtitle={
            <div className="adm-user__modal-subtitle">
              <p className="adm-user__modal-subtitle_centered">
                <Txt k="admin.user.modal.subtitle-1" args={[nickname]} />
              </p>
              <p className="adm-user__modal-subtitle-password adm-user__modal-subtitle_centered">
                {modal.newPassword}
              </p>
              <p className="adm-user__modal-subtitle_centered">
                <Txt k="admin.user.modal.subtitle-2" />
              </p>
            </div>
          }
          isOpen={modal.isShown}
          style={{ maxWidth: '40%', minWidth: '320px' }}>
          <ActionButton
            caption={`admin.user.${
              modal.isPasswordCopied ? 'new-password-copied' : 'copy-new-password'
            }`}
            img={<ContentCopyIcon size={28} color={Defaults.whiteColor} />}
            onClick={() => {
              this.setState({ modal: { ...modal, isPasswordCopied: true } });
              copy(modal.newPassword);
            }}
            disabled={modal.isPasswordCopied}
          />
        </Modal>
        <Modal
          onRequestClose={this.toggleMFAResetModal}
          subtitle={
            <div className="adm-user__modal-subtitle">
              <p className="adm-user__modal-subtitle_centered">
                <Txt k="admin.user.2FA-reset-success" args={[nickname]} />
              </p>
            </div>
          }
          isOpen={isMFAModalShown}
          style={{
            maxWidth: '40%',
            minWidth: '320px',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
            }}>
            <ActionButton
              caption={`admin.user.ok`}
              width="100px"
              onClick={this.toggleMFAResetModal}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
