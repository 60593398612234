import './assets/scss/main.scss';
import './config/i18n.ts';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

if (!window['this is IE']) {
  ReactDOM.render(<App />, document.getElementById('root'));
}
