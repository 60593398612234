import React from 'react';

import { formatDateTime } from '../../helpers/utils';
import { api } from '../../utils/api';
import Txt from '../controls/Txt';
import { AdmTable, AdmTablePopup } from './admin-common';

interface Currency {
  id: string;
  created_at: string;
  is_active: boolean;
  rate_variation: number;
  usd_rate: number;
}

interface State {
  rows: Currency[];
}

export default class Currencies extends React.Component {
  public state: State = {
    rows: [],
  };

  componentDidMount(): void {
    this.update();
  }

  update = () => {
    api.admin
      .currencies()
      .then((result) => this.setState({ rows: this.createNewRows(result) }))
      .catch(() => undefined);
  };

  createNewRows = (data: Currency[]): Currency[] =>
    data.map((currency) => ({
      id: currency.id.toUpperCase(),
      created_at: currency.created_at,
      is_active: currency.is_active,
      rate_variation: currency.rate_variation * 100 || 0,
      usd_rate: currency.usd_rate || 0,
    }));

  updateCurrency = (id: string, key: string, value: number | boolean) => {
    api.admin
      .updateCurrency(id, key, value)
      .then((result) => this.setState({ rows: this.createNewRows(result) }))
      .catch(() => undefined);
  };

  render(): React.ReactNode {
    const { rows } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <div className={'adm-page-title'}>
            <Txt k={'admin.currencies.title'} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <AdmTable
            columns={[
              {
                id: 'id',
                caption: 'admin.currencies.table.id',
              },
              {
                id: 'created_at',
                caption: 'admin.currencies.table.createdAt',
                render: (createdAt) => formatDateTime(new Date(createdAt)),
                minWidth: '11rem',
              },
              {
                id: 'is_active',
                caption: 'admin.currencies.table.status',
                render: (isActive, row) => (
                  <>
                    <span className={`color-${isActive ? 'true' : 'false'}`}>
                      &bull;&nbsp;
                    </span>
                    <Txt
                      k={`admin.api.table.status-${isActive ? 'online' : 'offline'}`}
                    />
                    <CurrencyEnableBox
                      isActive={isActive}
                      onClick={() => this.updateCurrency(row.id, 'is_active', !isActive)}
                    />
                  </>
                ),
              },
              {
                id: 'usd_rate',
                caption: 'admin.currencies.table.usdRate',
                minWidth: '8rem',
              },
            ]}
            rows={rows}
            defaultSortBy={'id'}
          />
        </div>
      </div>
    );
  }
}

const CurrencyEnableBox = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button delete-transaction'} onClick={onClick}>
      <span>
        <Txt k={`admin.api.table.merchant-${isActive ? 'disable' : 'enable'}`} />
      </span>
    </div>
  </AdmTablePopup>
);
