import { createBrowserHistory } from 'history';

import { listenNotifications, Notifications } from '../helpers/notifications';
import { escapeHtml, formatString } from '../helpers/utils';
import { onLanguageChange, t, tt } from './i18n';

export const history = createBrowserHistory();

const aliases = [
  { pattern: /admin\/merchants\/(.+)/, page: 'admin.merchant' },
  { pattern: /admin\/users\/(.+)\/chat/, page: 'admin.user-chat' },
  { pattern: /admin\/users\/(.+)\/transactions/, page: 'admin.user-transactions' },
  { pattern: /admin\/users\/(.+)\/deals/, page: 'admin.user-deals' },
  { pattern: /admin\/users\/(.+)\/bids/, page: 'admin.user-bids' },
  { pattern: /admin\/users\/(.+)\/promocodes/, page: 'admin.user-promocodes' },
  { pattern: /admin\/users\/(.+)\/transit/, page: 'admin.user-transit' },
  { pattern: /deals\/(.+)/, page: 'deal' },
  { pattern: /bids\/(new)/, page: 'bid-new' },
  { pattern: /bids\/(.+)/, page: 'bid' },
  { pattern: /users\/(.+)/, page: 'user' },
  { pattern: /wallet\/(.*)/, page: 'wallet' },
];

let unreadNotifications = 0;

onPageChange((page) => setDocumentTitle(page));
onLanguageChange(() => setDocumentTitle(getPage()));
setTimeout(() => setDocumentTitle(getPage()), 100);
listenNotifications(onNotificationsChange);

function setDocumentTitle(page: Page) {
  if (page.name === '') {
    page.name = 'home';
  }
  const title = tt(page.category, `${page.name}.title`, t('app-title'));
  const notifications = unreadNotifications > 0 ? `(${unreadNotifications}) ` : '';
  document.title =
    notifications + escapeHtml(formatString(title, page.param)) || t('app-title');
}

function parsePath(path: string): Page {
  for (const alias of aliases) {
    const match = path.match(alias.pattern);
    if (match && match.length > 1) {
      return { name: alias.page, param: match[1] };
    }
  }
  const name = path.substr(1);
  const ss = name.split('/');
  if (ss.length > 1) {
    return { category: ss[0], name: ss[1] };
  }
  return { name };
}

function onNotificationsChange(notifications: Notifications) {
  if (unreadNotifications !== notifications.unreadGroups) {
    unreadNotifications = notifications.unreadGroups;
    setDocumentTitle(getPage());
  }
}

export interface Page {
  name: string;
  param?: string;
  category?: string;
}

export function getPage(): Page {
  return parsePath(window.location.pathname);
}

export function setPage(page?: string) {
  history.push(`/${page || ''}`);
  window.scroll(0, 0);
}

export function setPreviousPage() {
  history.goBack();
}

export function onPageChange(callback: (page: Page) => void) {
  history.listen((location: any) => callback(parsePath(location.pathname)));
}

export default history;
