import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import React from 'react';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { t } from '../../config/i18n';
import {
  formatDate,
  formatNumber,
  formatString,
  getSorter,
  sortData,
  SortDirection,
} from '../../helpers/utils';
import { Defaults, Hr, px, TextEx } from '../common';
import Txt from './Txt';

const tableHeaderColor = '#dadee0';
const cellPadding = '0 1.2rem';

export interface ColumnHeader<T> {
  id: string;
  noSorting?: boolean;
  caption?: string;
  width?;
  minWidth?: number | string;
  render?: (
    value: any,
    row: any,
    n: number,
    rows: T[],
    setRows: (data: T[]) => void,
  ) => React.ReactNode;
}

export interface TableExProps<T> {
  className?: string;
  title?: string;
  titleArgs?: any[];
  titleComponent?: React.ReactNode;
  subHeader?: React.ReactNode;
  columns: ColumnHeader<T>[];
  isShowMoreBtnDisabled: boolean;
  rows: Array<{ [key in keyof T] }>;
  setRows: () => void;
  onShowMore?: () => boolean;
  flatTop?: boolean;
  minWidth?;
  left?;
  top?: number | string;
  sortBy: keyof T | undefined;
  sortDirection: SortDirection;
  sorter?: (sortDirection: SortDirection, sortBy?: keyof T) => (a, b) => number;
  noPadding?: boolean;
  headerColor?: string;
  backgroundColor?: string;
  textColor?: string;
  headerFontSize?;
  cellFontSize?: string;
  headClassName?: string;
  border?: string;
  onRowClick?: (i: number, row: any) => void;
  handleRequestSort?: (event: React.MouseEvent<unknown>, prop: any) => void;
}

export default function TableEx<T>({
  className,
  title,
  titleArgs = [],
  titleComponent,
  subHeader,
  columns,
  rows,
  setRows,
  isShowMoreBtnDisabled,
  onShowMore,
  flatTop,
  minWidth,
  left,
  top,
  sorter,
  sortDirection,
  sortBy,
  noPadding,
  headerColor = tableHeaderColor,
  backgroundColor = 'white',
  textColor,
  headerFontSize = '1.125rem',
  cellFontSize = '1rem',
  headClassName = '',
  border,
  onRowClick,
  handleRequestSort,
}: TableExProps<T>) {
  const createSortHandler = (id: keyof T) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort && handleRequestSort(event, id);
  };

  const handleMoreData = () => {
    onShowMore && onShowMore();
  };

  return (
    <TableRoot className={className} minWidth={minWidth} left={left} top={top}>
      <Paper
        style={{
          boxShadow: 'unset',
          ...(flatTop ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 } : {}),
          backgroundColor,
        }}>
        {title ? (
          <>
            <TableTitle>
              <TextEx weight={'500'} size={'1.2rem'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatString(t(title), ...titleArgs) || '',
                  }}
                />
              </TextEx>
              {titleComponent ? titleComponent : undefined}
            </TableTitle>
            <Hr />
            {subHeader}
          </>
        ) : undefined}
        <TableWrap noPadding={noPadding}>
          <Table>
            <TableHead
              className={headClassName}
              style={{ backgroundColor: headerColor, borderRadius: 6 }}>
              <TableRow>
                {columns.map((column, i) => (
                  <TableCell
                    key={`th-${i}`}
                    padding={'none'}
                    sortDirection={sortBy === column.id ? sortDirection : false}
                    style={{
                      padding: cellPadding,
                      height: '3.75rem',
                      minWidth: column.minWidth,
                      maxWidth: column.width,
                      border: 'unset',
                      borderLeft: i > 0 ? border : undefined,
                      borderRadius: borderRadius(i, columns.length - 1),
                    }}>
                    {column.caption ? (
                      <TableSortLabel
                        active={sortBy === column.id}
                        disabled={column.noSorting}
                        direction={sortDirection}
                        onClick={createSortHandler(column.id as keyof T)}
                        href={''}>
                        <TextEx color={textColor} size={headerFontSize}>
                          <Txt k={column.caption} />
                        </TextEx>
                      </TableSortLabel>
                    ) : undefined}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortData(
                rows,
                sorter ? sorter(sortDirection, sortBy) : getSorter(sortDirection, sortBy),
              ).map((row, i) => (
                <TableRow
                  hover
                  key={i}
                  style={{ height: '3.75rem' }}
                  className={row?.['className'] ? row['className'] : undefined}
                  onClick={() => (onRowClick ? onRowClick(i, row) : undefined)}>
                  {columns.map((column, j) => (
                    <TableCell
                      key={j}
                      padding={'none'}
                      style={{
                        padding: cellPadding,
                        borderBottom: 'unset',
                        border: border ? border : 'unset',
                        fontSize: cellFontSize,
                        color: textColor,
                      }}>
                      {column.render
                        ? column.render(row[column.id], row, i, rows, setRows)
                        : toString(row[column.id])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            {onShowMore ? (
              <tfoot>
                <tr>
                  <td colSpan={100} style={{ padding: 0 }}>
                    <Translation>
                      {(t) => (
                        <Button
                          href={undefined}
                          disabled={isShowMoreBtnDisabled}
                          title={t('table.action.more')}
                          onClick={() => handleMoreData()}
                          style={{
                            width: '100%',
                            height: '1.875rem',
                            padding: 0,
                            backgroundColor: headerColor,
                            color: Defaults.grayColor,
                            borderRadius: Defaults.borderRadius,
                            fontSize: '1.125rem',
                          }}>
                          &bull;&nbsp;&bull;&nbsp;&bull;
                        </Button>
                      )}
                    </Translation>
                  </td>
                </tr>
              </tfoot>
            ) : undefined}
          </Table>
        </TableWrap>
      </Paper>
    </TableRoot>
  );
}

const TableRoot = styled.div`
  width: 100%;
  min-width: ${(props) => px(props.minWidth)};
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
`;

const TableTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 2.1rem;
  height: 4.625rem;

  @media (max-width: 576px) {
    padding: 1rem 1.4rem;
    flex-wrap: wrap;
    font-size: 1rem;
    /* flex-direction: column; */
    /* align-items: unset; */
  }
`;
const TableWrap = styled.div`
  ${(props) => (!props.noPadding ? 'padding: 1.875rem;' : '')}
`;

function borderRadius(i, last): string | undefined {
  switch (i) {
    case 0:
      return '0.3125rem 0 0 0.3125rem';
    case last:
      return '0 0.3125rem 0.3125rem 0';
    default:
      return;
  }
}

function toString(value?: any): string {
  if (value) {
    switch (typeof value) {
      case 'number':
        return formatNumber(value);
      case 'object':
        return value instanceof Date ? formatDate(value) : value.toString();
      default:
        return value.toString();
    }
  }
  return '';
}
