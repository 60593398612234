import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { cl, findObjectInList, nvl } from '../../helpers/utils';
import {
  calcSize,
  ComponentRootEx,
  Defaults,
  onClickOutside,
  PopoverEx,
  px,
} from '../common';
import Txt from './Txt';

const defaultFontSize = '1rem';

interface FontProps {
  family?: string;
  size?: string | number;
  weight?: string | number;
  color?: string;
}

export interface SelectExProps {
  className?: string;
  disabled?: boolean;
  width?: number | string;
  minWidth?: number | string;
  maxHeight?: number | string;
  height?: number | string;
  top?: number | string;
  left?: number | string;
  onChange?: (id, value) => void;
  fontFamily?: string;
  fontSize?: number | string;
  fontWeight?: number | string;
  fontColor?: string;
  fontOffSet?: number;
  component?: ReactNode;
  popupComponent?: ReactNode;
  caption?: string;
  arrowColor?: string;
  hideArrow?: boolean;
  id?: string;
  options?: SelectExOptionProps[];
  allOptions?: boolean;
  defaultFirst?: boolean;
  borderColor?: string;
  hoverColor?: string;
  backgroundColor?: string;
  allowScroll?: boolean;
  borderRadius?: number | string;
}

export interface SelectExOptionProps {
  id: string;
  value?: string;
  caption?: string;
  img?: ReactNode;
  imgPosition?: 'left' | 'right';
  fontSize?: number | string;
  font?: FontProps;
  textAlign?: string;
  component?: ReactNode;
  hint?: string;
}

export default class SelectEx extends React.Component<SelectExProps> {
  state: {
    opened: boolean;
  } = {
    opened: false,
  };

  popoverOpen = (event) => {
    if (this.state.opened) {
      this.popoverClose();
      return;
    }
    const { options } = this.props;
    if (!!options && options.length > 0) {
      onClickOutside(event.currentTarget, this.popoverClose);
      this.setState({ opened: true });
    }
  };

  popoverClose = () => {
    this.setState({ opened: false });
  };

  getSelectedOption(): SelectExOptionProps | undefined {
    const { id, options, defaultFirst } = this.props;
    if (!!id && !!options) {
      const selected = findObjectInList(options, 'id', id);
      if (selected) {
        return selected;
      }
    }
    if (defaultFirst && !!options) {
      return options[0];
    }
  }

  getOptions(selectedId?: string): SelectExOptionProps[] | undefined {
    const { options, allOptions = false } = this.props;
    if (allOptions) {
      return options;
    }
    if (options) {
      return options.filter((option) => selectedId !== option.id);
    }
  }

  render(): React.ReactNode {
    const {
      className,
      width,
      minWidth,
      height = Defaults.height,
      maxHeight,
      top,
      left,
      component,
      popupComponent,
      caption,
      arrowColor = Defaults.mainColor,
      hideArrow = false,
      onChange,
      fontFamily,
      fontSize,
      fontColor,
      fontWeight,
      borderColor,
      hoverColor,
      backgroundColor,
      allowScroll,
      borderRadius,
      disabled,
      fontOffSet,
    } = this.props;

    const selected = this.getSelectedOption();
    const options = this.getOptions(selected ? selected.id : undefined);

    return (
      <SelectRoot
        className={`${className} ${cl(this.state.opened, 'active')}`}
        width={width}
        minWidth={minWidth}
        height={height}
        top={top}
        left={left}
        onClick={!disabled ? this.popoverOpen : undefined}
        borderColor={borderColor}
        hoverColor={hoverColor}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        fontOffSet={fontOffSet}
        cursor={disabled ? 'default' : 'cursor'}>
        <div style={{ flexGrow: 1 }}>
          {component ? (
            component
          ) : selected ? (
            <SelectExOption
              option={selected}
              caption={caption}
              className={'selected'}
              borderRadius={borderRadius}
              height={height}
              fontSize={fontSize}
              fontColor={fontColor}
              backgroundColor={backgroundColor}
              hoverColor={hoverColor}
            />
          ) : undefined}
        </div>
        <Popover
          className={`popover ${cl(this.state.opened, 'opened')}`}
          top={height}
          maxHeight={maxHeight}
          allowScroll={allowScroll}
          backgroundColor={backgroundColor}
          borderRadius={borderRadius}>
          {popupComponent
            ? popupComponent
            : options
            ? options.map((option, i) => (
                <SelectExOption
                  key={i}
                  option={option}
                  onChange={onChange}
                  height={height}
                  fontSize={fontSize}
                  fontColor={fontColor}
                  backgroundColor={backgroundColor}
                  borderRadius={borderRadius}
                  hoverColor={hoverColor}
                />
              ))
            : undefined}
        </Popover>
        {!hideArrow ? (
          <ArrowContainer>
            <Arrow size={'.75rem'} fill={arrowColor} opened={this.state.opened} />
          </ArrowContainer>
        ) : undefined}
      </SelectRoot>
    );
  }
}

const SelectExOption = ({
  option,
  className,
  height,
  caption = option.caption,
  fontFamily = !!option.font && !!option.font.family
    ? option.font.family
    : Defaults.fontFamily,
  fontSize = !!option.font && !!option.font.size ? option.font.size : option.fontSize,
  fontColor = option.font ? option.font.color : undefined,
  textAlign = option.textAlign,
  backgroundColor,
  hoverColor,
  onChange,
  borderRadius,
}: {
  option: SelectExOptionProps;
  className?: string;
  height: number | string;
  caption?: string;
  fontFamily?: string;
  fontWeight?: number | string;
  fontSize?: number | string;
  fontColor?: string;
  textAlign?: string;
  backgroundColor?: string;
  hoverColor?: string;
  onChange?: (id, value) => void;
  borderRadius?: number | string;
}) => (
  <OptionContainer
    className={className}
    height={height}
    backgroundColor={backgroundColor}
    hoverColor={hoverColor}
    borderRadius={borderRadius}
    onClick={onChange ? () => onChange(option.id, option.value) : undefined}>
    {reverseNodes(
      option.imgPosition === 'right',
      option.img ? <React.Fragment key={1}>{option.img}</React.Fragment> : undefined,
      option.img ? <OptionSpacer key={2} /> : undefined,
      <OptionValueBox key={3} textAlign={textAlign} title={option.hint}>
        {caption ? (
          <OptionValueCaption>
            <Txt k={caption} />
          </OptionValueCaption>
        ) : undefined}
        {option.component ? (
          option.component
        ) : (
          <OptionValue
            fontFamily={fontFamily}
            fontSize={fontSize}
            fontColor={fontColor}
            textAlign={textAlign}
            fontWeight={option.font ? option.font.weight : undefined}>
            {option.value !== undefined ? option.value : option.id}
          </OptionValue>
        )}
      </OptionValueBox>,
    )}
  </OptionContainer>
);

const Arrow = ({
  size,
  fill,
  opened,
}: {
  size: number | string;
  fill: string;
  opened: boolean;
}) => (
  <ArrowRoot
    size={size}
    viewBox={'0 0 452 452'}
    fill={fill}
    className={opened ? 'opened' : ''}>
    <path
      d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751
		c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0
		c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
    />
  </ArrowRoot>
);

const SelectRoot = styled(ComponentRootEx)`
  transition: ${Defaults.transition};
  & div {
    transition: ${Defaults.transition};
  }
  [style='flex-grow: 1;'] {
    max-width: calc(100% - ${(props) => nvl(props.fontOffSet, 2)}rem);
    /* max-width: none; */
  }
  &.userBalance div {
    max-width: 100%;
  }
  &.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;
const OptionContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;

  padding-right: 0;
  background-color: inherit;
  position: relative;
  padding-left: 1rem;
  position: relative;
  min-width: 100%;

  .select-icon-wrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 2rem;
    left: -1rem;
    z-index: 4;

    img {
      max-width: 100%;
      height: 1.4rem;
    }
  }
  &.selected {
    border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  }

  &:not(.selected):hover {
    background-color: ${(props) => props.hoverColor || Defaults.hoverColor};
  }

  &:not(.selected):last-child {
    border-bottom-left-radius: ${(props) =>
      px(props.borderRadius || Defaults.borderRadius)};
    border-bottom-right-radius: ${(props) =>
      px(props.borderRadius || Defaults.borderRadius)};
  }

  &:not(.selected) {
    height: ${(props) => px(props.height)};
    border-top: 1px solid ${(props) => props.hoverColor || Defaults.hoverColor};
    ${(props) => (props.onClick ? 'cursor: pointer;' : '')}
  }
`;
const OptionSpacer = styled.div`
  display: flex;
  width: ${px(Defaults.spacing)};
  height: 1px;
`;
const OptionValueBox = styled.div`
  padding: 0.3125rem;
  overflow: hidden;
  min-width: 3rem;
  /* flex-grow: 1; */
  div {
    flex-grow: 1;
    max-width: 100%;
  }
  /* max-width: calc(100% - 2rem); */
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    /* width: 100%; */
    display: block;
  }
  ${(props) => (props.textAlign ? 'width: 100%;' : '')}
`;
const OptionValueBoxItem = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;
const OptionValueCaption = styled(OptionValueBoxItem)`
  font-size: 1rem;
  color: ${Defaults.grayColor};
`;
const OptionValue = styled(OptionValueBoxItem)`
  font-size: ${(props) => px(props.fontSize || defaultFontSize)};
  color: ${(props) => props.fontColor || 'black'};
  ${(props) => (props.textAlign ? `justify-content: ${props.textAlign};` : '')}
  ${(props) => (props.fontFamily ? `font-family: ${props.fontFamily};` : '')}
    ${(props) => (props.fontWeight ? `font-weight: ${props.fontWeight};` : '')}
`;
const Popover = styled(PopoverEx)`
  width: calc(100% + ${calcSize(Defaults.borderWidth, (n) => n * 2)});
  ${(props) => (props.maxHeight ? `max-height: ${px(props.maxHeight)};` : '')}
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: ${(props) =>
    px(props.borderRadius || Defaults.borderRadius)};
  border-bottom-right-radius: ${(props) =>
    px(props.borderRadius || Defaults.borderRadius)};
  padding: 0 1px;

  ${(props) => (props.allowScroll ? 'overflow-y: auto;' : '')}
`;
const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
`;
const ArrowRoot = styled.svg`
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  transition-duration: 0.3s;
  margin-left: 0.5rem;
  flex: 0 0 ${(props) => px(props.size)};
  &.opened {
    transform: rotate(180deg);
  }
`;

function reverseNodes(condition: boolean, ...nodes: ReactNode[]): ReactNode[] {
  return condition ? nodes.reverse() : nodes;
}
