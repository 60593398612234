import React from 'react';

import { formatCrypto, formatDateTime, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmTablePopup, AdmUserLink } from './admin-common';
import PageLayout from './PageLayout';

export interface Promocode {
  id: number;
  code: string;
  user: string;
  symbol: string;
  amount: number;
  count: number;
  activations: number;
  activatedBy?: string;
  status: 'active' | 'closed';
  createdAt?: Date;
}

export interface PromocodeActivations {
  user: string;
  activatedAt?: Date;
}

export const columns = [
  {
    id: 'createdAt',
    caption: 'admin.promocodes.table.created-at',
    render: (createdAt) => formatDateTime(createdAt),
    minWidth: '10rem',
  },
  {
    id: 'user',
    caption: 'admin.promocodes.table.user',
    render: (user) => <AdmUserLink nickname={user} />,
  },
  {
    id: 'code',
    caption: 'admin.promocodes.table.code',
  },
  {
    id: 'amount',
    caption: 'admin.promocodes.table.amount',
    render: (amount, row) => (
      <>
        {formatCrypto(amount, row.crypto)}&nbsp;{row.crypto}
      </>
    ),
  },
  {
    id: 'count',
    caption: 'admin.promocodes.table.quantity',
  },
  {
    id: 'activations',
    caption: 'admin.promocodes.table.activated',
    render: (activated, row) => (
      <>
        {activated}
        {activated > 0 ? <ActivationsBox id={row.id} /> : undefined}
      </>
    ),
  },
  {
    id: 'status',
    noSorting: true,
    caption: 'admin.promocodes.table.status',
    render: (status) => <Txt k={`admin.promocodes.table.status-${status}`} />,
  },
];

export default class Promocodes extends React.Component {
  render(): React.ReactNode {
    return (
      <PageLayout<Promocode>
        request={getPromocodes}
        headerTitle={'admin.promocodes.title'}
        searchInputHint={'admin.promocodes.search-promocode'}
        defaultSortBy={'createdAt'}
        tableColumns={columns}
      />
    );
  }
}

const ActivationsBox = ({ id }: { id: number }) => {
  const [activations, setActivations] = React.useState<PromocodeActivations[]>([]);

  function update() {
    api.admin
      .promocode(id)
      .then((promocode) => {
        if (promocode.activations) {
          const activations: PromocodeActivations[] = [];
          for (const a of promocode.activations) {
            activations.push({
              user: a.user,
              activatedAt: parseDate(a.created_at),
            });
          }
          setActivations(activations);
        }
      })
      .catch(() => undefined);
  }

  return (
    <AdmTablePopup className={'adm-activations-box'} onOpen={update}>
      <table>
        <thead>
          <tr>
            <th>
              <Txt k={'admin.promocodes.table.activated-by'} />
            </th>
            <th>
              <Txt k={'admin.promocodes.table.activated-at'} />
            </th>
          </tr>
        </thead>
        <tbody>
          {activations.map((a, n) => (
            <tr key={n}>
              <td>
                <AdmUserLink nickname={a.user} />
              </td>
              <td>{formatDateTime(a.activatedAt)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </AdmTablePopup>
  );
};

export function getPromocodes(
  params: CommonRequestParams & { user?: string },
): Promise<Promocode[]> {
  return new Promise((resolve) => {
    api.admin
      .promocodes({ ...params, symbol: params.symbol?.toLowerCase() })
      .then((result) => {
        const promocodes: Promocode[] = [];
        for (const p of result) {
          promocodes.push({
            id: p.id,
            code: p.code,
            user: p.user,
            symbol: p.symbol.toUpperCase(),
            amount: p.amount,
            count: p.count,
            activations: p.activations,
            status: p.activations < p.count ? 'active' : 'closed',
            createdAt: parseDate(p.created_at),
          });
        }
        resolve(promocodes);
      })
      .catch(() => undefined);
  });
}
