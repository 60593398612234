import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import styled from 'styled-components';

import { getLanguage } from '../../config/i18n';
import { Defaults, px } from '../common';

const DatePickerEx = ({
  value,
  onChange,
  minWidth,
  width,
  height,
  fontSize,
  borderColor,
  onBlur,
  customStyle,
  color,
}: {
  value?: any;
  minWidth?;
  width?;
  height?: number | string;
  fontSize?: number | string;
  onBlur?: () => void;
  borderColor?: string;
  onChange?: (value) => void;
  customStyle?: string;
  color?: string;
}) => (
  <DateTimePickerRoot
    className={'datepicker'}
    minWidth={minWidth}
    width={width}
    color={color}
    height={height}
    onBlur={onBlur}
    customStyle={customStyle}
    fontSize={fontSize}
    borderColor={borderColor}>
    <DateTimePicker
      calendarClassName={'sky-calendar'}
      value={value}
      onChange={onChange}
      format={localeFormat}
      locale={getLanguage()}
    />
  </DateTimePickerRoot>
);

const localeFormat = getLocaleFormat();

function getLocaleFormat(): string {
  const now = new Date(3333, 10, 22);
  return now
    .toLocaleDateString()
    .replace(/3/g, 'y')
    .replace(/1/g, 'M')
    .replace(/2/g, 'd');
}

const DateTimePickerRoot = styled.div`
  /* font-family: ${Defaults.fontFamily}; */
  font-size: ${(props) => px(props.fontSize || Defaults.fontSize)};

  & .react-datetime-picker__wrapper {
    height: ${(props) => px(props.height)};
    width: ${(props) => px(props.width)};
    border-radius: ${px(Defaults.borderRadius)};
    border: 2px solid ${(props) => props.borderColor};
  }

  & .sky-calendar {
    border: 2px solid ${(props) => props.borderColor};
  }

  & input {
    ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
    ${(props) => (props.color ? `color: ${props.color}` : '')}
  }

  ${(props) => (props.color ? `color: ${props.color}` : '')}
  ${(props) => props.customStyle ?? ''}
`;

export default DatePickerEx;
