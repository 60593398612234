import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { getRowsPerPage } from '../../helpers/settings';
import { SortDirection } from '../../helpers/utils';
import { CommonRequestParams } from '../../utils/types';
import { camelToUnderscore } from '../common';
import { ColumnHeader } from '../controls/TableEx';
import Txt from '../controls/Txt';
import {
  AdmRowsPerPage,
  AdmSearchInput,
  AdmTable,
  getSettingsCrypto,
  offChangeCrypto,
  onChangeCrypto,
  ReturnButton,
} from './admin-common';

interface Additional {
  component?:
    | ReactNode
    | ((
        request: (
          isSearching: boolean,
          searchedValue?: string,
          additionalRequestParams?: Record<string, string>,
        ) => void,
        resetRows: () => void,
        searchedValue?: string,
      ) => ReactNode);
  contentComponent?:
    | ReactNode
    | ((request: (isSearching: boolean) => void, resetRows: () => void) => ReactNode);
  functions?: Record<string, any>;
}
interface PageLayoutProps<T> {
  request: any;
  user?: string;
  headerTitle: string;
  isHeaderTitleVisible?: boolean;
  additional?: Additional;
  additionalParams?: Record<string, string | number>;
  defaultSortBy: keyof T;
  searchInputHint?: string;
  reverseSortingOrder?: boolean;
  tableColumns?: ColumnHeader<T>[] | ((updateFunc: () => void) => ColumnHeader<T>[]);
  isSubtitle?: boolean;
  isReturnButton?: boolean;
  subtitleArgs?: Array<string>;
  showMoreDisabled?: boolean;
}

export default function PageLayout<T>(props: PageLayoutProps<T>) {
  const {
    headerTitle,
    additional,
    defaultSortBy,
    searchInputHint,
    reverseSortingOrder = false,
    tableColumns,
    user,
    request,
    additionalParams,
    isSubtitle = false,
    isReturnButton = false,
    isHeaderTitleVisible = true,
    subtitleArgs = [],
    showMoreDisabled,
  } = props;
  const [rows, setRows] = useState<T[]>([]);
  const [sortBy, setSortBy] = useState<keyof T>(defaultSortBy);
  const [limit, setLimit] = useState<number>(getRowsPerPage());
  const [crypto, setCrypto] = useState<string>(getSettingsCrypto());
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [isShowMoreBtnDisabled, setIsShowMoreBtnDisabled] = useState(
    showMoreDisabled ?? false,
  );
  const [searchedValue, setSearchedValue] = useState<string | undefined>('');

  const update = (
    isSearching?: boolean,
    searchedValue?: string,
    additionalRequestParams?: Record<string, string>,
  ) => {
    const offset = isSearching ? 0 : rows.length;

    const descOrderSign = reverseSortingOrder ? '' : '-';
    const ascOrderSign = reverseSortingOrder ? '-' : '';

    const requestParams: CommonRequestParams = {
      symbol: crypto,
      offset,
      limit,
      user,
      q: searchedValue || undefined,
      ordering: `${
        sortDirection === 'desc' ? descOrderSign : ascOrderSign
      }${camelToUnderscore(sortBy)}`,
      ...additionalRequestParams,
      ...additionalParams,
    };

    const updateData = (newData) => {
      setRows(isSearching ? newData : [...rows, ...newData]);
      setIsShowMoreBtnDisabled(
        showMoreDisabled ?? (newData.length < limit || !newData.length),
      );
    };
    request(requestParams, additional?.functions)
      .then((newData) => {
        updateData(newData);
      })
      .catch(() => undefined);
  };

  const columns = useMemo(() => {
    if (tableColumns?.['push']) {
      return tableColumns as ColumnHeader<T>[];
    }
    return (tableColumns as (updateFunc: () => void) => void)(update);
  }, [tableColumns]);

  const renderTable = () => (
    <AdmTable
      rows={rows}
      isShowMoreBtnDisabled={isShowMoreBtnDisabled}
      onShowMore={update}
      handleRequestSort={handleRequestSort}
      sortDirection={sortDirection}
      columns={columns}
      setRows={setRows}
    />
  );

  const handleRequestSort = (_, prop: keyof T) => {
    const isDesc = sortBy === prop && sortDirection === 'desc';
    setSortDirection(isDesc ? 'asc' : 'desc');
    setSortBy(prop);
    resetRows();
  };

  const handleChangeLimit = (limit: number) => {
    setLimit(limit);
    resetRows();
  };

  const handleSearchInput = (searchedValue?: string): void => {
    setSearchedValue(searchedValue);
    update(true, searchedValue);
  };

  const handleCryptoChange = (crypto: string) => {
    setCrypto(crypto);
    resetRows();
  };

  const resetRows = () => {
    setRows([]);
  };

  useEffect(() => {
    update();
  }, [sortDirection, sortBy, crypto, limit]);

  useEffect(() => {
    onChangeCrypto(handleCryptoChange);
    return () => offChangeCrypto(handleCryptoChange);
  }, []);

  return (
    <div className={'adm-page'}>
      <div className={'adm-page-header'}>
        {isReturnButton && <ReturnButton />}
        {!isSubtitle && isHeaderTitleVisible && (
          <div className={'adm-page-title'}>
            <Txt k={headerTitle} />
          </div>
        )}

        {typeof additional?.component === 'function'
          ? additional.component(update, resetRows, searchedValue)
          : !!additional?.component && additional.component}
        <div className={'right'}>
          {searchInputHint && (
            <AdmSearchInput
              value={searchedValue}
              onChange={handleSearchInput}
              hint={searchInputHint}
            />
          )}
          <AdmRowsPerPage onChange={handleChangeLimit} />
        </div>
      </div>
      {isSubtitle && (
        <div className={'adm-page-sub-header'}>
          <div className={'adm-page-title'}>
            <Txt k={headerTitle} args={subtitleArgs} />
          </div>
        </div>
      )}
      <div className={'adm-page-content'}>
        {typeof additional?.contentComponent === 'function'
          ? additional.contentComponent(update, resetRows)
          : !!additional?.contentComponent && additional.contentComponent}
        {renderTable()}
      </div>
    </div>
  );
}
