import { Md5 } from 'ts-md5';

export function isJson(response): boolean {
  let contentType = response?.headers?.['content-type'];
  contentType = contentType?.toLowerCase();
  return (
    contentType &&
    (contentType.indexOf('application/json') !== -1 ||
      contentType.indexOf('application/problem+json') !== -1)
  );
}

export function isCsv(response): boolean {
  const contentType = response?.headers?.['content-type'];
  return contentType && contentType?.indexOf('text/csv') !== -1;
}

export function generateSecretKey(): string {
  const SECRET_KEY = window.localStorage.getItem('SECRET_KEY');
  const CODE_DATA = window.localStorage.getItem('CODE_DATA');

  if (SECRET_KEY && JSON.parse(SECRET_KEY)) {
    return JSON.parse(SECRET_KEY);
  }
  if (!CODE_DATA || !JSON.parse(CODE_DATA)) {
    return '';
  }

  const keys = Object.keys(JSON.parse(CODE_DATA)).sort();
  const md5 = new Md5();
  for (const key of keys) {
    md5.appendStr(JSON.parse(CODE_DATA)[key]);
  }
  window.localStorage.setItem('SECRET_KEY', JSON.stringify(md5.end()));
  return md5.end() as string;
}
