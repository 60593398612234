import React from 'react';
import { RouteComponentProps } from 'react-router';

import { formatCrypto, formatDateTime, formatNumber } from '../../helpers/utils';
import { api } from '../../utils/api';
import { DealState } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmBidLink, AdmUserLink, ReturnButton } from './admin-common';
import { assignDeal } from './Deals';
import { WarnIcon } from './icons';

export interface Deal {
  id: string;
  createdAt?: Date;
  lot: string;
  crypto: string;
  amount: number;
  amountCurrency: number;
  requisite?: string;
  state: DealState;
  endTime?: Date;
  buyer: string;
  seller: string;
  commission: number;
  commissionBuyer?: number;
  commissionSeller?: number;
  hasDispute?: boolean;
  currency: string;
}

interface State {
  deal: Deal;
}

export default class DealPage extends React.Component<
  RouteComponentProps<{ id: string }>
> {
  public state: State = {
    deal: {
      id: '',
      createdAt: new Date(),
      lot: '',
      crypto: '',
      amount: 0,
      amountCurrency: 0,
      state: 'new',
      buyer: '',
      seller: '',
      commission: 0,
      currency: '',
    },
  };

  componentDidMount(): void {
    this.update();
  }

  update = () => {
    const { id } = this.props.match.params;
    api.admin
      .deal('btc', id)
      .then((result) => {
        if (result) {
          const deal: Deal = assignDeal(result);
          this.setState({ deal });
        }
      })
      .catch(() => undefined);
  };

  resolveDisputeBuyer = () => {
    // TODO
  };

  resolveDisputeSeller = () => {
    // TODO
  };

  render(): React.ReactNode {
    const { deal } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'adm-page-title'}>
            <Txt k={'admin.deal.title'} args={[deal.id]} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <table className={'adm-table'}>
            <tbody>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.id'} />
                </td>
                <td className={'color-main'}>{deal.id}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.buyer'} />
                </td>
                <td>
                  <AdmUserLink nickname={deal.buyer} />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.seller'} />
                </td>
                <td>
                  <AdmUserLink nickname={deal.seller} />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.bid'} />
                </td>
                <td>
                  <AdmBidLink lot={deal.lot} />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.amount-currency'} />
                </td>
                <td>{formatNumber(deal.amountCurrency) + ' ' + deal.currency.toUpperCase()}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.amount'} />
                </td>
                <td>
                  {formatCrypto(deal.amount, deal.crypto)}&nbsp;{deal.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.requisites'} />
                </td>
                <td>{deal.requisite}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.commission'} />
                </td>
                <td>
                  {formatCrypto(deal.commission, deal.crypto)}&nbsp;{deal.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.commission-buyer'} />
                </td>
                <td>
                  {formatCrypto(deal.commissionBuyer, deal.crypto)}&nbsp;{deal.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.commission-seller'} />
                </td>
                <td>
                  {formatCrypto(deal.commissionSeller, deal.crypto)}&nbsp;{deal.crypto}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.created-at'} />
                </td>
                <td>{formatDateTime(deal.createdAt)}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.completed-at'} />
                </td>
                <td>{formatDateTime(deal.endTime)}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.deals.table.state'} />
                </td>
                <td>
                  {deal.hasDispute ? (
                    <div className={'adm-row adm-deal-stage-warn'}>
                      <WarnIcon />
                      <Txt k={'admin.deal.stage.pending-dispute'} />
                    </div>
                  ) : (
                    <Txt k={`admin.deals.table.state-${deal.state}`} />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          {deal.hasDispute ? (
            <div className={'adm-page-deal-buttons'}>
              <button className={'adm-page-button'} onClick={this.resolveDisputeBuyer}>
                <span>
                  <Txt k={'admin.deal.resolve-dispute-buyer'} />
                </span>
              </button>
              <button className={'adm-page-button'} onClick={this.resolveDisputeSeller}>
                <span>
                  <Txt k={'admin.deal.resolve-dispute-seller'} />
                </span>
              </button>
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}
