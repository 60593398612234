import React from 'react';
import { Translation } from 'react-i18next';
import styled from 'styled-components';

import { formatString } from '../../helpers/utils';
import { ComponentRootEx, Defaults, px, TextEx } from '../common';
import IconEx from './IconEx';

export interface ButtonExProps {
  className?: string;
  minWidth?: number | string;
  width?: number | string;
  height?: number | string;
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
  flexGrow?: number;
  img?: string | React.ReactNode;
  imgSize?: number | string;
  hint?: string;
  caption?: string;
  captionArgs?: any[];
  fontSize?: number | string;
  hidden?: boolean;
  onClick: (event) => void;
  children?: React.ReactNode;
  backgroundColor?: string;
  captionColor?: string;
  borderColor?: string;
  hoverColor?: string;
  busy?: boolean;
  disabled?: boolean;
  customStyle?: string;
}

const ButtonEx = ({
  className,
  minWidth,
  width,
  height,
  top,
  left,
  right,
  bottom,
  flexGrow,
  img,
  imgSize,
  hint,
  caption,
  captionArgs,
  fontSize = Defaults.fontSize,
  hidden,
  onClick,
  children,
  backgroundColor,
  captionColor,
  borderColor,
  hoverColor,
  busy,
  disabled,
  customStyle,
}: ButtonExProps) => {
  if (hidden) {
    return <></>;
  }

  return (
    <Translation>
      {(t) => (
        <ButtonExContainer
          className={`${className ? className : ''} ${busy ? 'disabled' : ''}`}
          minWidth={minWidth}
          width={width}
          height={height}
          flexGrow={flexGrow}
          title={hint ? t(hint) : ''}
          top={top}
          left={left}
          right={right}
          bottom={bottom}
          onClick={(e) => (!busy && !disabled ? onClick(e) : undefined)}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
          hoverColor={hoverColor}
          cursor={disabled ? 'not-allowed' : busy ? 'wait' : ''}
          customStyle={customStyle}>
          {!!img && typeof img === 'object' ? img : undefined}
          {!!img && typeof img === 'string' ? (
            <IconEx src={img} size={imgSize || Defaults.imgSize} />
          ) : undefined}
          {caption ? (
            <TextEx
              color={captionColor || borderColor}
              size={fontSize}
              uppercase
              left={img ? '1vh' : undefined}>
              {captionArgs ? formatString(t(caption), ...captionArgs) : t(caption)}
            </TextEx>
          ) : undefined}
          {children}
        </ButtonExContainer>
      )}
    </Translation>
  );
};

const ButtonExContainer = styled(ComponentRootEx)`
  justify-content: center;
  padding: 0 ${px(Defaults.padding)};
  flex-shrink: 0;
  ${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : '')}
  ${(props) => props.customStyle}
`;

export default ButtonEx;
