import './components/home/css/main.min.css';

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';

import PageAdmin from './components/admin/PageAdmin';
import { Defaults, HtmlText, TextEx } from './components/common';
import { MessageBox } from './components/controls/Dialog';
import Txt from './components/controls/Txt';
import PageLogin, { toggleBodyFixed } from './components/login/PageLogin';
import { AuthContext, TokenListener } from './config/auth';
import history, { setPage } from './config/history';
import { tt } from './config/i18n';
import { getAuthToken, logout, setAuthToken } from './helpers/authHeader';
import { listenMessages, Message } from './helpers/notifications';
import { APP_VERSION } from './helpers/settings';

const theme = createTheme({
  typography: {
    fontFamily: Defaults.fontFamily,
  },
});

interface State {
  token: any;
  showLoginDialog: boolean;
  email?: string;
  error?: string;
  info?: string;
}

export default class App extends React.Component {
  public state: State = {
    token: getAuthToken(),
    showLoginDialog: true,
  };

  private tokenListeners: TokenListener[] = [];

  componentDidMount(): void {
    listenMessages(this.listenMessages);
  }

  listenMessages = (message: Message) => {
    switch (message.type) {
      case 'error':
        this.setState({ error: message.text });
        break;
      case 'login':
        this.openLoginDialog(message.text);
        break;
      default:
        this.setState({ info: message.text });
    }
  };

  setAuthorization = (token?: string) => {
    if (token) {
      setAuthToken(token);
      this.setState({ token });
    } else {
      logout();
    }
    this.setState({ token });
  };

  openLoginDialog = (email?: string) => {
    const showLogin = () => {
      this.setState({ email, showLoginDialog: true, token: null });
      // toggleBodyFixed(true);
    };

    showLogin();
  };

  openLoginDialog2 = (email?: string, onToken?: TokenListener) => {
    if (!!onToken && this.tokenListeners.indexOf(onToken) === -1) {
      this.tokenListeners.push(onToken);
    }
    this.openLoginDialog(email);
  };

  closeLoginDialog = () => {
    this.setState({ showLoginDialog: false });
    toggleBodyFixed(false);
  };

  onLoginSuccess = (action) => {
    setPage(`admin`);
    this.closeLoginDialog();
  };

  onLoginToken = (token: string): boolean => {
    let result = true;
    for (const tokenListener of this.tokenListeners) {
      if (!tokenListener(token)) {
        result = false;
      }
    }
    this.tokenListeners = [];
    if (!result) {
      this.closeLoginDialog();
    }
    return result;
  };

  render(): React.ReactNode {
    const { token, email, error, info, showLoginDialog } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <AuthContext.Provider
          value={{
            token,
            isAuthorized: !!token,
            setAuthorization: this.setAuthorization,
            openLoginDialog: this.openLoginDialog,
            openLoginDialog2: this.openLoginDialog2,
          }}>
          <Router history={history}>
            <Switch>
              <Route exact path={'/admin(/.*)?'}>
                <PageAdmin openLogin={this.openLoginDialog} />
              </Route>
              <Redirect to={token ? '/admin' : '/'} />
            </Switch>
          </Router>
          <Version />
          <PageLogin
            open={!token && showLoginDialog}
            email={email}
            onClose={() => this.closeLoginDialog}
            onLogin={this.onLoginToken}
            onSuccess={this.onLoginSuccess}
          />
          <ErrorBox
            open={!!error}
            error={error}
            onClose={() => this.setState({ error: undefined })}
          />
          <InfoBox
            open={!!info}
            info={info}
            onClose={() => this.setState({ info: undefined })}
          />
        </AuthContext.Provider>
      </MuiThemeProvider>
    );
  }
}

const Version = () => (
  <div
    style={{
      position: 'fixed',
      right: '1vh',
      top: '97vh',
      fontFamily: Defaults.fontFamily,
      fontSize: '1vh',
      zIndex: 999,
    }}>
    {APP_VERSION}
  </div>
);

const ErrorBox = ({
  open,
  error,
  onClose,
}: {
  open: boolean;
  error?: string;
  onClose: () => void;
}) => {
  if (!error) {
    return <></>;
  }

  let unknownError = true;
  const translated = tt('errors', error, 'unknown');
  if (translated !== 'unknown') {
    error = translated;
    unknownError = false;
  }

  if (unknownError) {
    // const errorByMask = checkErrorByMask(error);
    const errorByMask = '';
    if (errorByMask) {
      error = errorByMask;
      unknownError = false;
    }
  }

  return (
    <MessageBox open={open} caption={'common.error'} onClose={onClose}>
      <TextEx size={'1.125rem'}>{error}</TextEx>
      {unknownError ? (
        <>
          <br />
          <TextEx>
            <Txt k={'common.error-advise'} />
          </TextEx>
        </>
      ) : undefined}
    </MessageBox>
  );
};

const InfoBox = ({
  open,
  info,
  onClose,
}: {
  open: boolean;
  info?: string;
  onClose: () => void;
}) => {
  return info ? (
    <MessageBox open={open} onClose={onClose}>
      <HtmlText k={info} size={'1.125rem'} />
    </MessageBox>
  ) : (
    <></>
  );
};
