import React, { ChangeEvent, useEffect } from 'react';

import { formatNumber, nvl } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmMerchant, CommonRequestParams } from '../../utils/types';
import { camelToUnderscore, Row } from '../common';
import Txt from '../controls/Txt';
import { AdmLink, AdmTablePopup, AdmUserLink } from './admin-common';
import PageLayout from './PageLayout';

interface Merchant {
  merchantId: number;
  nickname: string;
  name: string;
  payments: number;
  sales: number;
  isAutoreportActive: boolean;
  autoreportEmail?: string;
  isActive: boolean;
  isActiveSale: boolean;
  isActiveWithdrawal: boolean;
  isActiveCpay: boolean;
  allowWaitingBackUrl: boolean;
  isActiveSkyPayV2: boolean;
  commission?: number;
  commissionSale?: number;
  requiredMask?: boolean;
  isReceiptRequired: boolean;
}

const getColumns = (
  updateRowValue: (
    id: string,
    key: string,
    value: boolean | number | string,
    i: number,
    rows: Merchant[],
    setRows: (data: Merchant[]) => void,
  ) => void,
) => [
  {
    id: 'nickname',
    caption: 'admin.api.table.user',
    render: (user) => <AdmUserLink nickname={user} />,
  },
  {
    id: 'name',
    caption: 'admin.api.table.merchant',
    render: (merchant, row) => (
      <AdmLink href={`admin/merchants/${row.merchantId}/${merchant}`}>{merchant}</AdmLink>
    ),
  },
  {
    id: 'payments',
    noSorting: true,
    caption: 'admin.api.table.payments',
  },
  {
    id: 'sales',
    noSorting: true,
    caption: 'admin.api.table.sales',
  },
  {
    id: 'requiredMask',
    caption: 'admin.api.table.required-mask',
    render: (requiredMask, row, i, rows, setRows) => (
      <>
        <span className={`color-${requiredMask ? 'true' : 'false'}`}>&bull;&nbsp;</span>
        <Txt k={`admin.api.table.status-${requiredMask ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={requiredMask}
          onClick={() =>
            updateRowValue(row.merchantId, 'requiredMask', requiredMask, i, rows, setRows)
          }
        />
      </>
    ),
  },
  {
    id: 'isActive',
    caption: 'admin.api.table.status',
    render: (isActive, row, i, rows, setRows) => (
      <>
        <span className={`color-${isActive ? 'true' : 'false'}`}>&bull;&nbsp;</span>
        <Txt k={`admin.api.table.status-${isActive ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isActive}
          onClick={() =>
            updateRowValue(row.merchantId, 'isActive', isActive, i, rows, setRows)
          }
        />
      </>
    ),
  },
  {
    id: 'isActiveSale',
    caption: 'admin.api.table.sale-status',
    render: (isSalesActive, row, i, rows, setRows) => (
      <>
        <span className={`color-${isSalesActive ? 'true' : 'false'}`}>&bull;&nbsp;</span>
        <Txt k={`admin.api.table.status-${isSalesActive ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isSalesActive}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'isActiveSale',
              isSalesActive,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'isActiveWithdrawal',
    caption: 'admin.api.table.withdrawal-status',
    render: (isActiveWithdrawal, row, i, rows, setRows) => (
      <>
        <span className={`color-${isActiveWithdrawal ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${isActiveWithdrawal ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isActiveWithdrawal}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'isActiveWithdrawal',
              isActiveWithdrawal,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'isActiveCpay',
    caption: 'admin.api.table.cpayment-status',
    render: (isActiveCPayment, row, i, rows, setRows) => (
      <>
        <span className={`color-${isActiveCPayment ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${isActiveCPayment ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isActiveCPayment}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'isActiveCpay',
              isActiveCPayment,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'isActiveSkyPayV2',
    caption: 'admin.api.table.sky-pay-v2-status',
    render: (isActiveSkyPayV2, row, i, rows, setRows) => (
      <>
        <span className={`color-${isActiveSkyPayV2 ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${isActiveSkyPayV2 ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isActiveSkyPayV2}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'isActiveSkyPayV2',
              isActiveSkyPayV2,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'allowWaitingBackUrl',
    caption: 'admin.api.table.allow-waiting-back-url',
    render: (allowWaitingBackUrl, row, i, rows, setRows) => (
      <>
        <span className={`color-${allowWaitingBackUrl ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${allowWaitingBackUrl ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={allowWaitingBackUrl}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'allowWaitingBackUrl',
              allowWaitingBackUrl,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'isReceiptRequired',
    caption: 'admin.api.table.receipt-required',
    render: (isReceiptRequired, row, i, rows, setRows) => (
      <>
        <span className={`color-${isReceiptRequired ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${isReceiptRequired ? 'online' : 'offline'}`} />
        <MerchantEnableBox
          isActive={isReceiptRequired}
          onClick={() =>
            updateRowValue(
              row.merchantId,
              'isReceiptRequired',
              isReceiptRequired,
              i,
              rows,
              setRows,
            )
          }
        />
      </>
    ),
  },
  {
    id: 'commission',
    caption: 'admin.api.table.commission',
    render: (commission, row, i, rows, setRows) => (
      <>
        {commission ? formatNumber(commission).concat('%') : '0%'}
        <EditCommissionBox
          value={commission}
          handleCommisionChange={(value) =>
            updateRowValue(row.merchantId, 'commission', value, i, rows, setRows)
          }
          onDelete={() =>
            updateRowValue(row.merchantId, 'commission', 0, i, rows, setRows)
          }
        />
      </>
    ),
  },
  {
    id: 'commissionSale',
    caption: 'admin.api.table.commission-sale',
    render: (commissionSales, row, i, rows, setRows) => (
      <>
        {commissionSales ? formatNumber(commissionSales).concat('%') : '0%'}
        <EditCommissionBox
          value={commissionSales}
          handleCommisionChange={(value) =>
            updateRowValue(row.merchantId, 'commissionSale', value, i, rows, setRows)
          }
          onDelete={() =>
            updateRowValue(row.merchantId, 'commissionSale', 0, i, rows, setRows)
          }
        />
      </>
    ),
  },
  {
    id: 'isAutoreportActive',
    caption: 'admin.api.table.autoreport-status',
    render: (isAutoreportActive, row, i, rows, setRows) => (
      <>
        <span className={`color-${isAutoreportActive ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.api.table.status-${isAutoreportActive ? 'online' : 'offline'}`} />
        <AdmTablePopup className={'adm-transaction-actions'}>
          <div
            className={'adm-button delete-transaction'}
            onClick={() =>
              updateRowValue(
                row.merchantId,
                'isAutoreportActive',
                isAutoreportActive,
                i,
                rows,
                setRows,
              )
            }>
            <span>
              <Txt
                k={`admin.api.table.merchant-${
                  isAutoreportActive ? 'disable' : 'enable'
                }`}
              />
            </span>
          </div>
          <EditTextBox
            value={row.autoreportEmail}
            handleTextChange={(value) =>
              updateRowValue(row.merchantId, 'autoreportEmail', value, i, rows, setRows)
            }
          />
        </AdmTablePopup>
      </>
    ),
  },
];

const updateRowValue = (
  id: string,
  key: string,
  value: boolean | number | string,
  i: number,
  rows: Merchant[],
  setRows: (data: Merchant[]) => void,
) => {
  const newValue = typeof value === 'boolean' ? !value : value;

  api.admin
    .updateMerchant(id, camelToUnderscore(key), newValue)
    .then(() => {
      const newData = [...rows];
      newData[i][key] = newValue;
      setRows(newData);
    })
    .catch(() => undefined);
};

export default class API extends React.Component {
  render(): React.ReactNode {
    return (
      <PageLayout<Merchant>
        request={getMerchants}
        headerTitle={'admin.api.title'}
        searchInputHint={'admin.api.search-user'}
        defaultSortBy={'nickname'}
        tableColumns={getColumns(updateRowValue)}
      />
    );
  }
}

const MerchantEnableBox = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button delete-transaction'} onClick={onClick}>
      <span>
        <Txt k={`admin.api.table.merchant-${isActive ? 'disable' : 'enable'}`} />
      </span>
    </div>
  </AdmTablePopup>
);

const EditCommissionBox = ({
  value,
  handleCommisionChange,
  onDelete,
}: {
  value: number;
  handleCommisionChange: (value: number) => void;
  onDelete: () => void;
}) => {
  const [val, setVal] = React.useState<string | number>(value);

  function change(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <AdmTablePopup
      className={'adm-transaction-actions adm-input-control adm-merchant-popup'}>
      <Row>
        <input
          type={'number'}
          value={val}
          onChange={change}
          onWheel={() => document.activeElement?.['blur']()}
        />
        <span className={'percent'}>%</span>
      </Row>
      <div
        className={'adm-button restart-transaction'}
        onClick={() => val !== '' && handleCommisionChange(Number(val))}>
        <span>
          <Txt k={'admin.api.table.edit-commission'} />
        </span>
      </div>
      <div className={'adm-button restart-transaction'} onClick={onDelete}>
        <span>
          <Txt k={'admin.api.table.delete-commission'} />
        </span>
      </div>
    </AdmTablePopup>
  );
};

const EditTextBox = ({
  value,
  handleTextChange,
}: {
  value: string;
  handleTextChange: (value: string) => void;
}) => {
  const [val, setVal] = React.useState<string>(value);

  function change(event: ChangeEvent<HTMLInputElement>) {
    setVal(event.target.value);
  }

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div
      className={
        'adm-transaction-actions adm-input-control adm-merchant-popup text-edit'
      }>
      <Row>
        <input
          type={'string'}
          value={val}
          onChange={change}
          onWheel={() => document.activeElement?.['blur']()}
        />
      </Row>
      <div
        className={'adm-button delete-transaction'}
        onClick={() => val !== '' && handleTextChange(val)}>
        <span>
          <Txt k={'admin.api.table.edit-commission'} />
        </span>
      </div>
    </div>
  );
};

function getMerchants(params: CommonRequestParams): Promise<Merchant[]> {
  return new Promise((resolve) => {
    api.admin
      .merchants({ ...params, symbol: params.symbol?.toLowerCase() })
      .then((response) => {
        const merchants: Merchant[] = [];
        for (const merchant of response) {
          merchants.push(assignMerchant(merchant));
        }
        resolve(merchants);
      })
      .catch(() => resolve([]));
  });
}

function assignMerchant(merchant: AdmMerchant): Merchant {
  return {
    merchantId: merchant.merchant_id,
    nickname: merchant.nickname,
    name: merchant.name,
    payments: merchant.payments || 0,
    sales: merchant.sales || 0,
    isActive: merchant.is_active,
    isActiveSale: merchant.is_active_sale,
    allowWaitingBackUrl: merchant.allow_waiting_back_url,
    isActiveWithdrawal: !!merchant.is_active_withdrawal,
    isAutoreportActive: !!merchant.is_autoreport_active,
    autoreportEmail: merchant.autoreport_email,
    isActiveCpay: !!merchant.is_active_cpay,
    isActiveSkyPayV2: !!merchant.is_active_sky_pay_v2,
    commission: nvl(merchant.commission),
    commissionSale: nvl(merchant.commission_sale),
    requiredMask: !!merchant.required_mask,
    isReceiptRequired: merchant.is_receipt_required,
  };
}
