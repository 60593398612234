import React from 'react';
import { RouteComponentProps } from 'react-router';

import { Deal } from './Deal';
import { columns, getDeals } from './Deals';
import PageLayout from './PageLayout';

export default class UserDeals extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  render(): React.ReactNode {
    const { nickname } = this.props.match.params;

    return (
      <PageLayout<Deal>
        request={getDeals}
        headerTitle={'admin.user-deals.title'}
        user={nickname}
        searchInputHint={'admin.deals.search-deal'}
        defaultSortBy={'createdAt'}
        tableColumns={columns}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[nickname]}
      />
    );
  }
}
