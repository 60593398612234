import React from 'react';
import { RouteComponentProps } from 'react-router';

import { formatDateTime, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import PageLayout from './PageLayout';

export interface UserLogin {
  createdAt?: Date;
  device: string;
  ip: string;
  isActive: boolean;
}

export const columns = [
  {
    id: 'createdAt',
    caption: 'admin.login-user-data.table.created-at',
    render: (createdAt) => formatDateTime(createdAt),
    minWidth: '10rem',
  },
  {
    id: 'device',
    caption: 'admin.login-user-data.table.device',
    noSorting: true,
  },
  {
    id: 'ip',
    caption: 'admin.login-user-data.table.ip',
    noSorting: true,
  },
  {
    id: 'isActive',
    caption: 'admin.login-user-data.table.is-active',
    noSorting: true,
    render: (isActive) => (
      <>
        <span className={`color-${isActive}`}>&bull;&nbsp;</span>
        <Txt
          k={`admin.login-user-data.table.state-${isActive ? 'active' : 'inactive'}`}
        />
      </>
    ),
  },
];

export default class UserLoginData extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  render(): React.ReactNode {
    const { nickname } = this.props.match.params;

    return (
      <PageLayout<UserLogin>
        request={getUserData}
        user={nickname}
        headerTitle={'admin.login-user-data.title'}
        defaultSortBy={'createdAt'}
        tableColumns={columns}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[nickname]}
      />
    );
  }
}

export function getUserData(
  params: CommonRequestParams & { user?: string },
): Promise<UserLogin[]> {
  return new Promise((resolve) => {
    api.admin
      .loginUserData({ ...params })
      .then((result) => {
        const loginData: UserLogin[] = [];
        for (const p of result) {
          loginData.push({
            createdAt: parseDate(p.created_at),
            device: p.device,
            ip: p.ip,
            isActive: p.is_active,
          });
        }
        resolve(loginData);
      })
      .catch(() => undefined);
  });
}
