import React from 'react';

import { formatDateTime, formatNumber, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmLot, CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import { AdmBidLink, AdmTable, AdmUserLink } from './admin-common';
import { Bid } from './Bid';
import PageLayout from './PageLayout';

export const columns = [
  {
    id: 'createdAt',
    caption: 'admin.bids.table.created-at',
    render: (createdAt) => formatDateTime(createdAt),
    minWidth: '10rem',
  },
  {
    id: 'type',
    caption: 'admin.bids.table.type',
    render: (type, row: Bid) => (
      <Txt k={`admin.bids.table.type-${type}`} args={[row.symbol]} />
    ),
  },
  {
    id: 'identificator',
    caption: 'admin.bids.table.id',
    render: (id) => <AdmBidLink lot={id} />,
  },
  {
    id: 'broker',
    caption: 'admin.bids.table.payment-method',
  },
  {
    id: 'user',
    caption: 'admin.bids.table.user',
    render: (user) => <AdmUserLink nickname={user} />,
  },
  {
    id: 'rate',
    caption: 'admin.bids.table.rate',
    render: (rate, row: Bid) => formatNumber(rate) + ' ' + row.currency.toUpperCase(),
  },
  {
    id: 'percent',
    noSorting: true,
    caption: 'admin.bids.table.percent',
    render: (percent) => formatNumber(percent),
  },
  {
    id: 'isActive',
    caption: 'admin.bids.table.state',
    render: (isActive) => (
      <>
        <span className={`color-${isActive === 'enabled' ? 'true' : 'false'}`}>
          &bull;&nbsp;
        </span>
        <Txt k={`admin.bids.table.state-${isActive}`} />
      </>
    ),
  },
  {
    id: 'deleted',
    noSorting: true,
    caption: 'admin.bids.table.deleted',
    render: (isDeleted) => <Txt k={`common.${isDeleted ? 'yes' : 'no'}`} />,
  },
];
export default class Bids extends React.Component {
  render(): React.ReactNode {
    return (
      <PageLayout<Bid>
        request={getBids}
        headerTitle={'admin.bids.title'}
        searchInputHint={'admin.bids.search-bid'}
        defaultSortBy={'createdAt'}
        tableColumns={columns}
      />
    );
  }
}

export const AdmBidsTable = ({
  rows,
  onShowMore,
}: {
  rows: Bid[];
  onShowMore: () => boolean;
}) => (
  <AdmTable
    columns={columns}
    rows={rows}
    defaultSortBy={'createdAt'}
    onShowMore={onShowMore}
  />
);

export function getBids(params: CommonRequestParams & { user?: string }): Promise<Bid[]> {
  return new Promise((resolve) => {
    api.admin
      .bids({ ...params, symbol: params.symbol?.toLowerCase() })
      .then((result) => {
        const bids: Bid[] = [];
        for (const lot of result) {
          bids.push(assignBid(lot));
        }
        resolve(bids);
      })
      .catch(() => undefined);
  });
}

export function assignBid(lot: AdmLot): Bid {
  return {
    identificator: lot.identificator,
    type: lot.type,
    symbol: lot.symbol.toUpperCase(),
    broker: lot.broker,
    user: lot.user,
    rate: lot.rate,
    percent: lot.coefficient ? lot.coefficient * 100 - 100 : undefined,
    limitFrom: lot.limit_from,
    limitTo: lot.limit_to,
    details: lot.details,
    isActive: lot.is_active ? 'enabled' : 'disabled',
    isDeleted: lot.is_deleted,
    createdAt: parseDate(lot.created_at),
    currency: lot.currency,
  };
}
