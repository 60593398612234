import React from 'react';
import { RouteComponentProps } from 'react-router';

import { api } from '../../utils/api';
import { CommonRequestParams } from '../../utils/types';
import { Row } from '../common';
import { AdmTablePopup, AdmText } from './admin-common';
import PageLayout from './PageLayout';

export interface IMerchantStatistics {
  monthYear?: string;
  turnover: number;
  successful: number;
  unsuccessful: number;
}

interface State {
  currencies: Array<string>;
  currentCurrency?: string;
}

export const columns = [
  {
    id: 'monthYear',
    caption: 'admin.merchant-statistics.table.month-year',
    minWidth: '5rem',
    noSorting: true,
  },
  {
    id: 'turnover',
    caption: 'admin.merchant-statistics.table.turnover',
    noSorting: true,
  },
  {
    id: 'successful',
    caption: 'admin.merchant-statistics.table.successful',
    noSorting: true,
  },
  {
    id: 'unsuccessful',
    caption: 'admin.merchant-statistics.table.unsuccessful',
    noSorting: true,
  },
];

export default class MerchantStatistics extends React.Component<
  RouteComponentProps<{ id: string; name: string }>
> {
  public state: State = {
    currencies: [],
    currentCurrency: undefined,
  };

  handleFilterChange = (currency: string, request, resetRows): void => {
    this.setState({ currentCurrency: currency }, () => {
      resetRows();
      request(true, undefined, { currency: currency.toLowerCase() });
    });
  };

  getMerchantStatistics =
    (id: string) =>
    (params: CommonRequestParams): Promise<IMerchantStatistics[]> => {
      return new Promise((resolve) => {
        let currencies;
        const currentCurrency = this.state?.currentCurrency;
        if (!currentCurrency) {
          currencies = api.admin.currencies().then((data) => {
            currencies = data
              .filter(({ is_active }) => is_active)
              .map(({ id }) => id.toUpperCase());
            this.setState(
              currentCurrency
                ? { currentCurrency: currencies[0].toUpperCase() }
                : { currencies, currentCurrency: currencies[0].toUpperCase() },
            );
            api.admin
              .merchantStatisticsData({
                ...params,
                merchant_id: id,
                currency: currencies[0].toLowerCase(),
              })
              .then((result) => {
                const statistics: IMerchantStatistics[] = [];
                for (const p of result) {
                  statistics.push({
                    monthYear: p.month_year,
                    successful: p.successful,
                    turnover: p.turnover,
                    unsuccessful: p.unsuccessful,
                  });
                }
                resolve(statistics);
              });
          });
        }
        if (currentCurrency) {
          api.admin
            .merchantStatisticsData({
              ...params,
              merchant_id: id,
              currency: currentCurrency?.toLowerCase(),
            })
            .then((result) => {
              const statistics: IMerchantStatistics[] = [];
              for (const p of result) {
                statistics.push({
                  monthYear: p.month_year,
                  successful: p.successful,
                  turnover: p.turnover,
                  unsuccessful: p.unsuccessful,
                });
              }
              resolve(statistics);
            });
        }
      });
    };

  render(): React.ReactNode {
    const { id, name } = this.props.match.params;
    const { currencies, currentCurrency } = this.state;

    return (
      <PageLayout<IMerchantStatistics>
        request={this.getMerchantStatistics(id)}
        headerTitle={'admin.merchant-statistics.title'}
        defaultSortBy={'monthYear'}
        tableColumns={columns}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[name]}
        additional={{
          component: (request, resetRows) => (
            <Row>
              <AdmText k={`${currentCurrency || ''}`} left={'1rem'} />
              <AdmTablePopup className={'adm-transaction-actions'}>
                {currencies.map((n) => (
                  <div
                    className={'adm-button delete-transaction select-transaction'}
                    onClick={() => this.handleFilterChange(n, request, resetRows)}
                    key={n}>
                    <span style={{ color: n === currentCurrency ? 'white' : 'inherit' }}>
                      {n}
                    </span>
                  </div>
                ))}
              </AdmTablePopup>
            </Row>
          ),
        }}
      />
    );
  }
}
