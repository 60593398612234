import React from 'react';
import { RouteComponentProps } from 'react-router';

import Txt from '../controls/Txt';
import { ReturnButton } from './admin-common';

interface State {
  messages: string[]; // TODO
}

export default class UserChat extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  public state: State = {
    messages: [],
  };

  componentDidMount(): void {
    this.update();
  }

  update = () => {
    // TODO const {nickname} = this.props.match.params;
  };

  render(): React.ReactNode {
    const { nickname } = this.props.match.params;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'adm-page-title'}>
            <Txt k={'admin.user-chat.title'} args={[nickname]} />
          </div>
        </div>
        <div className={'adm-page-content'}></div>
      </div>
    );
  }
}
