import React from 'react';
import { RouteComponentProps } from 'react-router';

import { formatCrypto } from '../../helpers/utils';
import Txt from '../controls/Txt';
import {
  AdmRowsPerPage,
  AdmSearchInput,
  AdmTable,
  getSettingsCrypto,
  offChangeCrypto,
  onChangeCrypto,
  ReturnButton,
} from './admin-common';

interface Transit {
  address: string;
  privateKey: string;
  crypto: string;
  balance: number;
}

interface State {
  crypto: string;
  rows: Transit[];
  searchTransit?: string;
  rowsPerPage: number;
}

export default class UserTransit extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  public state: State = {
    crypto: getSettingsCrypto(),
    rows: [],
    rowsPerPage: 10,
  };

  componentDidMount(): void {
    onChangeCrypto(this.onChangeCrypto);
    this.update();
  }

  componentWillUnmount(): void {
    offChangeCrypto(this.onChangeCrypto);
  }

  onChangeCrypto = (crypto: string) => this.setState({ crypto }, this.update);

  update = () => {
    // TODO const {nickname} = this.props.match.params;
    const { crypto } = this.state;

    const rows: Transit[] = [
      {
        address: '1GXPWTNnVC8bofgh5j5DFSfdhgjghkjhkjhghgfhfHEiaimG',
        privateKey: '1GXPWTNnVC8bofgh5j5DFSfdhgjghkjhkjhghgfhfHEiaimG',
        crypto,
        balance: 0.012313167,
      },
    ];
    this.setState({ rows });
  };

  onChangeRowsPerPage = (rowsPerPage: number) =>
    this.setState({ rowsPerPage, rows: [] }, this.update);

  onSearchTransit = (searchTransit?: string) =>
    this.setState({ searchTransit }, this.update);

  onShowMore = (): boolean => {
    return true;
  };

  render(): React.ReactNode {
    const { nickname } = this.props.match.params;
    const { rows, searchTransit } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'right'}>
            <AdmSearchInput
              value={searchTransit}
              onChange={this.onSearchTransit}
              hint={'admin.user-transit.search-transit'}
            />
            <AdmRowsPerPage onChange={this.onChangeRowsPerPage} />
          </div>
        </div>
        <div className={'adm-page-sub-header'}>
          <div className={'adm-page-title'}>
            <Txt k={'admin.user-transit.title'} args={[nickname]} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <AdmTable
            columns={[
              {
                id: 'address',
                caption: 'admin.user-transit.table.address',
              },
              {
                id: 'privateKey',
                caption: 'admin.user-transit.table.private-key',
              },
              {
                id: 'balance',
                caption: 'admin.user-transit.table.balance',
                render: (balance, row) => (
                  <>
                    {formatCrypto(balance, row.crypto)}&nbsp;{row.crypto}
                  </>
                ),
              },
            ]}
            rows={rows}
            defaultSortBy={'address'}
            onShowMore={this.onShowMore}
          />
        </div>
      </div>
    );
  }
}
