import React from 'react';

import { t } from '../../config/i18n';
import { Cryptos, CurrencyBox, fiatCurrencies } from '../currencies';
import SelectEx, { SelectExOptionProps, SelectExProps } from './SelectEx';

interface CurrenciesSelectProps extends SelectExProps {
  withAll?: boolean;
  restriction?: string[];
  currencyLeft?: string | number;
  textAlign?: string;
}

const CurrenciesSelect = (props: CurrenciesSelectProps) => (
  <SelectEx {...props} options={getCurrenciesOptions(props)} />
);

const getCurrenciesOptions = (props: CurrenciesSelectProps): SelectExOptionProps[] => {
  const restrictionMap = props.restriction
    ? props.restriction.reduce((m, c) => ({ ...m, [c]: undefined }), {})
    : {};
  const currencies = Cryptos.concat(fiatCurrencies).filter(
    (c) => !props.restriction || c in restrictionMap,
  );
  const options: SelectExOptionProps[] = currencies.map((currency) => ({
    id: currency,
    img: <CurrencyBox c={currency} left={props.currencyLeft} />,
    imgPosition: 'left' as const,
    textAlign: props.textAlign,
  }));
  if (props.withAll) {
    options.unshift({ id: 'all', value: t('currency.all') });
  }
  return options;
};

export default CurrenciesSelect;
