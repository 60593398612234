import React from 'react';
import { RouteComponentProps } from 'react-router';

import { formatDateTime, formatNumber } from '../../helpers/utils';
import { api } from '../../utils/api';
import Txt from '../controls/Txt';
import { AdmSwitcherLabeled, AdmUserLink, ReturnButton } from './admin-common';
import { assignBid } from './Bids';

type BidStatus = 'enabled' | 'disabled';

export interface Bid {
  identificator: string;
  createdAt?: Date;
  type: string;
  symbol: string;
  broker: string;
  user: string;
  rate: number;
  percent?: number;
  limitFrom?: number;
  limitTo?: number;
  details?: string;
  isActive: BidStatus;
  isDeleted: boolean;
  currency: string;
}

interface State {
  bid: Bid;
}

export default class BidPage extends React.Component<
  RouteComponentProps<{ id: string }>
> {
  public state: State = {
    bid: {
      identificator: '',
      type: '',
      symbol: '',
      broker: '',
      user: '',
      rate: 0,
      percent: 0,
      isActive: 'enabled',
      isDeleted: false,
      currency: '',
    },
  };

  componentDidMount(): void {
    this.update();
  }

  update = () => {
    const { id } = this.props.match.params;
    api.admin
      .bid(id)
      .then((lot) => this.setState({ bid: assignBid(lot) }))
      .catch(() => undefined);
  };

  activateBid = (activate: boolean) => {
    const { bid } = this.state;
    api.admin
      .updateBid(bid.identificator, activate, undefined)
      .then(() => {
        bid.isActive = activate ? 'enabled' : 'disabled';
        this.setState({ bid });
      })
      .catch(() => undefined);
  };

  deleteBid = (deleted: boolean) => {
    const { bid } = this.state;
    api.admin
      .updateBid(bid.identificator, undefined, deleted)
      .then(() => {
        bid.isDeleted = deleted;
        this.setState({ bid });
      })
      .catch(() => undefined);
  };

  render(): React.ReactNode {
    const { bid } = this.state;

    return (
      <div className={'adm-page'}>
        <div className={'adm-page-header'}>
          <ReturnButton />
          <div className={'adm-page-title'}>
            <Txt k={'admin.bid.title'} args={[bid.identificator]} />
          </div>
        </div>
        <div className={'adm-page-content'}>
          <table className={'adm-table'}>
            <tbody>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.id'} />
                </td>
                <td className={'color-main'}>{bid.identificator}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.created-at'} />
                </td>
                <td>{formatDateTime(bid.createdAt)}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.type'} />
                </td>
                <td>
                  {bid.type ? (
                    <Txt k={`admin.bids.table.type-${bid.type}`} args={[bid.symbol]} />
                  ) : undefined}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.payment-method'} />
                </td>
                <td>{bid.broker}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.user'} />
                </td>
                <td>
                  <AdmUserLink nickname={bid.user} />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.rate'} />
                </td>
                <td>{formatNumber(bid.rate) + ' ' + bid.currency.toUpperCase()}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.percent'} />
                </td>
                <td>{formatNumber(bid.percent)}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.limits'} />
                </td>
                <td>
                  {!!bid.limitFrom && !!bid.limitTo ? (
                    <Txt
                      k={'admin.bids.table.limits-mask'}
                      args={[bid.limitFrom, bid.limitTo, bid.currency.toUpperCase()]}
                    />
                  ) : undefined}
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.trade-conditions'} />
                </td>
                <td>{bid.details}</td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.state'} />
                </td>
                <td>
                  <AdmSwitcherLabeled
                    value={bid.isActive === 'enabled'}
                    label={`common.${bid.isActive === 'enabled' ? 'yes' : 'no'}`}
                    onChange={this.activateBid}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Txt k={'admin.bids.table.deleted'} />
                </td>
                <td>
                  <AdmSwitcherLabeled
                    value={bid.isDeleted}
                    label={`common.${bid.isDeleted ? 'yes' : 'no'}`}
                    onChange={this.deleteBid}
                    className={'adm-bid-deleted'}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}
