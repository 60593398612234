export interface CommonRequestParams {
  symbol?: string;
  currency?: string;
  offset?;
  limit: number;
  q?: string;
  ordering?: string;
  user?: string;
}
export interface UserProfile {
  nickname: string;
  deals: {
    amount_currency: number;
    deals: number;
    likes: number;
    dislikes: number;
  };
  rating: number;
  currency: string;
  registered: number;
  verified: boolean;
  email?: string;
  online?: boolean;
  is_mfa_enabled?: boolean;
  isa?: boolean;
}

export interface CurrencyBalance {
  currency: string;
  amount: number;
}

export interface Rate {
  currency: string;
  rates: SubRate[];
}

export interface SubRate {
  currency: string;
  rate: number;
}

export interface FastDealRate {
  symbol: string;
  currency: string;
  rate: number;
}

export interface Wallet {
  address: string;
  symbol: string;
  balance: number;
  frozen: number;
  is_active: boolean;
}

export type OperationType =
  | 'all'
  | 'deal'
  | 'promocode'
  | 'transaction'
  | 'referral'
  | 'sky pay'
  | 'sky sale'
  | 'sky sale v2'
  | 'withdrawal';

export const operationTypes = {
  deal: 1,
  transaction: 2,
  promocode: 3,
  referral: 4,
  'sky pay': 5,
  'sky sale': 6,
  'sky sale v2': 7,
  withdrawal: 8,
};

export interface Operation {
  id: string;
  created_at: string; // need to be parsed to Date
  amount: number;
  symbol: string;
  action: string;
}

export interface SkyPayOperation extends Operation {
  amount_currency: number;
  commission: number;
  currency: string;
}

export interface PaymentMethod {
  id: string;
  name: string;
  lots: number;
}

export interface CurrencyList {
  id: string;
}

export type BidType = 'buy' | 'sell';

export interface Bid {
  id?: string;
  type?: BidType;
  symbol?: string;
  broker?: string;
  rate?: number;
  coefficient?: number;
  limit_from?: number;
  limit_to?: number;
  amount_limit?: number;
  currency?: string;
  details?: string;
  is_active?: boolean;
}

export interface UpdateBid {
  id: string;
  rate?: number;
  coefficient?: number;
  limit_from?: number;
  limit_to?: number;
  details?: string;
  is_active?: boolean;
  captcha_token?: string;
}

export interface Broker {
  id: string;
  name: string;
}

export interface MarketBid extends Bid {
  user: UserProfile;
}

export interface DealBid extends Bid {
  user: string;
}

export type DealState = 'new' | 'proposed' | 'confirmed' | 'paid' | 'closed' | 'deleted';

export interface Deal {
  id: string;
  symbol: string;
  lot: DealBid;
  rate: number;
  state: DealState;
  amount: number;
  amount_currency: number;
  seller: UserProfile;
  seller_commission: number;
  buyer: UserProfile;
  buyer_commission: number;
  referral_commission_buyer: number;
  referral_commission_seller: number;
  requisite: string;
  created_at: string; // need to be parsed to Date
  end_time: string; // need to be parsed to Date
  expire_in: number | null;
  voted: boolean;
  cancel_reason: string | null;
  dispute: {
    initiator: string;
    opponent: string | null;
    created_at: string; // need to be parsed to Date
  } | null;
}

export interface DealShort {
  id: string;
  currency: string;
  type: string;
  status: string;
  amount: number;
  amount_currency: number;
  subCurrency: number;
  paymentMethod: string;
  agent: string;
  date?: Date;
}

export interface ActiveDeal {
  id: string;
  symbol: string;
  currency: string;
  type: string;
  state: DealState;
  amount: number;
  amount_currency: number;
  opponent: string;
  created_at: string; // need to be parsed to Date
  broker: string;
  dispute: boolean | null;
  is_lot_owner: boolean;
}

export interface NewDeal {
  lot_id: string;
  rate?: number; // crypto rate
  amount_currency?: number; // amount in fiat currency
  amount?: number; // amount in crypto
  requisite?: string;
}

export interface DealResponse {
  success: string;
}

export interface Dispute {
  initiator: string;
  opponent: string;
  created_at: string; // need to be parsed to Date
}

export interface Promocode {
  id?: string;
  symbol: string;
  amount: number;
  activations?: number;
  count?: number;
  user?: string;
  created_at?: string; // need to be parsed to Date
}

export interface ActivatedPromocode {
  activated_at: string; // need to be parsed to Date
  promocode: Promocode;
}

export interface Referrals {
  earned: Array<{
    symbol: string;
    amount: number;
    invited: number;
    ref_code: string;
  }>;
  invited_count: number;
}

export interface Message {
  created_at: string; // need to be parsed to Date
  receiver: string;
  sender: string;
  message?: string;
  media?: string;
}

export interface SendMessage {
  receiver: string;
  message?: string;
  media_id?: number;
  symbol?: string;
}

export interface Notification {
  id: number;
  type:
    | 'deal'
    | 'cancel_deal'
    | 'income_referral'
    | 'message'
    | 'timeout'
    | 'transaction'
    | 'dispute'
    | 'closed_dispute'
    | 'promocode'
    | 'exchange';
  created_ago: number;
  is_read: boolean;
  details: any;
}

export interface Commission {
  symbol: string;
  commission: number;
  commission_buyer: number;
  min_amount?: number;
  rate_variation?: string;
}

export interface JoinAccountStatus {
  joined: boolean;
}

export interface UserAccountJoins {
  id: number;
  account_tg: string;
  account_web: string;
}

export interface Merchant {
  name: string;
  website?: string;
  commission?: number;
  image_url?: string | Blob;
  image_name?: string;
  callback_url?: string;
  callback_url_sale?: string;
  callback_safe?: boolean;
}

export type CommissionForTransfer = Record<string, StableAndDynamicCommissions>;

export interface StableAndDynamicCommissions {
  stable?: number;
  dynamic?: Array<Array<number>>;
}

export interface AdmDeal {
  id: string;
  type: number;
  symbol: string;
  lot: string;
  seller: string;
  buyer: string;
  rate: number;
  amount: number;
  amount_currency: number;
  seller_commission: number;
  buyer_commission: number;
  state: DealState;
  created_at?: string;
  end_time?: string;
  requisite?: string;
  currency: string;
}

export interface AdmProfit {
  symbol: string;
  profit: number;
  users_balance: number;
  wallet_balance: number;
  deposit_revenue: number;
  withdraw_revenue: number;
  users: number;
  online_users: number;
  updated: string;
}

export interface AdmLot {
  identificator: string;
  type: string;
  symbol: string;
  user: string;
  broker: string;
  rate: number;
  coefficient: number;
  limit_from: number;
  limit_to: number;
  details: string;
  created_at: string;
  is_active: boolean;
  is_deleted: boolean;
  currency: string;
}

export interface AdmAutotrader {
  id: number;
  callback_url: string;
  name: string;
  allow_flash_pay: boolean;
}

export interface AdmAutotraderStatistics {
  amount: number;
  broker: string;
  received_fiat_in_crypto: number;
  cancel_reason: string;
  created_at: string;
  currency: string;
  identificator: string;
  invoice_id: string;
  nickname: string;
  payment_v2: string;
  processed_at: string;
  state: string;
  symbol: string;
}

export interface AdmPromocode {
  id: number;
  code: string;
  symbol: string;
  user: string;
  amount: number;
  count: number;
  activations: number;
  created_at: string;
}

export interface AdmPromocodeActivations {
  activations: Array<{
    user: string;
    created_at: string;
  }>;
}

export interface AdmUser {
  id: number;
  nickname: string;
  email: string;
  telegram_id: number;
  rating: number;
  balance: number;
  frozen: number;
  amount: number;
  deals_count: number;
  dislikes: number;
  likes: number;
  created_at: string;
  is_verify: boolean;
  is_temporary: boolean;
  is_baned: boolean;
  is_deleted: boolean;
  is_mfa_enabled: boolean;
}

export interface AdmUserLoginData {
  created_at: string;
  device: string;
  ip: string;
  is_active: boolean;
}
export interface AdmMerchantStatistics {
  month_year: string;
  successful: number;
  turnover: number;
  unsuccessful: number;
}

export interface AdmMerchantConversions {
  day: string;
  successful: number;
  conversion_rate: number;
  unsuccessful: number;
}

export interface AdmNewUserPassword {
  new_password: string;
}

export interface MFAResetData {
  success: string;
}

export interface AdmTransaction {
  id: number;
  type: string;
  to_address: string;
  tx_hash: string;
  amount: number;
  commission: number;
  is_confirmed: boolean;
  is_deleted: boolean;
  created_at: string;
  processed_at: string;
}
export interface AdmCurrency {
  id: string;
  created_at: string;
  is_active: boolean;
  rate_variation: number;
  usd_rate: number;
}
export interface AdmMerchant {
  required_mask: boolean;
  merchant_id: number;
  name: string;
  nickname: string;
  payments: number;
  is_autoreport_active: boolean;
  autoreport_email?: string;
  sales: number;
  commission?: number;
  commission_sale?: number;
  allow_waiting_back_url: boolean;
  is_active: boolean;
  is_active_sale: boolean;
  is_active_withdrawal: boolean;
  is_active_cpay: boolean;
  is_active_sky_pay_v2: boolean;
  is_receipt_required: boolean;
}

export interface AdmMerchantDeal {
  callback_id?: string;
  id: string;
  symbol: string;
  amount: number;
  label?: string;
  status: number;
  created_at: string;
  is_currency_amount?: boolean;
  amount_currency?: number;
  cancel_reason?: string;
  currency?: string;
}

export interface AdmSettingsValue {
  key: string;
  value: string;
}

export interface AdmSystemFlag {
  key: string;
  value: boolean;
}

export interface AdmCommission {
  type: string;
  commission: number;
}

export interface AdmCryptoSettings {
  symbol: string;
  min_tx_amount: number;
  tx_out_commission: number;
  net_commission: number;
  withdraw_active: boolean;
  buyer_commission: number;
  seller_commission: number;
  exchange_active: boolean;
}

export interface BrokerCurrencies {
  currency: string;
  rate_variation: number;
  allow_flash_pay: boolean;
  autotrader_id: number | string;
  sale_v2_rate: number;
  allow_sky_pay_autotrader: boolean;
}
export interface AdmBroker {
  currencies: BrokerCurrencies[];
  id: string;
  name: string;
}
