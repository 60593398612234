import React from 'react';
import { RouteComponentProps } from 'react-router';

import { t } from '../../config/i18n';
import { showMessage } from '../../helpers/notifications';
import { formatCrypto, formatDateTime, parseDate } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmMerchantDeal, CommonRequestParams } from '../../utils/types';
import { Row } from '../common';
import ActionButton from '../controls/ActionButton';
import Txt from '../controls/Txt';
import { AdmLink, AdmTablePopup, AdmText } from './admin-common';
import PageLayout from './PageLayout';

type filtersType = {
  operation: merchantOperationsType;
};

export type merchantOperationsType =
  | 'sky-pay'
  | 'sky-sale'
  | 'sky-sale-v2'
  | 'sky-withdrawals-v2'
  | 'sky-cpayment'
  | 'sky-pay-v2';
interface MerchantDeal {
  createdAt?: Date;
  callbackId?: string;
  status: number;
  id: string;
  cancelReason?: string;
  amount: number;
  amountCurrency?: number;
  symbol: string;
  isCurrencyAmount?: boolean;
  currency?: string;
  profit?: number;
  label?: string;
}

interface State {
  filters: filtersType;
}

interface FilterTypes {
  id: merchantOperationsType;
  value: string;
  searchParam: string;
}

const statusMapping = {
  0: 'created',
  1: 'in-progress',
  2: 'closed',
  3: 'canceled',
};

const filterTypes: FilterTypes[] = [
  { id: 'sky-pay', value: 'SKY PAY', searchParam: 'payment_id' },
  { id: 'sky-sale', value: 'SKY SALE', searchParam: 'sale_id' },
  { id: 'sky-sale-v2', value: 'SKY SALE V2', searchParam: 'sale_id' },
  { id: 'sky-withdrawals-v2', value: 'WITHDRAWAL V2', searchParam: 'withdrawal_id' },
  { id: 'sky-cpayment', value: 'CPAYMENT', searchParam: 'cpayment_id' },
  { id: 'sky-pay-v2', value: 'SKY PAY V2', searchParam: 'payment_v2_id' },
];

export default class Merchant extends React.Component<
  RouteComponentProps<{ id; name: string }>
> {
  public state: State = {
    filters: { operation: filterTypes[0].id },
  };

  setStatus = (
    id: string,
    status: number,
    i: number,
    rows: MerchantDeal[],
    setRows: (data: MerchantDeal[]) => void,
  ) => {
    const {
      filters: { operation },
    } = this.state;
    let method;
    switch (operation) {
      case 'sky-sale':
        method = api.admin.merchantSaleUpdate;
        break;
      case 'sky-pay-v2':
        method = api.admin.merchantDealV2Update;
        break;
      case 'sky-sale-v2':
        method = api.admin.merchantSaleV2Update;
        break;
      default:
        method = api.admin.merchantDealUpdate;
        break;
    }
    method(id, status)
      .then(() => {
        const newRows = [...rows];
        newRows[i].status = status;
        setRows(newRows);
      })
      .catch(() => undefined);
  };

  handleFilterChange = (
    id: merchantOperationsType,
    request,
    resetRows,
    searchedValue?: string,
  ): void => {
    this.setState({ filters: { operation: id } }, () => {
      resetRows();
      request(true, searchedValue, { operation: id });
    });
  };

  handlerResend = async (paymentId: string, update: () => any[]) => {
    const { filters } = this.state;
    const { id } = this.props.match.params;
    await api.admin.merchantResendCallback(
      id,
      paymentId,
      filters.operation === 'sky-cpayment' ? 'cpayments' : filters.operation,
    );
    update();
    showMessage('info', 'Sent successfully!');
  };

  handlerCancelCallback = async (callbackId: string, update: () => any[]) => {
    await api.admin.cancelCallback(callbackId);
    update();
    showMessage('info', 'Cancelled successfully!');
  };

  renderColumns = (update) => {
    console.log(update);
    const { filters } = this.state;

    const columns = [
      {
        id: 'createdAt',
        caption: 'admin.merchant.table.date',
        render: (createdAt) => formatDateTime(createdAt),
      },
      {
        id: 'status',
        caption: 'admin.merchant.table.status',
        render: (status, row, i, rows, setRows) => (
          <>
            {`${t(`admin.merchant.table.status-${statusMapping[status]}`)} [${status}]`}
            {(filters.operation === 'sky-pay' ||
              filters.operation === 'sky-sale' ||
              filters.operation === 'sky-sale-v2' ||
              filters.operation === 'sky-pay-v2') && (
              <StatusBox
                onClick={(status) => this.setStatus(row.id, status, i, rows, setRows)}
              />
            )}
          </>
        ),
      },
      {
        id: 'id',
        caption: 'admin.merchant.table.payment-id',
      },
      {
        id: 'label',
        caption: 'admin.merchant.table.label',
      },
      {
        id: 'amount',
        caption: 'admin.merchant.table.amount',
        render: (amount, row) => (
          <>
            {formatCrypto(amount, row.symbol)}&nbsp;
            {row.isCurrencyAmount ||
            filters.operation === 'sky-sale' ||
            filters.operation === 'sky-sale-v2'
              ? row.currency
              : row.symbol}
          </>
        ),
      },
      {
        id: 'cancelReason',
        caption: 'admin.merchant.table.cancel-reason',
        render: (cr) => !!cr && <Txt k={`admin.merchant.table.cancel-reasons.${cr}`} />,
      },
    ];

    const callbackColumn = {
      noSorting: true,
      id: 'resendBtn',
      caption: 'admin.merchant.table.callback',
      render: (_, row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {row.callbackId ? (
            <ActionButton
              caption={'common.cancel_sending'}
              onClick={() => this.handlerCancelCallback(row.callbackId, update)}
              width={'150px'}
              height={'35px'}
            />
          ) : (
            <ActionButton
              caption={'common.resend'}
              onClick={() => this.handlerResend(row.id, update)}
              width={'150px'}
              height={'35px'}
            />
          )}
        </div>
      ),
    };

    const skyCpaymentColumn = {
      id: 'amountCurrency',
      caption: 'admin.merchant.table.amount-currency',
      render: (_, row) => (
        <>
          {row.amountCurrency}&nbsp;
          {row.currency}
        </>
      ),
    };

    if (filters.operation === 'sky-cpayment') {
      columns.push(skyCpaymentColumn);
    }
    if (filters.operation !== 'sky-withdrawals-v2') {
      columns.push(callbackColumn);
    }

    return columns;
  };

  getMerchantDeals = (
    params: CommonRequestParams & {
      url: merchantOperationsType;
      merchantId: string;
    },
  ): Promise<MerchantDeal[]> => {
    const { id: merchantId } = this.props.match.params;
    const { filters } = this.state;

    const url = filters.operation;
    const searchOperationKey =
      filterTypes.find((item) => item.id === url)?.searchParam ||
      filterTypes[0].searchParam;

    return new Promise((resolve) => {
      api.admin
        .merchantOperations({ ...params, url, merchantId, searchOperationKey })
        .then((result) => {
          const deals: MerchantDeal[] = [];
          for (const deal of result) {
            deals.push(assignMerchantDeal(deal));
          }
          resolve(deals);
        })
        .catch(() => showMessage('info', 'Проверьте фильтр. ID должен быть полным.'));
    });
  };

  render(): React.ReactNode {
    const { name, id: merchantId } = this.props.match.params;
    const { filters } = this.state;

    return (
      <PageLayout<MerchantDeal>
        isReturnButton
        isHeaderTitleVisible={false}
        request={this.getMerchantDeals}
        headerTitle={'admin.bids.title'}
        searchInputHint={'admin.merchant.search-deal'}
        subtitleArgs={[name]}
        defaultSortBy={'createdAt'}
        additional={{
          component: (request, resetRows, searchedValue) => (
            <Row>
              <AdmText
                k={`admin.merchant.filter-type.${filters.operation}`}
                left={'1rem'}
              />
              <AdmTablePopup className={'adm-transaction-actions'}>
                {filterTypes.map((n) => (
                  <div
                    className={'adm-button delete-transaction select-transaction'}
                    onClick={() =>
                      this.handleFilterChange(n.id, request, resetRows, searchedValue)
                    }
                    key={n.id}>
                    <span
                      style={{ color: n.id === filters.operation ? 'white' : 'inherit' }}>
                      {n.value}
                    </span>
                  </div>
                ))}
              </AdmTablePopup>
              <div style={{ marginLeft: '16px', display: 'flex', gap: '8px' }}>
                <AdmLink href={`admin/merchants/${merchantId}/${name}/statistics`}>
                  {<Txt k="admin.merchant.table.statistics-show" />}
                </AdmLink>
                <AdmLink href={`admin/merchants/${merchantId}/${name}/conversion`}>
                  {<Txt k="admin.merchant.table.conversion" />}
                </AdmLink>
              </div>
            </Row>
          ),
        }}
        tableColumns={this.renderColumns}
      />
    );
  }
}

const StatusBox = ({ onClick }: { onClick: (status: number) => void }) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    {[1, 2, 3].map((n) => (
      <div className={'adm-button delete-transaction'} onClick={() => onClick(n)} key={n}>
        <span>[{n}]</span>
      </div>
    ))}
  </AdmTablePopup>
);

const getMerchantDeals =
  (merchantId, filters) =>
  (
    params: CommonRequestParams & {
      url: merchantOperationsType;
      merchantId: string;
    },
  ): Promise<MerchantDeal[]> => {
    const url = filters.operation;
    const searchOperationKey =
      filterTypes.find((item) => item.id === url)?.searchParam ||
      filterTypes[0].searchParam;

    console.log(filters);

    return new Promise((resolve) => {
      api.admin
        .merchantOperations({ ...params, url, merchantId, searchOperationKey })
        .then((result) => {
          const deals: MerchantDeal[] = [];
          for (const deal of result) {
            deals.push(assignMerchantDeal(deal));
          }
          resolve(deals);
        })
        .catch(() => showMessage('info', 'Проверьте фильтр. ID должен быть полным.'));
    });
  };

export function assignMerchantDeal(deal: AdmMerchantDeal): MerchantDeal {
  return {
    createdAt: parseDate(deal.created_at),
    status: deal.status,
    id: deal.id,
    callbackId: deal.callback_id,
    isCurrencyAmount: deal.is_currency_amount,
    currency: deal.currency?.toUpperCase(),
    amount: deal.amount,
    cancelReason: deal.cancel_reason,
    amountCurrency: deal.amount_currency,
    symbol: deal.symbol?.toUpperCase(),
    label: deal.label,
  };
}
