import { setPage } from '../config/history';
import { getItem, removeItem, setItem } from '../utils/localStorage';
import { setCurrentUser } from './settings';

const TOKEN_FIELD = 'sky_token';
const ADMIN_TOKEN_FIELD = 'sky_admin_token';
const REFRESH_TOKEN = 'sky_refresh_token';

export function setAuthToken(token: string) {
  removeItem(TOKEN_FIELD);
  setItem(TOKEN_FIELD, token);
}

export function getAuthToken() {
  return getItem(TOKEN_FIELD);
}

export function removeAuthToken() {
  return removeItem(TOKEN_FIELD);
}

export function removeRefreshToken() {
  return removeItem(REFRESH_TOKEN);
}

export function setAdminAuthToken(token: string) {
  removeItem(ADMIN_TOKEN_FIELD);
  setItem(ADMIN_TOKEN_FIELD, token);
}

export function getAdminAuthToken() {
  return getItem(ADMIN_TOKEN_FIELD);
}

export function removeAdminAuthToken() {
  return removeItem(ADMIN_TOKEN_FIELD);
}

export function getAuthHeader(token?: string) {
  return {
    Authorization: `Bearer ${token || getAuthToken()}`,
  };
}

export function getAdminAuthHeader(token?: string) {
  return {
    Authorization: `Bearer ${token || getAdminAuthToken()}`,
  };
}

export function logout() {
  setCurrentUser();
  removeAuthToken();
  removeAdminAuthToken();
  removeRefreshToken();
  setPage();
}
