import {
  IFastDealRegisterData,
  IFastDealRegisterParams,
  ILoginData,
  ILoginParams,
  IRegisterCompleteData,
  IRegisterCompleteParams,
  IRegisterEmailData,
  IRegisterParams,
  IValidateEmailData,
  IValidateEmailParams,
} from '../domain/services/AuthServiceEntities';
import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class AuthService {
  // OK
  static login = async (
    data: ILoginParams,
    onSuccess?: ISuccessCallbackType<ILoginData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ILoginData>({
      url: '/auth/login',
      method: 'POST',
      data: data,
      headers: 'none',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static registerEmail = async (
    data: IRegisterParams,
    onSuccess?: ISuccessCallbackType<IRegisterEmailData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IRegisterEmailData>({
      url: '/auth/register',
      method: 'POST',
      data: data,
      headers: 'none',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static validateEmail = async (
    data: IValidateEmailParams,
    onSuccess?: ISuccessCallbackType<IValidateEmailData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IValidateEmailData>({
      url: '/auth/validate',
      method: 'POST',
      data: data,
      headers: 'none',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static registerComplete = async (
    data: IRegisterCompleteParams,
    onSuccess?: ISuccessCallbackType<IRegisterCompleteData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IRegisterCompleteData>({
      url: '/auth/complete-register',
      method: 'POST',
      data: data,
      headers: 'none',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealRegister = async (
    params: IFastDealRegisterParams,
    onSuccess?: ISuccessCallbackType<IFastDealRegisterData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealRegisterData>({
      url: '/auth/fast-register',
      method: 'POST',
      data: {
        email: params.email,
        captcha_token: params.recaptchaToken,
        cmp: params.cmp,
      },
      headers: 'none',
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
