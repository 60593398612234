import React from 'react';
import styled from 'styled-components';

import { t } from '../../config/i18n';
import {
  copyToClipboard,
  formatCrypto,
  formatNumber,
  formatString,
  getCryptoFracDigits,
} from '../../helpers/utils';
import { calcSize, Defaults, px, TextEx } from '../common';
import IconEx from './IconEx';
import InputEx from './InputEx';
import Txt from './Txt';

export const pageFontSize = '1rem';
export const pageBorderColor = '#e9e9e9';
export const pageInfoCaptionColor = Defaults.grayColor;
export const pageControlHeight = '';

export const PageCaption = ({
  title,
  args = [],
  height = '4.625rem',
  children,
}: {
  title: string;
  args?: any[];
  height?: number | string;
  children?: React.ReactNode;
}) => (
  <PageCaptionBox height={height}>
    <TextEx size={'1.125rem'}>
      <div
        className={'pageTitle'}
        dangerouslySetInnerHTML={{ __html: formatString(t(title), ...args) || '' }}
      />
    </TextEx>
    {children}
  </PageCaptionBox>
);

export const PageContentRow = ({
  label,
  labelArgs,
  labelPos,
  labelWidth,
  left,
  top = '1.875rem',
  width,
  shrink,
  children,
}: {
  label?: string;
  labelArgs?: any[];
  labelPos?: 'left' | 'top';
  labelWidth?: number | string;
  left?: number | string;
  top?: number | string;
  width?: number | string;
  shrink?: number;
  children: React.ReactNode;
}) => (
  <PageContentRowBox
    className={'row-block'}
    left={left}
    top={top}
    labelPos={labelPos}
    width={width}
    shrink={shrink}>
    <PageContentRowLabelBox position={labelPos} width={labelWidth}>
      {label ? (
        <TextEx size={pageFontSize}>
          <Txt k={label} args={labelArgs} />
        </TextEx>
      ) : undefined}
    </PageContentRowLabelBox>
    <PageContentRowChildrenBox>{children}</PageContentRowChildrenBox>
  </PageContentRowBox>
);

export const AmountInput = ({
  value,
  height,
  onChange,
  flexGrow,
  width,
  minWidth,
  onBlur,
  img,
  imgWidth,
  fracDigits,
  crypto,
}: {
  value: number;
  height;
  width?;
  minWidth?;
  imgWidth?: number | string;
  onChange: (value: number) => void;
  flexGrow?: number;
  img?: React.ReactNode;
  fracDigits?: number;
  crypto?: string;
  onBlur?: (e) => void;
}) => {
  return (
    <InputEx
      type={'number'}
      value={value}
      onChange={onChange}
      fontSize={pageFontSize}
      borderColor={pageBorderColor}
      height={height}
      width={width}
      minWidth={minWidth}
      flexGrow={flexGrow}
      onBlur={onBlur}
      img={img}
      imgWidth={imgWidth}
      placeholder={
        <TextEx size={pageFontSize}>
          {crypto
            ? formatCrypto(value, crypto)
            : formatNumber(value, fracDigits, fracDigits)}
        </TextEx>
      }
      maxFracDigits={crypto ? getCryptoFracDigits(crypto) : 2}
    />
  );
};

export const PageReadOnlyValue = ({
  height,
  width,
  minHeight,
  top,
  flexGrow,
  fontSize = pageFontSize,
  textAlign,
  overflow,
  copy,
  onCopy,
  children,
}: {
  height?;
  width?;
  minHeight?: number | string;
  top?: number | string;
  flexGrow?: number;
  fontSize?: number | string;
  textAlign?: string;
  overflow?: string;
  copy?: boolean;
  onCopy?: () => void;
  children?: any;
}) => {
  const ref = React.useRef<HTMLElement>(null);
  const hasCopy = copy || !!onCopy;

  return (
    <PageReadOnlyValueRoot
      className={hasCopy ? 'hasCopy' : ''}
      ref={ref}
      height={height}
      width={width}
      minHeight={minHeight}
      top={top}
      flexGrow={flexGrow}
      align={textAlign}
      overflow={overflow}
      borderColor={pageBorderColor}>
      <TextEx size={fontSize} customStyle={'word-break: break-all;'}>
        {children}
      </TextEx>
      {hasCopy ? (
        <div
          className={'copy'}
          onClick={() => (onCopy ? onCopy() : copyToClipboard(children, ref.current))}>
          <IconEx src={'/ico/copy.svg'} size={'1rem'} hint={'operations.copy'} />
        </div>
      ) : undefined}
    </PageReadOnlyValueRoot>
  );
};

export const PageInfoRow = ({
  caption,
  value,
}: {
  caption: string;
  value: React.ReactNode;
}) => (
  <PageInfoRowBox>
    <TextEx className={'h6'} size={pageFontSize}>
      {value}
    </TextEx>
    <TextEx
      top={'2px'}
      size={calcSize(pageFontSize, (n) => n * 0.8)}
      color={pageInfoCaptionColor}>
      <Txt k={caption} />
    </TextEx>
  </PageInfoRowBox>
);

export interface PageTextProps {
  k: string;
  args?: any[];
  weight?: number;
  color?: string;
  size?;
  width?: number | string;
  left?;
  top?: number | string;
  customStyle?: string;
  fontFamily?: string;
  uppercase?: boolean;
  lineHeight?: any;
  textAlign?: string;
  center?: boolean;
  after?: string;
  letterSpacing?: string;
  onClick?: () => void;
}

export const PageText = ({
  k,
  args,
  size,
  weight,
  color,
  left,
  top,
  width,
  customStyle,
  fontFamily,
  uppercase,
  lineHeight,
  textAlign,
  center,
  after,
  letterSpacing,
  onClick,
}: PageTextProps) => (
  <TextEx
    size={size || pageFontSize}
    weight={weight}
    color={color}
    left={left}
    top={top}
    width={width}
    customStyle={customStyle}
    fontFamily={fontFamily}
    uppercase={uppercase}
    lineHeight={lineHeight}
    textAlign={center ? 'center' : textAlign}
    letterSpacing={letterSpacing}
    onClick={onClick}>
    <Txt k={k} args={args} />
    {after}
  </TextEx>
);

export const ComplainButton = ({
  caption,
  top,
  onClick,
}: {
  caption: string;
  top?: number | string;
  onClick: () => void;
}) => (
  <ComplainButtonRoot top={top}>
    <div onClick={onClick}>
      <ComplainIcon size={'1.125rem'} />
      <TextEx left={'0.6vh'} size={pageFontSize}>
        <Txt k={caption} />
      </TextEx>
    </div>
  </ComplainButtonRoot>
);

const ComplainIcon = ({ size }: { size: number | string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'}>
    <g>
      <path
        d="M368,112c-11,1.4-24.9,3.5-39.7,3.5c-23.1,0-44-5.7-65.2-10.2c-21.5-4.6-43.7-9.3-67.2-9.3c-46.9,
                0-62.8,10.1-64.4,11.2 l-3.4,2.4v2.6v161.7V416h16V272.7c6-2.5,21.8-6.9,51.9-6.9c21.8,0,42.2,8.3,63.9,
                13c22,4.7,44.8,9.6,69.5,9.6 c14.7,0,27.7-2,38.7-3.3c6-0.7,11.3-1.4,16-2.2V126v-16.5C379.4,110.4,374,
                111.2,368,112z"
      />
    </g>
  </svg>
);

export const PageRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => px(props.width) || 'auto'};
  height: ${(props) => px(props.height) || 'auto'};
  margin-left: ${(props) => px(props.left)};
  margin-top: ${(props) => px(props.top)};
  margin-bottom: ${(props) => px(props.bottom)};
  min-width: ${(props) => px(props.minWidth)};
  ${(props) => (props.minHeight ? `min-height: ${px(props.minHeight)};` : '')}
  ${(props) => (props.padding ? `padding: ${px(props.padding)};` : '')}
  background-color: white;
  border-radius: ${px(Defaults.borderRadius)};
`;
export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding: 1.875rem;
`;
export const PageActions = styled(PageContent)`
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
`;
const PageCaptionBox = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0 2.1rem;
  height: ${(props) => px(props.height)};
`;
const PageContentRowBox = styled.div`
  display: flex;
  flex-shrink: ${(props) => props.shrink || 0};
  flex-direction: ${(props) => (props.labelPos === 'top' ? 'column' : 'row')};
  ${(props) => (props.labelPos === 'left' ? 'align-items: center;' : '')};
  width: ${(props) => (props.width ? px(props.width) : '100%')};
  margin-left: ${(props) => px(props.left)};
  margin-top: ${(props) => px(props.top)};

  &:first-child {
    margin-left: 0;
    margin-top: 0;
  }
`;
const PageContentRowLabelBox = styled.div`
    min-width: ${(props) => px(props.width)};
    margin-${(props) => (props.position === 'top' ? 'bottom' : 'right')}: .8125rem;
`;
const PageContentRowChildrenBox = styled.div`
  width: 100%;
`;
const PageReadOnlyValueRoot = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: ${(props) => props.flexGrow || 1};
  height: ${(props) => px(props.height)};
  width: ${(props) => px(props.width)};
  min-height: ${(props) => px(props.minHeight)};
  margin-top: ${(props) => px(props.top)};
  align-items: center;
  justify-content: ${(props) => props.align || 'flex-start'};
  padding: 0.8125rem;
  border: 2px solid ${pageBorderColor};
  border-radius: ${px(Defaults.borderRadius)};
  background-color: #f5f5f5;
  overflow: ${(props) => props.overflow || 'hidden'};

  &.hasCopy {
    padding-right: 1.875rem;
  }

  & .copy {
    position: absolute;
    display: flex;
    align-items: center;
    right: 1vh;
    height: 100%;
    cursor: pointer;
  }
`;
const PageInfoRowBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  &:first-child {
    margin-top: 0;
  }
`;
const ComplainButtonRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => px(props.top || 0)};
  flex-grow: 1;

  & div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  & div * {
    fill: ${pageInfoCaptionColor};
    color: ${pageInfoCaptionColor};
    transition: ${Defaults.transition};
  }
  & div:hover * {
    fill: ${Defaults.mainColor};
    color: ${Defaults.mainColor};
  }
`;
