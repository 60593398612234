import { ReactNode } from 'react';
import ReactModal from 'react-modal';

import { Defaults } from '../common';
import ActionButton from './ActionButton';

interface ModalProps {
  title?: string | ReactNode;
  subtitle: string | ReactNode;
  style: Record<string, string>;
  isOpen: boolean;
  children: ReactNode | Element;
  onRequestClose: () => void;
}

const Modal = (props: ModalProps) => {
  const { children, style = {}, isOpen = false, title, subtitle, onRequestClose } = props;
  return (
    <ReactModal
      isOpen={isOpen}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          ...style,
        },
        overlay: { backgroundColor: 'transparent' },
      }}
      onRequestClose={onRequestClose}>
      <div className="adm-user__modal-close-button-container">
        <ActionButton
          width={24}
          height={24}
          buttonColor={Defaults.whiteColor}
          hoverColor={Defaults.whiteColor}
          onClick={onRequestClose}>
          X
        </ActionButton>
      </div>
      {title}
      {subtitle}
      {children}
    </ReactModal>
  );
};

export default Modal;
