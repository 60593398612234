import './admin.css';

import React from 'react';
import { Route, Router, Switch } from 'react-router';

import history, { getPage, setPage } from '../../config/history';
import { t } from '../../config/i18n';
import {
  getAdminAuthToken,
  getAuthToken,
  logout,
  setAdminAuthToken,
} from '../../helpers/authHeader';
import { cl } from '../../helpers/utils';
import { api } from '../../utils/api';
import { Defaults } from '../common';
import { LogoIcon, LogoText } from '../controls/Logo';
import Txt from '../controls/Txt';
import { Cryptos } from '../currencies';
import { AdmInput, changeCrypto, renderIcon } from './admin-common';
import API from './API';
import Autotraders from './Autotraders';
import AutotraderStatistics from './AutotraderStatistics';
import BidPage from './Bid';
import Bids from './Bids';
import Brokers from './Brokers';
import Currencies from './Currencies';
import DealPage from './Deal';
import Deals from './Deals';
import {
  ApiIcon,
  BidsIcon,
  CurrencyIcon,
  DealsIcon,
  FinanceIcon,
  FreezingIcon,
  MailingIcon,
  MarketingIcon,
  ProfitIcon,
  PromocodesIcon,
  SettingsIcon,
  TransactionsIcon,
  UsersIcon,
  WebIcon,
} from './icons';
import Merchant from './Merchant';
import MerchantConversion from './MerchantConversion';
import MerchantStatistics from './MerchantStatistics';
import Profit from './Profit';
import Promocodes from './Promocodes';
import Settings from './Settings';
import Transactions from './Transactions';
import User from './User';
import UserBids from './UserBids';
import UserChat from './UserChat';
import UserDeals from './UserDeals';
import UserLoginData from './UserLoginData';
import UserPromocodes from './UserPromocodes';
import Users from './Users';
import UserTransactions from './UserTransactions';
import UserTransit from './UserTransit';

interface MenuItem {
  title: string;
  icon: React.ReactNode;
}

const items: MenuItem[] = [
  { title: 'deals', icon: DealsIcon },
  { title: 'users', icon: UsersIcon },
  { title: 'promocodes', icon: PromocodesIcon },
  { title: 'bids', icon: BidsIcon },
  { title: 'transactions', icon: TransactionsIcon },
  { title: 'finance', icon: FinanceIcon },
  { title: 'marketing', icon: MarketingIcon },
  { title: 'profit', icon: ProfitIcon },
  { title: 'mailing', icon: MailingIcon },
  { title: 'freezing', icon: FreezingIcon },
  { title: 'autotraders', icon: FinanceIcon },
  { title: 'api', icon: ApiIcon },
  { title: 'brokers', icon: FinanceIcon },
  { title: 'currencies', icon: CurrencyIcon },
];

interface State {
  cryptoIndex: number;
  crypto: string;
  nickname?: string;
  password?: string;
  code?: string;
  codeRequired: boolean;
  currentPageName: string;
}

interface PageAdminProps {
  openLogin: () => void;
}

export default class PageAdmin extends React.Component<PageAdminProps> {
  public state: State = {
    cryptoIndex: 0,
    crypto: Cryptos[0],
    codeRequired: false,
    currentPageName: getPage().name,
  };

  componentDidMount(): void {
    if (!getAuthToken()) {
      return setPage();
    }
    // if (!getUserIsAdmin()) {
    // logout();
    // this.props.openLogin();
    // }
  }

  handlePageChange = (item: string) => () => {
    const page = `admin${item !== 'admin' ? `/${item}` : ''}`;
    setPage(page);
    this.setState({ currentPageName: item });
  };

  onCryptoSwitcherChange = () => {
    let { cryptoIndex } = this.state;
    cryptoIndex++;
    if (cryptoIndex >= Cryptos.length) {
      cryptoIndex = 0;
    }

    const crypto = Cryptos[cryptoIndex];
    this.setState({ cryptoIndex, crypto });
    changeCrypto(crypto);
  };

  onChangeField = (field: string) => (value?: string) =>
    this.setState({ [field]: value });

  adminLogin = () => {
    const { nickname, password, code, codeRequired } = this.state;
    if (!nickname || !password || (codeRequired && !code)) {
      return;
    }
    api.admin
      .login(nickname, password, code)
      .then((response) => {
        if (response.request_code) {
          this.setState({ codeRequired: true });
        }
        if (response.token) {
          setAdminAuthToken(response.token);
          this.handlePageChange('settings')();
          this.setState({ password: undefined, code: undefined, codeRequired: false });
        }
      })
      .catch(() => undefined);
  };

  render(): React.ReactNode {
    const {
      cryptoIndex,
      crypto,
      nickname,
      password,
      code,
      codeRequired,
      currentPageName,
    } = this.state;

    return (
      <div className={'adm-main-page'}>
        <div className={'adm-top-menu'}>
          <div
            className={'adm-button web'}
            onClick={() => {
              window.location.href = process.env.REACT_APP_PUBLIC_URL || '';
            }}>
            <div>
              <WebIcon />
            </div>
            <span>WEB</span>
          </div>
          <div
            className={'adm-button logout'}
            onClick={() => {
              this.props.openLogin();
              logout();
            }}>
            <Txt k={'top-bar.logout'} />
          </div>
        </div>
        <div className={'adm-menu'}>
          <div className={'row'}>
            <LogoIcon size={'2.6rem'} fill={Defaults.mainColor} />
            <LogoText className={'adm-logo-text'} />
            <div className={'adm-mark-box'} onClick={this.handlePageChange('admin')}>
              <span>ADM</span>
            </div>
            <div className={'vl'}>|</div>
          </div>
          <div className={'adm-menu-items'}>
            {items.map((item: MenuItem, n) => (
              <div
                className={`adm-menu-item ${cl(
                  item.title === currentPageName,
                  'active',
                )}`}
                key={n}
                onClick={this.handlePageChange(item.title)}>
                {renderIcon(item.icon)}
                <span>
                  <Txt k={`admin.${item.title}.title`} />
                </span>
              </div>
            ))}
          </div>

          <div className={'adm-menu-footer'}>
            <div className={'crypto-switcher'} onClick={this.onCryptoSwitcherChange}>
              <div className={`slider position-${cryptoIndex}`}>
                <div />
              </div>
              <div className={'labels'}>
                {Cryptos.map((symbol, n) => (
                  <span key={n} className={cl(symbol === crypto, 'enabled')}>
                    {symbol}
                  </span>
                ))}
              </div>
            </div>

            <div
              className={`adm-settings-button ${cl(
                currentPageName === 'settings',
                'active',
              )}`}
              title={t('admin.settings.title')}
              onClick={this.handlePageChange('settings')}>
              <SettingsIcon />
            </div>
          </div>
        </div>
        {!getAdminAuthToken() && (
          <LoginPanel
            nickname={nickname}
            onChangeNickname={this.onChangeField('nickname')}
            password={password}
            onChangePassword={this.onChangeField('password')}
            code={code}
            codeRequired={codeRequired}
            onChangeCode={this.onChangeField('code')}
            onPerform={this.adminLogin}
          />
        )}

        <Router history={history}>
          <Switch>
            <Route exact path={'/admin/deals'} component={Deals} />
            <Route exact path={'/admin/deals/:id'} component={DealPage} />
            <Route exact path={'/admin/users'} component={Users} />
            <Route exact path={'/admin/users/:nickname'} component={User} />
            <Route exact path={'/admin/users/:nickname/chat'} component={UserChat} />
            <Route
              exact
              path={'/admin/users/:nickname/transactions'}
              component={UserTransactions}
            />
            <Route exact path={'/admin/users/:nickname/deals'} component={UserDeals} />
            <Route exact path={'/admin/users/:nickname/bids'} component={UserBids} />
            <Route
              exact
              path={'/admin/users/:nickname/promocodes'}
              component={UserPromocodes}
            />
            <Route
              exact
              path={'/admin/users/:nickname/login-data'}
              component={UserLoginData}
            />
            <Route
              exact
              path={'/admin/merchants/:id/:name/statistics'}
              component={MerchantStatistics}
            />
            <Route
              exact
              path={'/admin/merchants/:id/:name/conversion'}
              component={MerchantConversion}
            />
            <Route
              exact
              path={'/admin/autotraders/:id/statistics'}
              component={AutotraderStatistics}
            />
            <Route
              exact
              path={'/admin/users/:nickname/transit'}
              component={UserTransit}
            />
            <Route exact path={'/admin/promocodes'} component={Promocodes} />
            <Route exact path={'/admin/bids'} component={Bids} />
            <Route exact path={'/admin/autotraders'} component={Autotraders} />
            <Route exact path={'/admin/bids/:id'} component={BidPage} />
            <Route exact path={'/admin/transactions'} component={Transactions} />
            <Route exact path={'/admin/profit'} component={Profit} />
            <Route exact path={'/admin/api'} component={API} />
            <Route exact path={'/admin/merchants/:id/:name'} component={Merchant} />
            <Route exact path={'/admin/settings'} component={Settings} />
            <Route exact path={'/admin/brokers'} component={Brokers} />
            <Route exact path={'/admin/currencies'} component={Currencies} />
          </Switch>
        </Router>
      </div>
    );
  }
}

const LoginPanel = ({
  nickname,
  password,
  code,
  codeRequired,
  onChangeNickname,
  onChangePassword,
  onChangeCode,
  onPerform,
}: {
  nickname?;
  password?;
  code?: string;
  codeRequired: boolean;
  onChangeNickname;
  onChangePassword;
  onChangeCode: (value: string) => void;
  onPerform: () => void;
}) => (
  <div className={'adm-page'}>
    <div className={'adm-page-header'}>
      <div className={'adm-page-title'}>
        <Txt k={'admin.login.title'} />
      </div>
    </div>
    <div className={'adm-page-content'}>
      <div className={'adm-input-group'}>
        <AdmInput
          type={'text'}
          name={'login'}
          label={'admin.login.nickname'}
          value={nickname}
          onChange={onChangeNickname}
        />
        <AdmInput
          type={'password'}
          label={'admin.login.password'}
          value={password}
          onChange={onChangePassword}
        />
        {codeRequired ? (
          <AdmInput
            type={'text'}
            label={'admin.login.code'}
            value={code}
            onChange={onChangeCode}
          />
        ) : undefined}
        <div
          className={`adm-action-button login ${cl(
            !nickname || !password || (codeRequired && !code),
            'disabled',
          )}`}
          onClick={onPerform}>
          <Txt k={'admin.login.perform'} />
        </div>
      </div>
    </div>
  </div>
);
