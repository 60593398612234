import React from 'react';
import { RouteComponentProps } from 'react-router';

import { MONTHS, OP_TYPES, OP_TYPES_FILTER } from '../../helpers/utils';
import { api } from '../../utils/api';
import { CommonRequestParams } from '../../utils/types';
import { Row } from '../common';
import ButtonEx from '../controls/ButtonEx';
import DatePickerEx from '../controls/DatePickerEx';
import InputEx from '../controls/InputEx';
import Modal from '../controls/Modal';
import SelectEx from '../controls/SelectEx';
import { AdmInput, AdmInputRow, AdmSelect, AdmTablePopup, AdmText } from './admin-common';
import PageLayout from './PageLayout';

export interface IMerchantConversion {
  day?: string;
  conversion_rate: number;
  successful: number;
  unsuccessful: number;
}

interface State {
  opType: string;
  month: number;
  year: number;
  isModalOpen: boolean;
}

export const columns = [
  {
    id: 'day',
    caption: 'admin.merchant-conversion.table.day',
    minWidth: '5rem',
    noSorting: true,
  },
  {
    id: 'conversion_rate',
    caption: 'admin.merchant-conversion.table.conversion',
    noSorting: true,
    render: (value) => `${value ?? 0}%`,
  },
  {
    id: 'successful',
    caption: 'admin.merchant-conversion.table.successful',
    noSorting: true,
    render: (value) => `${value}`,
  },
  {
    id: 'unsuccessful',
    caption: 'admin.merchant-conversion.table.unsuccessful',
    noSorting: true,
    render: (value) => `${value}`,
  },
];

export default class MerchantConversion extends React.Component<
  RouteComponentProps<{ id: string; name: string }>
> {
  public state: State = {
    opType: 'pay',
    year: new Date().getFullYear(),
    month: new Date().getMonth(),
    isModalOpen: false,
  };

  handleFilterChange = (opType: string, request, resetRows): void => {
    this.setState({ opType }, () => {
      resetRows();
      request(true, undefined, { opType });
    });
  };

  handleMonthChange = (value: number, request, resetRows) => {
    this.setState({ month: value }, () => {
      const { year, month } = this.state;
      resetRows();
      if (year >= 2018)
        request(true, undefined, {
          month: new Date(year, month, 1, 23, 59).getTime(),
        });
    });
  };

  handleYearChange = (value: number, request, resetRows) => {
    this.setState({ year: value }, () => {
      const { year, month } = this.state;
      resetRows();
      if (year >= 2018)
        request(true, undefined, {
          month: new Date(year, month, 1, 23, 59).getTime(),
        });
    });
  };

  getMerchantStatistics =
    (id: string) =>
    (params: CommonRequestParams): Promise<IMerchantConversion[]> => {
      return new Promise((resolve) => {
        api.admin
          .merchantConversionData({
            ...params,
            merchant_id: id,
            opType: this.state.opType,
            month: new Date(this.state.year, this.state.month, 1, 23, 59).getTime(),
          })
          .then((result) => {
            const statistics: IMerchantConversion[] = [];
            for (const p of result) {
              statistics.push({
                day: p.day,
                successful: p.successful,
                conversion_rate: p.conversion_rate,
                unsuccessful: p.unsuccessful,
              });
            }
            resolve(statistics);
          });
      });
    };

  render(): React.ReactNode {
    const { id, name } = this.props.match.params;
    const { opType, year, month } = this.state;

    return (
      <PageLayout<IMerchantConversion>
        request={this.getMerchantStatistics(id)}
        headerTitle={'admin.merchant-conversion.title'}
        defaultSortBy={'day'}
        tableColumns={columns}
        isSubtitle
        isReturnButton
        showMoreDisabled
        subtitleArgs={[name]}
        additional={{
          component: (request, resetRows) => (
            <Row>
              <AdmText
                k={`${OP_TYPES_FILTER.find((item) => item.id === opType)?.value || ''}`}
                left={'1rem'}
              />
              <AdmTablePopup className={'adm-transaction-actions'}>
                {OP_TYPES_FILTER.map((n) => (
                  <div
                    className={'adm-button delete-transaction select-transaction'}
                    onClick={() => this.handleFilterChange(n.id, request, resetRows)}
                    key={n.id}>
                    <span style={{ color: n.id === opType ? 'white' : 'inherit' }}>
                      <AdmText k={`${n.value}`} left={'1rem'} />
                    </span>
                  </div>
                ))}
              </AdmTablePopup>
            </Row>
          ),
          contentComponent: (request, resetRows) => (
            <Row padding={'0 0 16px 0'}>
              <AdmInputRow
                type="number"
                label={'Год'}
                onChange={(value) => this.handleYearChange(value, request, resetRows)}
                value={year}
              />
              <AdmSelect
                label="Месяц"
                options={MONTHS}
                backgroundColor={'white'}
                onChange={(value) => this.handleMonthChange(value, request, resetRows)}
                id={month.toString()}
                height={'100%'}
              />
            </Row>
          ),
        }}
      />
    );
  }
}
