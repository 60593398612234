import React from 'react';
import { RouteComponentProps } from 'react-router';

import PageLayout from './PageLayout';
import {
  deleteTransaction,
  getColumns,
  getTransactions,
  restartTransaction,
  Transaction,
} from './Transactions';

export default class UserTransactions extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  render(): React.ReactNode {
    const { nickname } = this.props.match.params;

    return (
      <PageLayout<Transaction>
        request={getTransactions}
        user={nickname}
        headerTitle={'admin.user-transactions.title'}
        searchInputHint={'admin.transactions.search-transaction'}
        defaultSortBy={'createdAt'}
        tableColumns={getColumns(restartTransaction, deleteTransaction)}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[nickname]}
      />
    );
  }
}
