import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import {
  IMessageRecieveData,
  IMessageRecieveParams,
  IMessageSendData,
  IMessageSendParams,
  ISendFeedbackData,
  ISendFeedbackParams,
} from '../domain/services/UserMessageServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class UserMessagesService {
  static sendFeedback = async (
    params: ISendFeedbackParams,
    onSuccess?: ISuccessCallbackType<ISendFeedbackData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ISendFeedbackData>({
      url: '/user-messages/feedback',
      method: 'POST',
      data: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static receive = async (
    params: IMessageRecieveParams,
    onSuccess?: ISuccessCallbackType<IMessageRecieveData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMessageRecieveData>({
      url: '/user-messages',
      headers: getAuthHeader(params.token),
      params: { with_user: params.user },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static send = async (
    params: IMessageSendParams,
    onSuccess?: ISuccessCallbackType<IMessageSendData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IMessageSendData>({
      url: '/user-messages',
      method: 'POST',
      headers: getAuthHeader(params.token),
      data: params.message,
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
