import axios, { AxiosError, AxiosRequestHeaders, Method } from 'axios';
import axiosRetry from 'axios-retry';
import { Md5 } from 'ts-md5';

import { tt } from '../config/i18n';
import { IRequestCallbacks } from '../domain/services/common';
import { getAuthHeader } from '../helpers/authHeader';
import { showMessage } from '../helpers/notifications';
import { API_URL } from '../helpers/settings';
import { AxiosErrorHandlers } from './axiosErrorHandlers';
import { isCsv, isJson } from './axiosHelpers';

interface IGeneralRequestData {
  [index: string]: any;
}

interface IGeneralRequestParams {
  [index: string]: any;
}

interface IGeneralRequestConfig<T> extends IRequestCallbacks<T> {
  url: string;
  method?: Method;
  data?: IGeneralRequestData;
  params?: IGeneralRequestParams;
  headers?: 'none' | AxiosRequestHeaders;
}

type ISecretRefreshSubscribersCallback = (secret: string) => void;

let Secret;
let isRefreshingSecret = false;
let banned = false;
let refreshSubscribers: ISecretRefreshSubscribersCallback[] = [];
const ignoredErrorCodes = [412, 401];
const ignoredRetryCodes = [400, 403];

const client = axios.create({ baseURL: `${API_URL}/rest/v1` });

client.interceptors.request.use((config) => {
  if (banned) throw new axios.Cancel('Banned');
  return config;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 412) {
      if (!isRefreshingSecret) {
        isRefreshingSecret = true;
        getCodeData()
          .then((codeData) => {
            isRefreshingSecret = false;
            const needReverse = codeData['aKM'] === 'e';
            const md5 = new Md5();
            let codedataKeys = Object.keys(codeData).sort();
            needReverse && (codedataKeys = codedataKeys.reverse());
            const codedataValues = codedataKeys.map((key) => codeData[key]);
            const result = codedataValues.join('') + 'l';
            Secret = md5.appendStr(result).end() as string;
            onSecretRefreshed(Secret);
            refreshSubscribers = [];
          })
          .catch(() => (isRefreshingSecret = false));
      }

      return new Promise((resolve) => {
        subscribeSecretRefresh((secret) => {
          originalRequest.headers['AuthKey'] = secret;
          resolve(axios(originalRequest));
        });
      });
    } else {
      return Promise.reject(error);
    }
  },
);

const subscribeSecretRefresh = (cb: ISecretRefreshSubscribersCallback) => {
  refreshSubscribers.push(cb);
};

const onSecretRefreshed = (secret: string) => {
  refreshSubscribers.map((cb: ISecretRefreshSubscribersCallback) => cb(secret));
};

axiosRetry(client, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error: any) => {
    AxiosErrorHandlers[`${error.response?.status}`]
      ? AxiosErrorHandlers[`${error.response?.status}`](error)
      : AxiosErrorHandlers.default(error);

    if (error.response?.data.detail === 'User is baned') banned = true;

    return error.response
      ? !ignoredErrorCodes.includes(error.response.status) &&
          !ignoredRetryCodes.includes(error.response.status)
      : axiosRetry.isRetryableError(error);
  },
});

export const getCodeData = (): Promise<any> => generalRequest({ url: '/codedata' });

export const generalRequest = async <T>(config: IGeneralRequestConfig<T>): Promise<T> => {
  const { successCallback, errorCallback } = config;
  let { headers = getAuthHeader() } = config;

  if (headers === 'none') headers = {};

  Secret && (headers['AuthKey'] = Secret);

  if (!Object.prototype.hasOwnProperty.call(headers, 'Content-Type')) {
    headers['Content-Type'] = 'application/json;charset=utf-8';
  }
  if (headers['Content-Type'] === '') delete headers['Content-Type'];
  headers['Access-Control-Allow-Origin'] = '*';

  const onSuccess = (response) => {
    const { data } = response;

    let formedData;

    if (isCsv(response)) formedData = new Blob([data]);
    else if (isJson(response)) formedData = data;
    else formedData = JSON.stringify(data);

    successCallback?.(formedData);
    return formedData;
  };

  const onError = async (error: AxiosError<any>) => {
    // 'currently not available' - handled in PageFastDeal component
    if (
      error?.response?.data?.detail !== 'currently not available' &&
      !ignoredErrorCodes.includes(error?.response?.status ?? 0)
    )
      showMessage(
        'error',
        error.response?.data?.detail || error?.response?.statusText || '',
      );
    errorCallback?.(error);

    return Promise.reject(error.response);
  };

  return client({
    url: config.url,
    method: config.method ?? 'GET',
    params: config.params,
    data: config.data,
    headers: headers,
  })
    .then(onSuccess)
    .catch(onError);
};
