import _ from 'lodash';

import { IErrorCallbackType, ISuccessCallbackType } from '../domain/services/common';
import {
  IChangeCurrencyData,
  IChangeCurrencyParams,
  IChangePasswordData,
  IChangePasswordParams,
  ICheckTokenData,
  IDealsVoteUserData,
  IDealsVoteUserParams,
  IDisableMfaData,
  IFastDealsVoteUserData,
  IFastDealsVoteUserParams,
  IGetOtpAuthData,
  IJoinAccountConfirmData,
  IJoinAccountConfirmParams,
  IJoinAccountData,
  IJoinAccountParams,
  IJoinAccountStatusData,
  IProfileData,
  IProfileParams,
  IWalletsData,
} from '../domain/services/UsersServiceEntities';
import { getAuthHeader } from '../helpers/authHeader';
import { getCurrentUser } from '../helpers/settings';
import { generalRequest } from '../utils/axiosSetup';

export default abstract class UsersService {
  // OK
  static wallets = async (
    onSuccess?: ISuccessCallbackType<IWalletsData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IWalletsData>({
      url: '/users/wallets',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static checkToken = async (
    onSuccess?: ISuccessCallbackType<ICheckTokenData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<ICheckTokenData>({
      url: '/users/wallets',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static profile = async (
    params: IProfileParams,
    onSuccess?: ISuccessCallbackType<IProfileData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IProfileData>({
      url: `/users/${params.id}`,
      data: _.omit(params, 'id'),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static disableMfa = async (
    onSuccess?: ISuccessCallbackType<IDisableMfaData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDisableMfaData>({
      url: `/users/${getCurrentUser()}`,
      method: 'PATCH',
      data: { is_mfa_enabled: false },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static changePassword = async (
    params: IChangePasswordParams,
    onSuccess?: ISuccessCallbackType<IChangePasswordData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IChangePasswordData>({
      url: '/users/password',
      method: 'PATCH',
      data: { old: params.oldPassword, new: params.newPassword },
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static getOtpAuth = async (
    onSuccess?: ISuccessCallbackType<IGetOtpAuthData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IGetOtpAuthData>({
      url: '/users/user-otp-auth',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static joinAccount = async (
    params: IJoinAccountParams,
    onSuccess?: ISuccessCallbackType<IJoinAccountData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IJoinAccountData>({
      url: '/users/accounts-join',
      method: 'POST',
      data: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  static joinAccountConfirm = async (
    params: IJoinAccountConfirmParams,
    onSuccess?: ISuccessCallbackType<IJoinAccountConfirmData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IJoinAccountConfirmData>({
      url: '/users/confirmation-accounts-join',
      method: 'POST',
      data: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static joinAccountStatus = async (
    onSuccess?: ISuccessCallbackType<IJoinAccountStatusData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IJoinAccountStatusData>({
      url: '/users/accounts-join-status',
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static changeCurrency = async (
    params: IChangeCurrencyParams,
    onSuccess?: ISuccessCallbackType<IChangeCurrencyData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IChangeCurrencyData>({
      url: `/users/${getCurrentUser()}`,
      method: 'PATCH',
      data: params,
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static dealVoteUser = async (
    params: IDealsVoteUserParams,
    onSuccess?: ISuccessCallbackType<IDealsVoteUserData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IDealsVoteUserData>({
      url: '/users/vote',
      method: 'POST',
      data: _.omit({ ...params, deal_id: params.dealId }, 'dealId'),
      successCallback: onSuccess,
      errorCallback: onError,
    });

  // OK
  static fastDealVoteUser = async (
    params: IFastDealsVoteUserParams,
    onSuccess?: ISuccessCallbackType<IFastDealsVoteUserData>,
    onError?: IErrorCallbackType,
  ) =>
    generalRequest<IFastDealsVoteUserData>({
      url: '/users/vote',
      method: 'POST',
      headers: getAuthHeader(params.token),
      data: _.omit({ ...params, deal_id: params.dealId }, ['dealId', 'token']),
      successCallback: onSuccess,
      errorCallback: onError,
    });
}
