import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { t } from '../../config/i18n';
import { formatString } from '../../helpers/utils';
import { api } from '../../utils/api';
import {
  AdmAutotrader,
  AdmBroker,
  BrokerCurrencies as BrokerCurrenciesResponse,
  CommonRequestParams,
} from '../../utils/types';
import { Row } from '../common';
import { AttachBox, AttachmentButton } from '../controls/Chat';
import SelectEx from '../controls/SelectEx';
import Txt from '../controls/Txt';
import { fiatCurrencies, getFiatCurrencies } from '../currencies';
import { AdmSwitcherLabeled, AdmTablePopup } from './admin-common';
import { Autotrader } from './Autotraders';
import PageLayout from './PageLayout';

type AutotraderSelectData = {
  id: string;
  value: string;
};

type BrokerCurrency = {
  symbol: string;
  isLinked: boolean;
  rateVariation: number | string;
  autotraderId: string | number;
  allowFlashPay: boolean;
  saleV2Rate: number;
  allowSkyPayAutotrader: boolean;
};
type BrokerCurrencies = Record<string, BrokerCurrency>;
interface Broker extends AdmBroker {
  id: string;
  name: string;
  currenciesRates: BrokerCurrencies;
}
interface State {
  tempCurrencies: BrokerCurrencies;
  autotraders: AutotraderSelectData[];
}

export default class Brokers extends React.Component {
  public state: State = {
    tempCurrencies: {},
    autotraders: [],
  };

  showInput = (): string => {
    let name = prompt(t('admin.brokers.prompt-name'));
    if (!name) {
      return '';
    }
    name = name.trim();
    if (name.length === 0) {
      return '';
    }
    return name;
  };

  addBroker = () => {
    const name = this.showInput();
    if (name.length === 0) return;
    api.admin
      .createBroker(name)
      .then(() => {
        window.location.reload();
      })
      .catch(() => undefined);
  };

  onChangeCurrencyStatus = (checked: boolean, c: string) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].isLinked = checked;
    tempCurrenciesCopy[c].allowFlashPay = false;
    tempCurrenciesCopy[c].saleV2Rate = 97;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  onChangeCurrencyFlashPayStatus = (checked: boolean, c: string) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].allowFlashPay = checked;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  onChangeCurrencySkyPayStatus = (checked: boolean, c: string) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].allowSkyPayAutotrader = checked;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  onChangeCurrencyRate = (c: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].rateVariation = event.target.value;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  onChangeCurrencySaleRate = (c: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].saleV2Rate = event.target.value;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  onChangeCurrencyAutotrader = (c: string) => (id: string) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    tempCurrenciesCopy[c].autotraderId = id;

    this.setState({ tempCurrencies: tempCurrenciesCopy });
  };

  deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

  onSaveCurrencies = (
    id: string,
    brokerCurrencies: string[],
    rows: Broker[],
    setRows: (data: Broker[]) => void,
  ) => {
    const tempCurrenciesCopy = this.deepCopy(this.state.tempCurrencies);
    const tempCurrenciesStatus = this.deepCopy(this.state.tempCurrencies);
    const requestData = [] as Array<BrokerCurrenciesResponse>;
    for (const key in tempCurrenciesCopy) {
      if (!tempCurrenciesStatus[key].isLinked) {
        delete tempCurrenciesStatus[key];
      } else if (
        brokerCurrencies[key].allowFlashPay !== tempCurrenciesStatus[key].allowFlashPay ||
        brokerCurrencies[key].rateVariation !== tempCurrenciesStatus[key].rateVariation ||
        brokerCurrencies[key].autotraderId !== tempCurrenciesStatus[key].autotraderId ||
        brokerCurrencies[key].saleV2Rate !== tempCurrenciesStatus[key].saleV2Rate ||
        (!brokerCurrencies[key].isLinked && tempCurrenciesStatus[key].isLinked) ||
        brokerCurrencies[key].allowSkyPayAutotrader !==
          tempCurrenciesStatus[key].allowSkyPayAutotrader
      ) {
        requestData.push({
          currency: tempCurrenciesStatus[key].symbol,
          rate_variation: tempCurrenciesStatus[key].rateVariation,
          allow_flash_pay: tempCurrenciesStatus[key].allowFlashPay,
          autotrader_id: !tempCurrenciesStatus[key].autotraderId
            ? null
            : tempCurrenciesStatus[key].autotraderId,
          allow_sky_pay_autotrader: tempCurrenciesStatus[key].allowSkyPayAutotrader,
          sale_v2_rate: tempCurrenciesStatus[key].saleV2Rate,
        });
      }
      if (!tempCurrenciesCopy[key].isLinked) {
        tempCurrenciesCopy[key].rateVariation = 15;
        tempCurrenciesCopy[key].allowFlashPay = false;
        tempCurrenciesCopy[key].saleV2Rate = 97;
      }
    }

    if (JSON.stringify(tempCurrenciesCopy) !== JSON.stringify(brokerCurrencies)) {
      api.admin
        .updateBroker(id, { currencies: Object.keys(tempCurrenciesStatus) })
        .then(() => {
          requestData.map((item) => {
            api.admin.updateBrokerCurrencyRateVariation(
              item.currency,
              id,
              +item.rate_variation / 100,
              +item.autotrader_id,
              item.allow_flash_pay,
              +item.sale_v2_rate,
              item.allow_sky_pay_autotrader,
            );
          });
          this.changeBrokerValue(id, 'currencies', tempCurrenciesCopy, rows, setRows);
        });
    }
  };

  deleteBroker = (id: string, name: string) => () => {
    if (window.confirm(formatString(t('admin.brokers.confirm-deletion'), name))) {
      api.admin
        .deleteBroker(id)
        .then(() => window.location.reload())
        .catch(() => undefined);
    }
  };

  saveAutotraders = (autotraders: Autotrader) => {
    this.setState({ autotraders });
  };

  changeBankEnabled =
    (
      id: string,
      key: string,
      value: boolean,
      rows: Broker[],
      setRows: (data: Broker[]) => void,
    ) =>
    () => {
      api.admin
        .changeBrokerStatus(id, key, value)
        .then(() => {
          this.changeBrokerValue(id, key, value, rows, setRows);
        })
        .catch(() => undefined);
    };

  changeBrokerValue = (
    id: string,
    key: string,
    value: boolean | string | number | string[],
    rows: Broker[],
    setRows: (data: Broker[]) => void,
  ) => {
    const newData = [...rows];
    const brokerIndex = newData.findIndex(({ id: brokerId }) => brokerId === id);
    if (key === 'currencies') {
      const tempCurrenciesArray = Object.values(
        this.deepCopy(this.state.tempCurrencies),
      ) as Array<BrokerCurrency>;

      const currenciesArray = tempCurrenciesArray
        .filter(({ isLinked }) => isLinked)
        .map((item) => ({
          currency: item.symbol,
          rate_variation: +item.rateVariation / 100,
          autotrader_id: item.autotraderId,
          allow_flash_pay: item.allowFlashPay,
          sale_v2_rate: item.saleV2Rate,
          allow_sky_pay_autotrader: item.allowSkyPayAutotrader,
        }));
      newData[brokerIndex][key] = currenciesArray;
      newData[brokerIndex]['currenciesRates'] = this.state.tempCurrencies;
    } else {
      newData[brokerIndex][key] = value;
    }
    setRows(newData);
  };

  generateBooleanColumn = (
    brokerPropName: string,
    translationPath: string,
    feminine = false,
  ) => ({
    id: brokerPropName,
    caption: `admin.brokers.table.${translationPath}`,
    render: (isActive, row, _, rows, setRows) => (
      <>
        <span className={`color-${isActive ? 'true' : 'false'}`}>&bull;&nbsp;</span>
        <Txt
          k={`admin.brokers.table.status-${isActive ? 'enabled' : 'disabled'}${
            feminine ? '-feminine' : ''
          }`}
        />
        <BankEnableBox
          isActive={isActive}
          onClick={this.changeBankEnabled(
            row.id,
            brokerPropName,
            !isActive,
            rows,
            setRows,
          )}
        />
      </>
    ),
  });

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  changeLogo =
    (id: string, rows: Broker[], setRows: (data: Broker[]) => void) =>
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target || !e.target.files || e.target.files.length === 0) {
        return;
      }
      const base64 = await this.toBase64(e.target.files[0]);

      api.admin
        .updateBroker(id, { logo: base64 as string })
        .then(() => this.changeBrokerValue(id, 'logo', base64 as string, rows, setRows));
    };

  render(): React.ReactNode {
    const { tempCurrencies, autotraders } = this.state;

    return (
      <PageLayout<Broker>
        request={getBrokers}
        headerTitle={'admin.brokers.title'}
        searchInputHint={'admin.brokers.search-bank'}
        defaultSortBy={'name'}
        reverseSortingOrder={true}
        additional={{
          contentComponent: () => (
            <button className={'adm-page-button'} onClick={() => this.addBroker()}>
              <span>
                <Txt k={'admin.brokers.actions.add'} />
              </span>
            </button>
          ),
          functions: { saveAutotraders: this.saveAutotraders },
        }}
        tableColumns={[
          {
            id: 'logo',
            noSorting: true,
            caption: 'admin.brokers.table.logo',
            width: '20%',
            render: (logoBase64, row, _, rows, setRows) => (
              <>
                {logoBase64 && <img width={50} src={logoBase64} alt={'logo'} />}
                <BankLogoActionsBox
                  onChangeLogo={this.changeLogo(row.id, rows, setRows)}
                />
              </>
            ),
          },
          {
            id: 'name',
            caption: 'admin.brokers.table.name',
            width: '20%',
            render: (name, row) => (
              <>
                {name}
                <BankActionsBox onDelete={this.deleteBroker(row.id, name)} />
              </>
            ),
          },
          {
            id: 'currenciesRates',
            noSorting: true,
            caption: 'Привязанная валюта',
            render: (currenciesRates, row, _, rows, setRows) => {
              const currenciesRatesArray = Object.values(
                currenciesRates,
              ) as Array<BrokerCurrency>;
              return (
                <>
                  {currenciesRatesArray
                    .filter(({ isLinked }) => isLinked)
                    .map(({ symbol }) => symbol)
                    .join(',')
                    .toUpperCase()}
                  <AdmTablePopup
                    className={'adm-broker-actions'}
                    onOpen={() =>
                      this.setState({
                        tempCurrencies: this.deepCopy(currenciesRates),
                      })
                    }>
                    <CurrenciesWrapper>
                      {fiatCurrencies.map((c) => (
                        <Row key={`Switcher ${c}`}>
                          <Row width={120}>
                            <AdmSwitcherLabeled
                              value={tempCurrencies[c.toLowerCase()]?.isLinked}
                              label={c}
                              onChange={(checked) =>
                                this.onChangeCurrencyStatus(checked, c.toLowerCase())
                              }
                              className={'adm-user-verified ' + c}
                            />
                          </Row>
                          <Row>
                            <input
                              disabled={!tempCurrencies[c.toLowerCase()]?.isLinked}
                              type={'number'}
                              style={{ width: '50px' }}
                              value={tempCurrencies[c.toLowerCase()]?.rateVariation}
                              onChange={this.onChangeCurrencyRate(c.toLowerCase())}
                              onWheel={() => document.activeElement?.['blur']()}
                            />
                            <span className={'percent'}>&nbsp;%</span>
                          </Row>
                          <Row>
                            <input
                              disabled={!tempCurrencies[c.toLowerCase()]?.isLinked}
                              type={'number'}
                              style={{ width: '50px' }}
                              value={tempCurrencies[c.toLowerCase()]?.saleV2Rate}
                              onChange={this.onChangeCurrencySaleRate(c.toLowerCase())}
                              onWheel={() => document.activeElement?.['blur']()}
                            />
                            <span className={'percent'}>&nbsp;(S2)</span>
                          </Row>
                          {!!autotraders.length && (
                            <Row left={32}>
                              <SelectEx
                                disabled={!tempCurrencies[c.toLowerCase()]?.isLinked}
                                height={'3rem'}
                                minWidth={'10rem'}
                                arrowColor={'#828891'}
                                fontColor={'white'}
                                hoverColor={
                                  !tempCurrencies[c.toLowerCase()]?.isLinked
                                    ? '#2f2f2f'
                                    : '#616161'
                                }
                                backgroundColor={
                                  !tempCurrencies[c.toLowerCase()]?.isLinked
                                    ? '#2f2f2f'
                                    : '#4c4c4c'
                                }
                                borderColor={'unset'}
                                id={
                                  !tempCurrencies[c.toLowerCase()]?.autotraderId
                                    ? 'null'
                                    : tempCurrencies[
                                        c.toLowerCase()
                                      ]?.autotraderId.toString()
                                }
                                options={autotraders}
                                onChange={this.onChangeCurrencyAutotrader(
                                  c.toLowerCase(),
                                )}
                              />
                            </Row>
                          )}
                          <Row left={32} width={140}>
                            <AdmSwitcherLabeled
                              disabled={!tempCurrencies[c.toLowerCase()]?.isLinked}
                              labelPosition="left"
                              value={tempCurrencies[c.toLowerCase()]?.allowFlashPay}
                              label={t('admin.brokers.flash-pay')}
                              onChange={(checked) =>
                                this.onChangeCurrencyFlashPayStatus(
                                  checked,
                                  c.toLowerCase(),
                                )
                              }
                              className={'adm-user-verified ' + c}
                            />
                          </Row>
                          <Row left={32} width={140}>
                            <AdmSwitcherLabeled
                              disabled={!tempCurrencies[c.toLowerCase()]?.isLinked}
                              labelPosition="left"
                              value={
                                tempCurrencies[c.toLowerCase()]?.allowSkyPayAutotrader
                              }
                              label={t('admin.brokers.sky-pay')}
                              onChange={(checked) =>
                                this.onChangeCurrencySkyPayStatus(
                                  checked,
                                  c.toLowerCase(),
                                )
                              }
                              className={'adm-user-verified ' + c}
                            />
                          </Row>
                        </Row>
                      ))}
                    </CurrenciesWrapper>

                    <div
                      className={'adm-button broker-action'}
                      onClick={() =>
                        this.onSaveCurrencies(row.id, currenciesRates, rows, setRows)
                      }>
                      <span>
                        <Txt
                          k={formatString(t('admin.brokers.actions.saveCurrency')) || ''}
                        />
                      </span>
                    </div>
                  </AdmTablePopup>
                </>
              );
            },
          },
          this.generateBooleanColumn('sky_pay', 'sky-pay-and-sale-status'),
          this.generateBooleanColumn('sale_v2', 'sky-sale-v2-status'),
          this.generateBooleanColumn('is_card', 'is-card', true),
          this.generateBooleanColumn('fast_deal', 'fast-deal'),
        ]}
      />
    );
  }
}

const BankActionsBox = ({ onDelete }: { onDelete: () => void }) => (
  <AdmTablePopup className={'adm-broker-actions'}>
    <div className={'adm-button broker-action'} onClick={onDelete}>
      <span>
        <Txt k={'admin.brokers.actions.delete'} />
      </span>
    </div>
  </AdmTablePopup>
);

const BankLogoActionsBox = ({
  onChangeLogo,
}: {
  onChangeLogo: (e: ChangeEvent<HTMLInputElement>) => void;
}) => (
  <AdmTablePopup className={'adm-broker-actions'}>
    <div className={'broker-load-logo'}>
      <AttachBox>
        <AttachmentButton size={'1.5rem'} hint={t('chat.attachments-hint')} />
        <input type={'file'} accept={'image/*'} onChange={onChangeLogo} />
      </AttachBox>
    </div>
  </AdmTablePopup>
);

const BankEnableBox = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => (
  <AdmTablePopup className={'adm-broker-actions'}>
    <div className={'adm-button broker-action'} onClick={onClick}>
      <span>
        <Txt k={`admin.brokers.actions.${isActive ? 'disable' : 'enable'}`} />
      </span>
    </div>
  </AdmTablePopup>
);

const CurrenciesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
`;

function getBrokers(
  params: CommonRequestParams,
  additionalFunctions?: Record<string, any>,
): Promise<Broker[]> {
  return new Promise((resolve) => {
    Promise.all([
      api.admin.getBrokers({ ...params }),
      api.admin.autotraders({ offset: 0, limit: 20, ordering: '-name' }),
      getFiatCurrencies(),
    ])
      .then(([brokers, traders]) => {
        const brokerCurrencies: BrokerCurrencies = fiatCurrencies.reduce((acc, item) => {
          acc[item.toLowerCase()] = {
            symbol: item.toLowerCase(),
            isLinked: false,
            rateVariation: 15,
            saleV2Rate: 97,
          };
          return acc;
        }, {});
        resolve(
          brokers.map((broker) => ({
            ...broker,
            currenciesRates: broker.currencies.reduce(
              (acc, item) => {
                acc[item.currency] = {
                  symbol: item.currency,
                  allowFlashPay: item.allow_flash_pay,
                  isLinked: true,
                  rateVariation: +(item.rate_variation * 100).toFixed(6),
                  autotraderId: item.autotrader_id,
                  allowSkyPayAutotrader: item.allow_sky_pay_autotrader,
                  saleV2Rate: item.sale_v2_rate,
                };
                return acc;
              },
              { ...brokerCurrencies },
            ),
          })),
        );
        const autotraders: AutotraderSelectData[] = [];
        autotraders.push({ id: 'null', value: 'no autotrader' });
        for (const autotrader of traders) {
          autotraders.push(assignAutotrader(autotrader));
        }
        additionalFunctions && additionalFunctions.saveAutotraders(autotraders);
      })
      .catch(() => resolve([]));
  });
}

function assignAutotrader(trader: AdmAutotrader): AutotraderSelectData {
  return {
    id: trader.id.toString(),
    value: trader.name,
  };
}
