import React from 'react';

const setAuthorization = (token?: string) => {
  //
};

const openLoginDialog = (email?: string) => {
  //
};

export type TokenListener = (token: string) => boolean;
export type OpenLoginDialog2 = (email?: string, onLogin?: TokenListener) => void;

const openLoginDialog2: OpenLoginDialog2 = (email?: string, onLogin?: TokenListener) => {
  //
};

const token: string | undefined = '';

export const AuthContext = React.createContext({
  isAuthorized: false,
  token,
  setAuthorization,
  openLoginDialog,
  openLoginDialog2,
});
