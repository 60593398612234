import React from 'react';

import { getRowsPerPage } from '../../helpers/settings';
import {
  cl,
  formatCrypto,
  formatDateTime,
  formatNumber,
  parseDate,
} from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmDeal, CommonRequestParams } from '../../utils/types';
import Txt from '../controls/Txt';
import { Cryptos } from '../currencies';
import { AdmBidLink, AdmDealLink, AdmSwitcher, AdmUserLink } from './admin-common';
import { Deal } from './Deal';
import PageLayout from './PageLayout';

interface State {
  fastDealEnabled: boolean;
  exchangeEnabled: boolean;
  searchDeal?: string;
  rows: Deal[];
  rowsLimit: number;
}

export const FAST_DEAL_ENABLED = 'fast_deal_active';
export const EXCHANGE_ENABLED = 'exchange_active';

export const columns = [
  {
    id: 'createdAt',
    caption: 'admin.deals.table.created-at',
    render: (createdAt) => formatDateTime(createdAt),
    minWidth: '10.4rem',
  },
  {
    id: 'id',
    caption: 'admin.deals.table.id',
    render: (id) => <AdmDealLink dealId={id} />,
  },
  {
    id: 'lot',
    caption: 'admin.deals.table.bid',
    render: (lot) => <AdmBidLink lot={lot} />,
  },
  {
    id: 'amountCurrency',
    caption: 'admin.deals.table.amount-currency',
    render: (amountCurrency, row) =>
      formatNumber(amountCurrency) + ' ' + row.currency.toUpperCase(),
    minWidth: '6rem',
  },
  {
    id: 'amount',
    caption: 'admin.deals.table.amount',
    render: (amount, row) => (
      <>
        {formatCrypto(amount, row.crypto)}&nbsp;{row.crypto}
      </>
    ),
  },
  {
    id: 'state',
    caption: 'admin.deals.table.state',
    render: (state) => <Txt k={`admin.deals.table.state-${state}`} />,
  },
  {
    id: 'endTime',
    caption: 'admin.deals.table.completed-at',
    render: (endTime) => (endTime ? formatDateTime(endTime) : undefined),
    minWidth: '10.1rem',
  },
  {
    id: 'buyer',
    caption: 'admin.deals.table.buyer',
    render: (buyer) => <AdmUserLink nickname={buyer} />,
    width: '9rem',
  },
  {
    id: 'seller',
    caption: 'admin.deals.table.seller',
    render: (seller) => <AdmUserLink nickname={seller} />,
    width: '8rem',
  },
  {
    id: 'commission',
    caption: 'admin.deals.table.commission',
    render: (commission, row) => (
      <>
        {formatCrypto(commission, row.crypto)}&nbsp;{row.crypto}
      </>
    ),
  },
];
export default class Deals extends React.Component {
  public state: State = {
    fastDealEnabled: true,
    exchangeEnabled: true,
    rows: [],
    rowsLimit: getRowsPerPage(),
  };

  enableFastDeal = (fastDealEnabled: boolean) =>
    api.admin
      .updateSettings(FAST_DEAL_ENABLED, String(fastDealEnabled))
      .then(() => this.setState({ fastDealEnabled }))
      .catch(() => undefined);

  toggleExchange = (exchangeEnabled: boolean) => {
    Promise.all(
      Cryptos.map((crypto) =>
        api.admin.updateCryptoSettings(
          crypto.toLowerCase(),
          EXCHANGE_ENABLED,
          String(exchangeEnabled),
        ),
      ),
    ).then(() => this.setState({ exchangeEnabled }));
  };

  handleFastDealStatus = (status) => {
    this.setState({ fastDealEnabled: status });
  };

  handleExchangeStatus = (status) => {
    this.setState({ exchangeEnabled: status });
  };

  render(): React.ReactNode {
    const { fastDealEnabled, exchangeEnabled } = this.state;

    return (
      <PageLayout<Deal>
        request={getDeals}
        headerTitle={'admin.deals.title'}
        searchInputHint={'admin.deals.search-deal'}
        defaultSortBy={'createdAt'}
        additional={{
          component: (
            <div className={'adm-fast-deal-box'}>
              <AdmSwitcher value={fastDealEnabled} onChange={this.enableFastDeal} />
              <span className={cl(fastDealEnabled, 'enabled')}>
                <Txt k={'admin.deals.fast-deal'} />
              </span>
              <AdmSwitcher value={exchangeEnabled} onChange={this.toggleExchange} />
              <span className={cl(exchangeEnabled, 'enabled')}>
                <Txt k={'admin.deals.exchange'} />
              </span>
            </div>
          ),
          functions: {
            handleFastDealStatus: this.handleFastDealStatus,
            handleExchangeStatus: this.handleExchangeStatus,
          },
        }}
        tableColumns={columns}
      />
    );
  }
}

export function getDeals(
  params: CommonRequestParams & { user?: string },
  additionalFunctions?: Record<string, any>,
): Promise<Deal[]> {
  return new Promise((resolve) => {
    Promise.all([
      api.admin.deals({ ...params, symbol: params.symbol?.toLowerCase() }),
      additionalFunctions ? api.admin.settingsValue(FAST_DEAL_ENABLED) : undefined,
      additionalFunctions ? api.admin.getExchangeActive() : undefined,
    ])
      .then(([response, fdEnabled, exEnabled]) => {
        const deals: Deal[] = [];
        for (const deal of response) {
          deals.push(assignDeal(deal));
        }
        resolve(deals);
        console.log(exEnabled);
        if (additionalFunctions) {
          additionalFunctions.handleFastDealStatus(fdEnabled?.value === 'true');
          additionalFunctions.handleExchangeStatus(exEnabled);
        }
      })
      .catch(() => resolve([]));
  });
}

export function assignDeal(deal: AdmDeal): Deal {
  return {
    id: deal.id,
    lot: deal.lot,
    crypto: deal.symbol.toUpperCase(),
    buyer: deal.buyer,
    seller: deal.seller,
    amount: deal.amount,
    amountCurrency: deal.amount_currency,
    commission: deal.seller_commission + deal.buyer_commission || 0,
    commissionBuyer: deal.buyer_commission || 0,
    commissionSeller: deal.seller_commission || 0,
    state: deal.state,
    createdAt: parseDate(deal.created_at),
    endTime: parseDate(deal.end_time),
    requisite: deal.requisite,
    currency: deal.currency,
  };
}
