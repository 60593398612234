import React from 'react';
import { RouteComponentProps } from 'react-router';

import PageLayout from './PageLayout';
import { columns, getPromocodes, Promocode } from './Promocodes';
export default class UserPromocodes extends React.Component<
  RouteComponentProps<{ nickname: string }>
> {
  render(): React.ReactNode {
    const { nickname } = this.props.match.params;

    return (
      <PageLayout<Promocode>
        request={getPromocodes}
        user={nickname}
        headerTitle={'admin.promocodes.title'}
        searchInputHint={'admin.promocodes.search-promocode'}
        defaultSortBy={'createdAt'}
        tableColumns={columns}
        isSubtitle={true}
        isReturnButton={true}
        subtitleArgs={[nickname]}
      />
    );
  }
}
