import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import history from '../../config/history';
import { saveCSVFile } from '../../helpers/utils';
import { api } from '../../utils/api';
import { AdmAutotrader, CommonRequestParams } from '../../utils/types';
import ActionButton from '../controls/ActionButton';
import CurrenciesSelect from '../controls/CurrenciesSelect';
import DatePickerEx from '../controls/DatePickerEx';
import { Confirm } from '../controls/Dialog';
import InputEx from '../controls/InputEx';
import { LabeledControl } from '../controls/LabeledControl';
import { pageBorderColor } from '../controls/Page';
import Txt from '../controls/Txt';
import { Cryptos, fiatCurrencies, getFiatCurrencies } from '../currencies';
import { AdmTablePopup } from './admin-common';
import PageLayout from './PageLayout';

export interface Autotrader {
  id: string;
  callbackUrl: string;
  name: string;
  allowFlashPay: boolean;
}

const MAX_YEAR = new Date().getUTCFullYear();
const CURRENT_MONTH = new Date().getUTCMonth();
const MIN_YEAR = 2018;

const changeFlashPayEnabled =
  (
    id: string,
    key: string,
    value: boolean,
    rows: Autotrader[],
    setRows: (data: Autotrader[]) => void,
  ) =>
  () => {
    api.admin
      .changeAutotraderFlashPayStatus(id, value)
      .then(() => {
        const newData = [...rows];
        const autotraderIndex = newData.findIndex(
          ({ id: autotraderIndex }) => autotraderIndex === id,
        );
        newData[autotraderIndex][key] = value;
        setRows(newData);
      })
      .catch(() => undefined);
  };

const Autotraders = () => {
  const { t } = useTranslation();

  const [busy, setBusy] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [autotraderId, setAutotraderId] = useState();
  const [loadCurrency, setLoadCurrency] = useState('RUB');
  const [symbol, setSymbol] = useState('USDT');
  const [dateFrom, setDateFrom] = useState();
  const [dateTo, setDateTo] = useState();

  const isValidValues = useMemo(() => {
    return !!dateFrom && !!dateTo;
  }, [dateFrom, dateTo]);

  const columns = useMemo(
    () => [
      {
        id: 'name',
        caption: 'admin.autotraders.table.name',
      },
      {
        id: 'callbackUrl',
        caption: 'admin.autotraders.table.callbacl-url',
        noSorting: true,
      },
      // {
      //   id: 'allowFlashPay',
      //   caption: 'admin.autotraders.table.flash-pay-status',
      //   render: (allowFlashPay, row, i, rows, setRows) => (
      //     <>
      //       <span className={`color-${allowFlashPay ? 'true' : 'false'}`}>
      //         &bull;&nbsp;
      //       </span>
      //       <Txt
      //         k={`admin.autotraders.table.status-${
      //           allowFlashPay ? 'enabled' : 'disabled'
      //         }`}
      //       />
      //       <AutotradersEnableBox
      //         isActive={allowFlashPay}
      //         onClick={changeFlashPayEnabled(
      //           row.id,
      //           'allowFlashPay',
      //           !allowFlashPay,
      //           rows,
      //           setRows,
      //         )}
      //       />
      //     </>
      //   ),
      // },
      {
        id: 'statistics',
        caption: 'admin.autotraders.table.statistics',
        noSorting: true,
        render: (_, row) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ActionButton
              caption={'common.open'}
              onClick={() => {
                setShowModal(true);
                setAutotraderId(row.id);
              }}
              width={'100px'}
              height={'35px'}
            />
          </div>
        ),
      },
    ],
    [],
  );

  const submitOpenStatistics = async () => {
    setBusy(true);
    const file = await api.admin.autotradersStatistics({
      id: autotraderId!,
      dateFrom: new Date(dateFrom ?? '').getTime(),
      dateTo: new Date(dateTo ?? '').getTime(),
      symbol: symbol.toLowerCase(),
      currency: loadCurrency.toLowerCase(),
      ordering: '-amount',
    });
    saveCSVFile(file, `statistics_${new Date().toISOString()}`);
    setBusy(false);
  };

  useEffect(() => {
    getFiatCurrencies();
  }, []);

  return (
    <>
      <PageLayout<Autotrader>
        request={getAutotraders}
        headerTitle={'admin.autotraders.title'}
        defaultSortBy={'name'}
        tableColumns={columns}
      />
      <Confirm
        open={showModal}
        disabled={!isValidValues || busy}
        onSubmit={submitOpenStatistics}
        onCancel={() => setShowModal(false)}>
        <LabeledControl label={t('admin.autotraders.statistics-modal.currency')}>
          <CurrenciesSelect
            id={loadCurrency}
            onChange={setLoadCurrency}
            restriction={fiatCurrencies}
            minWidth={'9rem'}
            borderColor={pageBorderColor}
          />
        </LabeledControl>
        <LabeledControl label={t('admin.autotraders.statistics-modal.symbol')}>
          <CurrenciesSelect
            id={symbol}
            onChange={setSymbol}
            restriction={Cryptos}
            minWidth={'9rem'}
            borderColor={pageBorderColor}
          />
        </LabeledControl>
        <LabeledControl label={t('admin.autotraders.statistics-modal.date-from')}>
          <DatePickerEx
            value={dateFrom}
            borderColor={pageBorderColor}
            onChange={setDateFrom}
          />
        </LabeledControl>
        <LabeledControl label={t('admin.autotraders.statistics-modal.date-to')}>
          <DatePickerEx
            value={dateTo}
            borderColor={pageBorderColor}
            onChange={setDateTo}
          />
        </LabeledControl>
      </Confirm>
    </>
  );
};

const AutotradersEnableBox = ({
  isActive,
  onClick,
}: {
  isActive: boolean;
  onClick: () => void;
}) => (
  <AdmTablePopup className={'adm-transaction-actions'}>
    <div className={'adm-button delete-transaction'} onClick={onClick}>
      <span>
        <Txt k={`admin.autotraders.table.status-${isActive ? 'disable' : 'enable'}`} />
      </span>
    </div>
  </AdmTablePopup>
);

export function getAutotraders(params: CommonRequestParams): Promise<Autotrader[]> {
  return new Promise((resolve) => {
    api.admin
      .autotraders({ ...params })
      .then((result) => {
        const autotraders: Autotrader[] = [];
        for (const autotrader of result) {
          autotraders.push(assignAutotrader(autotrader));
        }
        resolve(autotraders);
      })
      .catch(() => undefined);
  });
}

export function assignAutotrader(trader: AdmAutotrader): Autotrader {
  return {
    id: trader.id.toString(),
    name: trader.name,
    callbackUrl: trader.callback_url,
    allowFlashPay: trader.allow_flash_pay,
  };
}

export default memo(Autotraders);
