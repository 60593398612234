import { Cryptos, fiatCurrencies, isAllowedCrypto } from '../components/currencies';
import { JsonExchangeHistory } from '../data/json/JsonExchangeHistory';
import { JsonExchangeSettings } from '../data/json/JsonExchangeSettings';
import { getAdminAuthHeader, getAuthHeader } from '../helpers/authHeader';
import { getCurrentUser } from '../helpers/settings';
import { findObjectInList } from '../helpers/utils';
import UsersService from '../services/UsersService';
import { merchantOperationsType } from './../components/admin/Merchant';
import { generalRequest } from './axiosSetup';
import { fetchApi } from './fetchApi';
import {
  ActivatedPromocode,
  ActiveDeal,
  AdmAutotrader,
  AdmAutotraderStatistics,
  AdmBroker,
  AdmCryptoSettings,
  AdmCurrency,
  AdmDeal,
  AdmLot,
  AdmMerchant,
  AdmMerchantConversions,
  AdmMerchantDeal,
  AdmMerchantStatistics,
  AdmNewUserPassword,
  AdmProfit,
  AdmPromocode,
  AdmPromocodeActivations,
  AdmSettingsValue,
  AdmSystemFlag,
  AdmTransaction,
  AdmUser,
  AdmUserLoginData,
  Bid,
  Commission,
  CommonRequestParams,
  Deal,
  DealBid,
  DealResponse,
  Dispute,
  FastDealRate,
  JoinAccountStatus,
  MarketBid,
  Merchant,
  Message,
  MFAResetData,
  NewDeal,
  Notification,
  Operation,
  OperationType,
  operationTypes,
  PaymentMethod,
  Promocode,
  Rate,
  Referrals,
  SendMessage,
  SkyPayOperation,
  SubRate,
  UpdateBid,
  UserAccountJoins,
  UserProfile,
  Wallet,
} from './types';

export const api = {
  login: (
    email,
    password,
    code?: string,
  ): Promise<{
    token?: string;
    request_code?: boolean;
    access?: string;
    refresh?: string;
  }> =>
    fetchApi('/auth/login', {
      method: 'POST',
      body: { email, password, code },
      suppressError: true,
    }),
  registerEmail: (email: string): Promise<{ nickname: string }> =>
    fetchApi('/auth/register', { method: 'POST', body: { email }, suppressError: true }),
  validateEmail: (email, code: string): Promise<{ is_valid: boolean }> =>
    fetchApi('/auth/validate', {
      method: 'POST',
      body: { email, code },
      suppressError: true,
    }),
  registerComplete: (
    email,
    code,
    password,
    secret,
    ref?,
    cmp?: string,
  ): Promise<{ success: string }> =>
    fetchApi('/auth/complete-register', {
      method: 'POST',
      body: { email, code, password, secret, ref, cmp },
      suppressError: true,
    }),
  paymentMethods: (currency: string): Promise<PaymentMethod[]> =>
    fetchApi('/brokers', { headers: getAuthHeader(), params: { currency } }),
  fastDealBrokers: (currency: string): Promise<PaymentMethod[]> =>
    fetchApi('/brokers', {
      headers: getAuthHeader(),
      params: { currency, fast_deal: true },
    }),
  skyPayBrokers: (currency: string): Promise<PaymentMethod[]> =>
    fetchApi('/brokers', {
      headers: getAuthHeader(),
      params: { currency, sky_pay: true },
    }),
  rates: (): Promise<any> => generalRequest({ url: '/rates', headers: getAuthHeader() }),
  getListCurrency: (): Promise<any[]> =>
    generalRequest({ url: '/currencies', headers: getAuthHeader() }),
  wallets: (): Promise<Wallet[]> =>
    fetchApi('/users/wallets', { headers: getAuthHeader() }),
  exchange: (body): Promise<any> =>
    fetchApi('/exchanges', {
      headers: getAuthHeader(),
      method: 'POST',
      body: body,
      suppressError: true,
    }),
  exchangeRate: (): Promise<JsonExchangeSettings> =>
    fetchApi('/exchanges/settings', { headers: getAuthHeader() }),
  exchangeHistory: (limit: number): Promise<JsonExchangeHistory[]> =>
    fetchApi('/exchanges', { headers: getAuthHeader(), params: { limit: limit } }),
  transfer: (symbol, address: string, amount: number): Promise<any> =>
    generalRequest({
      url: '/transactions',
      method: 'POST',
      headers: getAuthHeader(),
      data: { symbol, address, amount },
    }),
  sendFeedback: (user, email, message: string): Promise<any> =>
    fetchApi('/user-messages/feedback', {
      method: 'POST',
      body: { user, email, message },
    }),
  tradeActivate: (activate: boolean): Promise<{ status: boolean }> =>
    fetchApi('/operations/trading-status', {
      method: 'PATCH',
      headers: getAuthHeader(),
      body: { is_active: activate },
    }),
  fastDealRates: (): Promise<FastDealRate[]> =>
    generalRequest({ url: '/fast-deal-rates' }),
  checkToken: (): Promise<any> =>
    fetchApi('/users/wallets', {
      headers: getAuthHeader(),
      noLogout: true,
      suppressError: true,
    }),
  user: {
    profile: (id: string): Promise<UserProfile> =>
      fetchApi(`/users/${id}`, { headers: getAuthHeader() }),
    balance: (): Promise<Record<string, number>> =>
      fetchApi('/operations/total-balance', { headers: getAuthHeader() }),
    operations: (
      from?,
      to?: number,
      symbol?: string,
      type?: OperationType,
      offset?,
      limit?: number,
    ): Promise<Operation[]> =>
      fetchApi('/operations', {
        headers: getAuthHeader(),
        params: {
          _from: from,
          _to: to,
          symbol,
          action: type ? operationTypes[type] : undefined,
          offset,
          limit,
        },
      }),
    operationsExport: (
      from?,
      to?: number,
      symbol?: string,
      type?: OperationType,
    ): Promise<any> =>
      fetchApi('/operations/export', {
        headers: getAuthHeader(),
        params: {
          _from: from,
          _to: to,
          symbol,
          action: type ? operationTypes[type] : undefined,
        },
      }),
    referrals: (): Promise<Referrals> =>
      fetchApi('/operations/affiliates', { headers: getAuthHeader() }),
    notifications: (limit?: number): Promise<Notification[]> =>
      fetchApi('/operations/updates', { headers: getAuthHeader(), params: { limit } }),
    allNotifications: (
      from?,
      to?,
      notificationType?,
      limit?: number,
    ): Promise<Notification[]> =>
      fetchApi('/operations/updates', {
        headers: getAuthHeader(),
        params: { _from: from, _to: to, notificationType, limit },
      }),
    readNotifications: (ids: number[]) =>
      fetchApi('/operations/updates', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { ids, is_read: true },
      }),
    enableMfa: (code: string): Promise<{ enabled: boolean }> =>
      fetchApi('/settings/enable-mfa', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { code },
      }),
    disableMfa: (): Promise<any> =>
      fetchApi(`/users/${getCurrentUser()}`, {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { is_mfa_enabled: false },
      }),
    changePassword: (oldPassword, newPassword: string): Promise<{ success: string }> =>
      fetchApi('/users/password', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { old: oldPassword, new: newPassword },
      }),
    getOtpAuth: (): Promise<{ otp_auth: string; otp_secret: string }> =>
      fetchApi('/users/user-otp-auth', { headers: getAuthHeader() }),
  },

  settings: {
    joinAccount: (nickname: string) =>
      fetchApi('/users/accounts-join', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { nickname },
      }),
    joinAccountConfirm: (
      token: string,
      nickname: string,
    ): Promise<{ access: string; refresh: string }> =>
      fetchApi('/users/confirmation-accounts-join', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { token, nickname },
      }),
    joinAccountStatus: (): Promise<JoinAccountStatus> =>
      fetchApi('/users/accounts-join-status', { headers: getAuthHeader() }),
    transfer: (token?: string): Promise<Commission[]> =>
      fetchApi('/settings', { headers: getAuthHeader(token) }),
    getCommissions: (
      symbol: string,
      amount: string | number,
    ): Promise<{ commission: number }> =>
      fetchApi('/settings/withdraw_commission', {
        headers: getAuthHeader(),
        params: { symbol, amount },
      }),
    changeCurrency: (currency: string) =>
      generalRequest({
        url: `/users/${getCurrentUser()}`,
        method: 'PATCH',
        headers: getAuthHeader(),
        data: { currency },
      }),
  },
  deals: {
    list: (
      symbol?,
      type?: string,
      offset?,
      limit?: number,
      subCurrency?: string,
    ): Promise<ActiveDeal[]> =>
      fetchApi('/deals', {
        headers: getAuthHeader(),
        params: { offset, limit, symbol, lot_type: type, currency: subCurrency },
      }),
    get: (id: string): Promise<Deal> =>
      fetchApi(`/deals/${id}`, { headers: getAuthHeader() }),
    sendNoAgreement: (id: string): Promise<string[]> =>
      fetchApi(`/deals/${id}/approve-no-agreement`, {
        headers: getAuthHeader(),
        method: 'POST',
      }),
    new: (deal: NewDeal): Promise<Deal> =>
      fetchApi('/deals', { method: 'POST', headers: getAuthHeader(), body: deal }),
    run: (id: string): Promise<DealResponse> =>
      fetchApi('/deals/states', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { deal_id: id },
      }),
    cancel: (id: string): Promise<DealResponse> =>
      fetchApi('/deals/cancel', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { deal_id: id },
      }),
    lastRequisites: (paymentMethod: string): Promise<string[]> =>
      fetchApi('/operations/last-requisites', {
        headers: getAuthHeader(),
        params: { broker: paymentMethod },
      }),
    setRequisite: (id, requisite: string): Promise<DealResponse> =>
      fetchApi('/deals/requisite', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: { deal_id: id, requisite },
      }),
    dispute: {
      get: (id: string): Promise<Dispute> =>
        fetchApi(`/disputes/${id}`, { headers: getAuthHeader() }),
      open: (id: string) =>
        fetchApi('/disputes', {
          method: 'POST',
          headers: getAuthHeader(),
          body: { deal_id: id },
        }),
    },
    voteUser: (dealId, user: string, method: 'like' | 'dislike'): Promise<DealResponse> =>
      fetchApi('/users/vote', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { deal_id: dealId, user, method },
      }),
  },

  bids: {
    list: (symbol?, type?: string, subCurrency?: string): Promise<Bid[]> =>
      fetchApi('/lots', {
        headers: getAuthHeader(),
        params: { symbol, lot_type: type, currency: subCurrency },
      }),
    get: (id: string, actual_limits?: boolean): Promise<DealBid> =>
      fetchApi(`/lots/${id}`, { headers: getAuthHeader(), params: { actual_limits } }),
    create: (bid: Bid) =>
      fetchApi('/lots', { method: 'POST', headers: getAuthHeader(), body: bid }),
    update: (bid: UpdateBid) =>
      fetchApi(`/lots/${bid.id}`, {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: bid,
      }),
    delete: (id: string) =>
      fetchApi(`/lots/${id}`, { method: 'DELETE', headers: getAuthHeader() }),
  },

  marketBids: ({
    symbol,
    type,
    broker,
    subCurrency,
    user,
    limit,
    offset,
  }: {
    symbol?;
    type?;
    broker?;
    subCurrency?: string;
    user?: string;
    limit?;
    offset?: number;
  }): Promise<MarketBid[]> =>
    fetchApi('/lots/market', {
      headers: getAuthHeader(),
      params: {
        symbol,
        lot_type: type,
        broker,
        currency: subCurrency,
        nickname: user,
        limit,
        offset,
      },
    }),

  promocodes: {
    list: {
      created: (limit?: number): Promise<Promocode[]> =>
        fetchApi('/promocodes', { headers: getAuthHeader(), params: { limit } }),
      activated: (limit?: number): Promise<ActivatedPromocode[]> =>
        fetchApi('/promocodes/activated', {
          headers: getAuthHeader(),
          params: { limit },
        }),
    },
    create: (p: Promocode): Promise<Promocode> =>
      fetchApi('/promocodes', {
        method: 'POST',
        headers: getAuthHeader(),
        body: p,
        suppressError: true,
      }),
    activate: (id: string) =>
      fetchApi('/promocodes/activation', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { id },
        suppressError: true,
      }),
    delete: (id: string) =>
      fetchApi(`/promocodes/${id}`, { method: 'DELETE', headers: getAuthHeader() }),
  },

  messages: {
    receive: (user?, token?: string): Promise<Message[]> =>
      fetchApi('/user-messages', {
        headers: getAuthHeader(token),
        params: { with_user: user },
      }),
    send: (message: SendMessage, token?: string) =>
      fetchApi('/user-messages', {
        method: 'POST',
        headers: getAuthHeader(token),
        body: message,
      }),
    attach: (file: any, token?: string): Promise<{ id: number }> =>
      generalRequest({
        url: '/media',
        method: 'POST',
        headers: { ...getAuthHeader(token), 'Content-Type': '' },
        data: formData({ file }),
      }),
  },

  fastDeal: {
    register: (
      email,
      recaptchaToken: string,
      cmp?: string,
      suppressError?: boolean,
    ): Promise<{ access: string; refresh?: string }> =>
      fetchApi('/auth/fast-register', {
        method: 'POST',
        body: { email, captcha_token: recaptchaToken, cmp },
        suppressError,
      }),
    findBid: (
      token,
      broker,
      symbol: string,
      amount: number,
      exclude: string[],
    ): Promise<DealBid> =>
      fetchApi('/lots/fast-deal', {
        headers: getAuthHeader(token),
        params: { broker, symbol, amount, exclude: exclude.join(',') },
      }),
    get: (token, id: string): Promise<Deal> =>
      fetchApi(`/deals/${id}`, { headers: getAuthHeader(token) }),
    new: (token, address, lot: string, amountCurrency: number): Promise<Deal> =>
      fetchApi('/deals', {
        method: 'POST',
        headers: getAuthHeader(token),
        suppressError: true,
        body: {
          lot_id: lot,
          amount_currency: amountCurrency,
          type: 1,
          address,
        },
      }),
    run: (token, id: string): Promise<DealResponse> =>
      fetchApi('/deals/states', {
        method: 'PATCH',
        headers: getAuthHeader(token),
        body: { deal_id: id },
      }),
    cancel: (token, id: string): Promise<DealResponse> =>
      fetchApi('/deals/cancel', {
        method: 'POST',
        headers: getAuthHeader(token),
        body: { deal_id: id },
      }),
    openDispute: (token, id: string) =>
      fetchApi('/disputes', {
        method: 'POST',
        headers: getAuthHeader(token),
        body: { deal_id: id },
      }),
    voteUser: (
      token,
      dealId,
      user: string,
      method: 'like' | 'dislike',
    ): Promise<DealResponse> =>
      fetchApi('/users/vote', {
        method: 'POST',
        headers: getAuthHeader(token),
        body: { deal_id: dealId, user, method },
      }),

    notifications: (token: string, limit: number): Promise<Notification[]> =>
      fetchApi('/operations/updates', {
        headers: getAuthHeader(token),
        params: { limit },
      }),
    readNotifications: (token: string, ids: number[]) =>
      fetchApi('/operations/updates', {
        method: 'PATCH',
        headers: getAuthHeader(token),
        body: { ids, is_read: true },
      }),
  },

  merchant: {
    get: (): Promise<Merchant> => fetchApi('/merchant', { headers: getAuthHeader() }),
    create: (name, website, imageUrl, callbackUrl, callbackUrlSale): Promise<Merchant> =>
      fetchApi('/merchant', {
        method: 'POST',
        headers: getAuthHeader(),
        body: {
          name,
          website,
          image_url: imageUrl,
          callback_url: callbackUrl,
          callback_url_sale: callbackUrlSale,
        },
      }),
    update: (name, website, imageUrl, callbackUrl, callbackUrlSale): Promise<Merchant> =>
      fetchApi('/merchant', {
        method: 'PATCH',
        headers: getAuthHeader(),
        body: {
          name,
          website,
          image_url: imageUrl,
          callback_url: callbackUrl,
          callback_url_sale: callbackUrlSale,
        },
      }),
    resetToken: () =>
      fetchApi('/merchant/reset-token', { method: 'POST', headers: getAuthHeader() }),
  },

  skyPay: {
    operations: (
      from?,
      to?: number,
      symbol?: string,
      subCurrency?: string,
      operationType: OperationType = 'sky pay',
      id?: string,
      offset?,
      limit?: number,
    ): Promise<SkyPayOperation[]> =>
      fetchApi('/operations', {
        headers: getAuthHeader(),
        params: {
          _from: from,
          _to: to,
          symbol,
          currency: subCurrency,
          id,
          offset,
          limit,
          op_type: 2,
          action: operationTypes[operationType],
        },
      }),
    operationsExport: (
      from?,
      to?: number,
      symbol?: string,
      subCurrency?: string,
      operationType: OperationType = 'sky pay',
      id?: string,
    ): Promise<any> =>
      fetchApi('/operations/export', {
        headers: getAuthHeader(),
        params: {
          _from: from,
          _to: to,
          symbol,
          currency: subCurrency,
          id,
          op_type: 2,
          action: operationTypes[operationType],
        },
      }),
  },

  admin: {
    login: (
      username,
      password: string,
      code?: string,
    ): Promise<{ token?: string; request_code?: boolean }> =>
      fetchApi('/auth/admin/login', {
        method: 'POST',
        headers: getAuthHeader(),
        body: { username, password, code },
      }),
    deals: ({
      user,
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams & { user?: string }): Promise<AdmDeal[]> =>
      fetchApi('/admin/deals', {
        headers: getAdminAuthHeader(),
        params: { symbol, user_q: user, q, offset, limit, ordering },
      }),
    deal: (symbol, id: string): Promise<AdmDeal> =>
      fetchApi(`/admin/deals/${id}`, { headers: getAdminAuthHeader() }),
    users: ({
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams): Promise<AdmUser[]> =>
      fetchApi('/admin/users', {
        headers: getAdminAuthHeader(),
        params: { symbol, q, limit, offset, ordering },
      }),
    user: (symbol, nickname: string): Promise<AdmUser> =>
      fetchApi(`/admin/users/${nickname}`, {
        headers: getAdminAuthHeader(),
        params: { symbol },
      }),
    loginUserData: ({
      user,
      limit,
      offset,
      ordering,
    }: CommonRequestParams & { user?: string }): Promise<AdmUserLoginData[]> =>
      fetchApi(`/admin/users/${user}/user-login`, {
        headers: getAdminAuthHeader(),
        params: { limit, ordering, nickname: user, offset },
      }),
    merchantStatisticsData: ({
      merchant_id,
      currency,
    }: {
      merchant_id?: string;
      currency: string;
    }): Promise<AdmMerchantStatistics[]> =>
      fetchApi(`/admin/merchant-statistics-by-month`, {
        headers: getAdminAuthHeader(),
        params: { currency, merchant_id },
      }),
    merchantConversionData: ({
      merchant_id,
      opType,
      month,
    }: {
      merchant_id?: string;
      opType: string;
      month: number;
    }): Promise<AdmMerchantConversions[]> =>
      fetchApi(`/admin/merchant-statistics-by-day`, {
        headers: getAdminAuthHeader(),
        params: { op_type: opType, merchant_id, month },
      }),
    regenerateUserPassword: (nickname: string): Promise<AdmNewUserPassword> =>
      fetchApi(`/admin/users/${nickname}/reset-password`, {
        method: 'POST',
        headers: getAdminAuthHeader(),
      }),
    resetUser2FA: (nickname: string): Promise<MFAResetData> =>
      fetchApi(`/admin/users/${nickname}/reset-mfa`, {
        method: 'POST',
        headers: getAdminAuthHeader(),
      }),
    getUserAccountJoins: (nickname: string): Promise<UserAccountJoins> =>
      fetchApi(`/admin/users/${nickname}/account-joins`, {
        headers: getAdminAuthHeader(),
      }),
    deleteUserAccountJoins: (nickname: string): Promise<JoinAccountStatus> =>
      fetchApi(`/admin/users/${nickname}/account-joins`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { web_nickname: nickname },
      }),
    updateUser: (
      symbol,
      nickname: string,
      banned?,
      verified?: boolean,
      balance?: number,
    ) =>
      fetchApi(`/admin/users/${nickname}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        params: { symbol },
        body: { is_baned: banned, is_verify: verified, balance },
      }),
    bids: ({
      user,
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams & { user?: string }): Promise<AdmLot[]> =>
      fetchApi('/admin/lots', {
        headers: getAdminAuthHeader(),
        params: { symbol, user_q: user, offset, q, limit, ordering },
      }),
    bid: (id: string): Promise<AdmLot> =>
      fetchApi(`/admin/lots/${id}`, { headers: getAdminAuthHeader() }),
    updateBid: (id: string, active?, deleted?: boolean) =>
      fetchApi(`/admin/lots/${id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { is_active: active, is_deleted: deleted },
      }),
    promocodes: ({
      user,
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams & { user?: string }): Promise<AdmPromocode[]> =>
      fetchApi('/admin/promocodes', {
        headers: getAdminAuthHeader(),
        params: { symbol, user_q: user, limit, offset, q, ordering },
      }),
    promocode: (id: number): Promise<AdmPromocodeActivations> =>
      fetchApi(`/admin/promocodes/${id}`, { headers: getAdminAuthHeader() }),
    autotraders: ({
      offset,
      limit,
      ordering,
    }: CommonRequestParams): Promise<AdmAutotrader[]> =>
      fetchApi('/admin/autotraders', {
        headers: getAdminAuthHeader(),
        params: { offset, limit, ordering },
      }),
    autotradersStatistics: ({
      id,
      currency,
      dateFrom,
      dateTo,
      symbol,
      ordering,
    }: {
      id: string;
      currency: string;
      symbol: string;
      dateFrom?: number;
      dateTo?: number;
      ordering: string;
    }): Promise<AdmAutotraderStatistics[]> =>
      fetchApi(`/admin/autotraders/${id}/export-statistics`, {
        headers: getAdminAuthHeader(),
        params: {
          currency,
          _from: dateFrom,
          _to: dateTo,
          ordering,
          symbol,
        },
      }),
    changeAutotraderFlashPayStatus: (id: string, isActive: boolean) =>
      fetchApi(`/admin/autotraders/${id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { allow_flash_pay: isActive },
      }),
    transactions: ({
      user,
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams & { user?: string }): Promise<AdmTransaction[]> =>
      fetchApi('/admin/transactions', {
        headers: getAdminAuthHeader(),
        params: { symbol, user_q: user, limit, offset, q, ordering },
      }),
    restartTransaction: (id: number) =>
      fetchApi('/admin/restart-transaction', {
        method: 'POST',
        headers: getAdminAuthHeader(),
        body: { id },
      }),
    deleteTransaction: (id: number, deleted?: boolean) =>
      fetchApi(`/admin/transactions/${id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { is_deleted: deleted },
      }),
    currencies: (): Promise<AdmCurrency[]> =>
      fetchApi('/admin/currencies', {
        headers: getAdminAuthHeader(),
      }),
    updateCurrency: (id: string, key: string, value: boolean | number) =>
      fetchApi(`/admin/currencies/${id.toLowerCase()}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { [key]: value },
      }),
    merchants: ({
      symbol,
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams): Promise<AdmMerchant[]> =>
      fetchApi('/admin/sky-pay', {
        headers: getAdminAuthHeader(),
        params: { symbol, nickname: q, limit, offset, ordering },
      }),
    merchantResendCallback: (
      merchantId: string,
      paymentId: string,
      operation: merchantOperationsType | 'cpayments',
    ) =>
      fetchApi(`/admin/${operation}/${merchantId}/${paymentId}/send-callback`, {
        headers: getAdminAuthHeader(),
        method: 'POST',
      }),
    cancelCallback: (callbackId: string) =>
      fetchApi(`/admin/callbacks/${callbackId}`, {
        headers: getAdminAuthHeader(),
        method: 'PATCH',
        body: { is_sent: true },
      }),
    merchantOperations: ({
      offset,
      limit,
      q,
      ordering,
      merchantId,
      searchOperationKey,
      url,
    }: CommonRequestParams & {
      url: merchantOperationsType;
      merchantId: string;
      searchOperationKey: string;
    }): Promise<AdmMerchantDeal[]> =>
      fetchApi(`/admin/${url}/${merchantId}`, {
        headers: getAdminAuthHeader(),
        suppressError: true,
        params: { limit, offset, [searchOperationKey]: q, ordering },
      }),
    updateMerchant: (
      merchantId: string,
      key: string,
      value: boolean | number | string,
    ): Promise<any> =>
      fetchApi(`/admin/sky-pay/${merchantId}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { [key]: value },
      }),
    merchantSaleV2Update: (saleV2Id, status): Promise<any> =>
      fetchApi(`/admin/s2/${saleV2Id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { status },
      }),
    merchantDealV2Update: (paymentId, status): Promise<any> =>
      fetchApi(`/admin/p2/${paymentId}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { status },
      }),
    merchantDealUpdate: (paymentId, status): Promise<any> =>
      fetchApi(`/p/${paymentId}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp: '8758399327742' },
        body: { status },
      }),
    merchantSaleUpdate: (paymentId, status): Promise<any> =>
      fetchApi(`/s/${paymentId}`, {
        method: 'PATCH',
        headers: { ...getAuthHeader(), Stamp: '8758399327742' },
        body: { status },
      }),
    profit: (symbol: string): Promise<AdmProfit> =>
      fetchApi(`/admin/profit/${symbol}`, { headers: getAdminAuthHeader() }),
    settings: (): Promise<AdmSettingsValue[]> =>
      fetchApi('/admin/settings', { headers: getAdminAuthHeader() }),
    settingsValue: (key: string): Promise<AdmSettingsValue> =>
      fetchApi(`/admin/settings/${key}`, { headers: getAdminAuthHeader() }),
    updateSettings: (key, value: string) =>
      fetchApi(`/admin/settings/${key}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { value },
      }),
    cryptoSettings: (symbol: string): Promise<AdmCryptoSettings> =>
      fetchApi('/admin/crypto-settings', {
        headers: getAdminAuthHeader(),
        params: { symbol },
      }),
    getExchangeActive: (): Promise<boolean> =>
      Promise.all(
        Cryptos.map((crypto) => api.admin.cryptoSettings(crypto.toLowerCase())),
      ).then((value) =>
        value
          .map((cryptoSettings) => cryptoSettings.exchange_active)
          .every((enabled) => enabled),
      ),
    updateCryptoSettings: (symbol, type: string, value: string) =>
      fetchApi(`/admin/crypto-settings/${type}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        params: { symbol },
        body: { value },
      }),
    systemFlag: (key: string): Promise<AdmSystemFlag> =>
      fetchApi(`/admin/system-flags/${key}`, { headers: getAdminAuthHeader() }),
    updateSystemFlag: (key: string, value: boolean) =>
      fetchApi(`/admin/system-flags/${key}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { value },
      }),
    createBroker: (name: string) =>
      fetchApi('/brokers', {
        method: 'POST',
        headers: getAdminAuthHeader(),
        body: { name },
      }),
    deleteBroker: (id: string) =>
      fetchApi(`/brokers/${id}`, { method: 'DELETE', headers: getAdminAuthHeader() }),
    getBrokers: ({
      offset,
      limit,
      q,
      ordering,
    }: CommonRequestParams): Promise<AdmBroker[]> =>
      fetchApi('/admin/brokers', {
        headers: getAdminAuthHeader(),
        params: { limit, offset, name: q, ordering },
      }),

    updateBroker: (id: string, params: { currencies?: string[]; logo?: string }) =>
      fetchApi(`/brokers/${id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { ...params },
      }),
    updateBrokerCurrencyFlashPayStatus: (
      currency: string,
      brokerId: string,
      status: number,
    ) =>
      fetchApi(`/admin/currencies/${currency}/brokers/${brokerId}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { allow_flash_pay: status },
      }),
    updateBrokerCurrencyRateVariation: (
      currency: string,
      brokerId: string,
      rateVariation: number,
      autotraderId: number | null,
      allowFlashPay: boolean,
      saleV2Rate: number,
      allowSkyPayAutotrader: boolean,
    ) =>
      fetchApi(`/admin/currencies/${currency}/brokers/${brokerId}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: {
          rate_variation: rateVariation,
          autotrader_id: autotraderId,
          allow_flash_pay: allowFlashPay,
          allow_sky_pay_autotrader: allowSkyPayAutotrader,
          sale_v2_rate: saleV2Rate,
        },
      }),
    changeBrokerStatus: (id: string, prop: string, isActive: boolean) =>
      fetchApi(`/brokers/${id}`, {
        method: 'PATCH',
        headers: getAdminAuthHeader(),
        body: { [prop]: isActive },
      }),
    addCurrency: (currency: string) =>
      fetchApi('/admin/currency', {
        method: 'POST',
        headers: getAdminAuthHeader(),
        body: { currency },
      }),
    deleteCurrency: (currency: string) =>
      fetchApi('/admin/currency', {
        method: 'DELETE',
        headers: getAdminAuthHeader(),
        body: { currency },
      }),
  },
};

function formData(params: Record<string, any>): FormData {
  const data = new FormData();
  for (const name in params) {
    if (params[name]) {
      data.append(name, params[name]);
    }
  }
  return data;
}

export function updater(
  update: () => void,
  interval = 10000,
  straight = true,
): () => void {
  if (straight) {
    update();
  }
  const intervalId = setInterval(update, interval);
  return () => clearInterval(intervalId);
}

export function filterRates(rates: SubRate[]): SubRate[] {
  if (!rates) {
    return [];
  }
  const filtered: SubRate[] = [];
  for (const rate of rates) {
    if (fiatCurrencies.includes(rate.currency.toUpperCase())) {
      filtered.push({ currency: rate.currency.toUpperCase(), rate: rate.rate });
    }
  }

  return filtered;
}

export function getCryptoRates(): Promise<Rate[]> {
  return new Promise<Rate[]>((resolve) => {
    api
      .rates()
      .then((records) => {
        if (records) {
          const cryptos = {};
          for (const record of records) {
            if (!isAllowedCrypto(record.symbol.toUpperCase())) {
              continue;
            }
            if (!cryptos?.[record.symbol]) {
              cryptos[record.symbol] = [];
            }
            cryptos[record.symbol].push({ currency: record.currency, rate: record.rate });
          }
          const rates: Rate[] = [];
          for (const crypto in cryptos) {
            if (cryptos?.[crypto]) {
              rates.push({
                currency: crypto.toUpperCase(),
                rates: filterRates(cryptos[crypto]),
              });
            }
          }
          resolve(rates);
        } else {
          resolve([]);
        }
      })
      .catch(() => undefined);
  });
}

export function getSubRate(rates: Rate[], crypto, currency: string): number {
  const rate = findObjectInList(rates, 'currency', crypto);
  const subRate = rate
    ? findObjectInList(rate.rates, 'currency', currency.toUpperCase())
    : undefined;
  return subRate ? subRate.rate : 0;
}

const externalRates: Rate[] = [];

export function getExternalRates(): Promise<Rate[]> {
  return new Promise((resolve) => {
    if (externalRates.length > 0) {
      resolve(externalRates);
      return;
    }

    const cryptos = {
      bitcoin: 'BTC',
      ethereum: 'ETH',
    };
    const currencies = ['rub', 'usd'];

    const ids = Object.keys(cryptos).join(',');
    fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=${currencies.join(
        ',',
      )}`,
    )
      .then((response: Response) => {
        if (response) {
          response.json().then((res) => {
            for (const crypto of Object.keys(res)) {
              const c = res[crypto];
              const subRates: SubRate[] = [];
              for (const currency of Object.keys(c)) {
                subRates.push({ currency: currency.toUpperCase(), rate: c[currency] });
              }
              if (!findObjectInList(externalRates, 'currency', cryptos[crypto])) {
                externalRates.push({ currency: cryptos[crypto], rates: subRates });
              }
            }
            resolve(externalRates);
          });
        }
      })
      .catch(() => resolve([]));
  });
}

export async function changeCurrency(currency: string): Promise<boolean> {
  await UsersService.changeCurrency({ currency: currency.toLowerCase() });
  return true;
}
