import React from 'react';

import { Column, Spacer, TextEx } from '../common';
import Txt from './Txt';

export const LabeledControl = ({
  label,
  first = false,
  rightLabel = false,
  children,
  ImageForLabel,
}: {
  label?: string;
  first?: boolean;
  rightLabel?: boolean;
  children?: React.ReactNode;
  ImageForLabel?: React.ReactNode;
}) => (
  <Column top={!first ? '1rem' : undefined} width={'100%'}>
    <TextEx size={'1.15rem'} width={'100%'} textAlign={rightLabel && 'right'}>
      {label && <Txt k={label} />}
      {ImageForLabel}
    </TextEx>
    <Spacer height={'.5rem'} />
    {children}
  </Column>
);
